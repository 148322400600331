import React from 'react';
import {useComponentTheme} from '@unthinkable/react-theme';

import {
  useToast,
  ToastProvider,
  ToastComponent as BasicToastComponent,
} from '@unthinkable/react-toast';
const Toast = props => {
  let theme = useComponentTheme('Toast');
  return <BasicToastComponent theme={theme} {...props} />;
};

export {useToast, ToastProvider, Toast};
