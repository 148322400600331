export const Input = ({theme: {colors, fonts}}) => {
  return {
    containerStyle: {
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      borderRadius: 8,
      backgroundColor: colors.BACKGROUND,
    },
    errorStyle: {
      borderColor: colors.ERROR_HIGH,
    },
    inputStyle: {
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 14,
      paddingBottom: 14,
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
  };
};

export const LabelledInput = ({theme: {colors, fonts}}) => {
  return {
    inputStyle: {
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 22,
      paddingBottom: 6,
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
  };
};

export const InputCell = ({theme: {colors, fonts}}) => {
  return {
    containerStyle: {
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      borderRadius: 4,
      backgroundColor: colors.SURFACE1,
    },
    inputStyle: {
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 14,
      paddingBottom: 14,
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
  };
};

export const LabelledInputCell = ({theme: {colors, fonts}}) => {
  return {
    containerStyle: {
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      borderRadius: 6,
      backgroundColor: colors.SURFACE1,
    },
    inputStyle: {
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 22,
      paddingBottom: 6,
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
  };
};

export const InputRender = ({theme: {colors, fonts}}) => {
  return {
    containerStyle: {},
    labelStyle: {
      ...fonts.CAPTION,
      color: colors.NEUTRAL_MEDIUM,
      paddingBottom: 8,
    },
    textStyle: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
  };
};
