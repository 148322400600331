import React from 'react';
import {View, Text} from '@unthinkable/react-core-components';

export default class Layout extends React.Component {
  renderLayout = ({layout, theme, elements, parentDirection, index}) => {
    const {
      id,
      columns,
      rows,
      fillParent,
      containerStyle,
      backgroundColor: layoutBg,
      modal,
    } = layout;
    let style = {...containerStyle};
    let children = rows;
    let direction = parentDirection || 'column';
    let containerTheme = (theme && theme[id]) || {};
    let {backgroundColor, width} = containerTheme;
    backgroundColor = backgroundColor || layoutBg;
    if (columns && columns.length) {
      direction = 'row';
      children = columns;
    } else if (rows && rows.length) {
      direction = 'column';
      children = rows;
    }
    children = children || [];
    style['flexDirection'] = direction;
    if (backgroundColor) {
      style['backgroundColor'] = backgroundColor;
    }
    if (width !== undefined) {
      style['width'] = width;
    }
    if (fillParent) {
      style['flex'] = 1;
      style['overflow'] = 'hidden';
    }
    let renderChildren;
    if (children && children.length) {
      renderChildren = children.map((child, index) => {
        return this.renderLayout({
          layout: child,
          theme,
          elements,
          index,
          parentDirection: direction,
        });
      });
    } else {
      renderChildren = elements[id] || null;
    }
    let key = id || index || 'layout';
    return (
      <View style={style} key={key}>
        {renderChildren}
      </View>
    );
  };
  render() {
    const {layout, theme, elements} = this.props;

    const modal =
      layout &&
      layout.modal &&
      layout.modal.id &&
      elements &&
      elements[layout.modal.id];

    return (
      <>
        {this.renderLayout({layout, theme, elements})}
        {modal || null}
      </>
    );
  }
}
