import React from 'react';
import {
  View,
  Image,
  TextInput,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {getFormatValue, isMobile, typecast} from './DateUtility';
import SelectDate from './SelectDate';
import {WithModal} from '@unthinkable/react-popper';
import './date';

class DateInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setStateValue = value => {
    this.setState({
      value,
    });
  };

  onChangeValue = (value, e) => {
    let {onChangeText, onChange} = this.props;
    if (typeof value === 'string') {
      value = value.trim();
    }
    let typecastDate = this.props.typecast || typecast;
    if (typecastDate) {
      value = typecastDate(value, this.props);
    } else {
      value = value ? new Date(value) : null;
      if (value === 'Invalid Date') {
        value = null;
      }
    }
    if (onChangeText) {
      onChangeText(value);
    }
    onChange(value);
  };

  onDateChange = (value, e) => {
    let {onBlur, onChange} = this.props;
    this.onChangeValue(value, e);
    onBlur && onBlur(e);
  };

  onBlur = e => {
    let {onBlur} = this.props;
    let {value} = this.state;
    if (value !== undefined) {
      this.onChangeValue(value, e);
      this.setStateValue(void 0);
    }
    onBlur && onBlur(e);
  };

  _renderDropdown = ({hide}) => {
    let {
      mode,
      value,
      calendarStyle: {calendarStyle, dropdownStyle} = {},
    } = this.props;

    if (value === undefined || value === null) {
      value = '';
    }
    let formattedValue = getFormatValue(value, this.props);
    let ValueSelector = mode === 'time' ? null : SelectDate;
    return (
      <TouchableOpacity
        activeOpacity={1}
        style={[dropdownStyle]}
        onPress={() => {}}>
        <View style={{}}>
          <ValueSelector
            {...this.props}
            value={formattedValue}
            theme={calendarStyle}
            mode={mode}
            hideModal={hide}
            onChange={this.onDateChange}
          />
        </View>
      </TouchableOpacity>
    );
  };

  removeValue = () => {
    let {onChangeText, onChange, onBlur} = this.props;
    onChangeText && onChangeText(null);
    onChange && onChange(null);
    onBlur && onBlur();
  };

  _renderIcon() {
    const {
      showIcon = true,
      iconSource,
      iconComponent,
      customStyles = {},
      calendarStyle = {},
      inputSelectorContainerStyle,
      inputSelectorIconContainerStyle,
      value,
      editable,
    } = this.props;
    let {calendarIconStyle = {}} = calendarStyle;
    if (!showIcon) {
      return null;
    }
    if (iconComponent) {
      return iconComponent;
    }
    let showCross = value && editable !== false;

    let iconRender = (
      <View style={inputSelectorIconContainerStyle}>
        <Image
          style={[customStyles.dateIcon, calendarIconStyle.imageStyle]}
          source={
            showCross
              ? calendarIconStyle.crossImage
              : iconSource || calendarIconStyle.image
          }
        />
      </View>
    );
    if (showCross) {
      iconRender = (
        <TouchableOpacity
          style={{cursor: 'pointer'}}
          onPress={this.removeValue}>
          {iconRender}
        </TouchableOpacity>
      );
    }
    return <View style={inputSelectorContainerStyle}>{iconRender}</View>;
  }

  render() {
    let {
      value,
      disabled,
      editable = true,
      containerStyle,
      inputStyle,
      calendarStyle,
      ...rest
    } = this.props;

    if (value === undefined || value === null) {
      value = '';
    }
    const {value: stateValue} = this.state;
    let formattedValue = getFormatValue(value, rest);
    const inputEditable = editable && !disabled && !isMobile;

    let Wrapper = isMobile ? View : TouchableOpacity;
    let renderComponent = (
      <View style={containerStyle}>
        <Wrapper
          onPress={
            isMobile
              ? void 0
              : e => {
                  e.preventDefault();
                }
          }
          style={{flex: 1, overflow: 'hidden'}}>
          <TextInput
            {...rest}
            style={inputStyle}
            editable={inputEditable}
            value={stateValue !== undefined ? stateValue : formattedValue}
            onBlur={this.onBlur}
            onChangeValue={this.setStateValue}
          />
        </Wrapper>
        {this._renderIcon()}
      </View>
    );

    if (editable)
      renderComponent = (
        <WithModal
          position={isMobile ? 'screenBottom' : 'bottom'}
          renderModal={({hide}) => {
            return this._renderDropdown({hide});
          }}>
          {renderComponent}
        </WithModal>
      );
    return renderComponent;
  }
}

export default DateInput;
