import React from 'react';
import {useComponentTheme} from '@unthinkable/react-theme';
import Layout from '../unthinkable-components/Layout';

export const MenuPageComponent = ({theme, elements}) => {
  const {layout} = theme;
  return <Layout layout={layout} elements={elements} />;
};

export const LeftMenuPage = props => {
  const theme = useComponentTheme('LeftMenuPage');
  return <MenuPageComponent theme={theme} {...props} />;
};

export const LeftMenusHeaderPage = props => {
  const theme = useComponentTheme('LeftMenusHeaderPage');
  return <MenuPageComponent theme={theme} {...props} />;
};
