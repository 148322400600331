import React, { useCallback, memo, forwardRef } from "react";
import StyleSheet from "../StyleSheet";

const defaultStyle = {
  outline: "none",
  padding: 0,
  borderWidth: 0,
  backgroundColor: "transparent",
};

const NumberInput = (
  {
    keyboardType = "numeric",
    value,
    editable,
    onSubmitEditing,
    style,
    onChange,
    caretHidden,
    showSoftInputOnFocus,
    onKeyUp,
    ...props
  },
  ref
) => {
  const onChangeCallback = useCallback(
    (e) => {
      if (onChange) {
        onChange(e);
      }
      let inputValue = e && e.target && e.target.value;

      if (typeof inputValue === "string") {
        inputValue = inputValue.trim();
        if (inputValue.length) {
          inputValue = Number(inputValue);
        } else {
          inputValue = void 0;
        }
      }
      onChange && onChange(inputValue);
    },
    [onChange]
  );

  const onKeyUpCallback = useCallback(
    (e) => {
      const keyCode = e.keyCode;
      if (keyCode === 13) {
        onSubmitEditing && onSubmitEditing(e);
      }
      onKeyUp && onKeyUp(e);
    },
    [onKeyUp, onSubmitEditing]
  );
  if (style && Array.isArray(style)) {
    style = StyleSheet.flatten(style);
  }
  if (style && style.lineHeight && typeof style.lineHeight === "number") {
    style = { ...style, lineHeight: style.lineHeight + "px" };
  }
  if (caretHidden) {
    style = {
      ...style,
      caretColor: "transparent",
    };
  }
  let extraProps = {};
  if (showSoftInputOnFocus === false) {
    extraProps["readOnly"] = true;
  }
  if (editable === false) {
    extraProps["disabled"] = true;
  }
  if (onSubmitEditing) {
    extraProps["onKeyUp"] = onKeyUpCallback;
  }

  if (value === undefined || value === null) {
    value = "";
  } else if (typeof value === "number" && !isNaN(value)) {
    value = value.toString();
  } else if (value && typeof value !== "string") {
    value = JSON.stringify(value);
  }

  return (
    <input
      {...props}
      {...extraProps}
      ref={ref}
      style={{ ...defaultStyle, ...style }}
      type="number"
      keyboardType={keyboardType}
      value={value}
      onChange={onChangeCallback}
    />
  );
};

export default memo(forwardRef(NumberInput));
