export const NotificationCards = ({theme: {colors, fonts, icons}}) => {
  return {
    sm: {},
    md: {
      textStyle: {
        color: 'red',
      },
      cardWrapperStyle: {
        borderWidth: 1,
        borderColor: colors.OUTLINE,
        borderRadius: 8,
      },
      textStyle: {
        ...fonts.PAGE_PARAGRAPH_MEDIUM,
        color: colors.NEUTRAL_MEDIUM,
        marginTop: 8,
      },
      countStyle: {
        ...fonts.PAGE_SUBHEADING_LARGE,
        color: colors.NEUTRAL_HIGH,
        marginTop: 8,
      },
      pushNotificationTextStyle: {
        ...fonts.PAGE_SUBHEADING_LARGE,
        color: colors.NEUTRAL_MEDIUM,
      },
    },
  };
};
