import React from 'react';
import {Text, View} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';

const TagChipModal = ({
  textStyle,
  modalContainer,
  textContainer,
  timeText,
  listContainer,
  item,
  versionCard,
}) => {
  const date = new Date(versionCard.firstOccurrence);

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;

  return (
    <View style={modalContainer}>
      <View style={textContainer}>
        <Text style={textStyle}>Android - Version ({versionCard.version})</Text>
        <Text style={timeText}>Deployed on- {formattedDate}</Text>
        <Text style={textStyle}>Features Deployed:</Text>
        <View style={listContainer}>
          {versionCard.details?.map(item => {
            return <li>{item}</li>;
          })}
        </View>
      </View>
    </View>
  );
};

const TagChip = props => {
  const theme = useComponentTheme('TagChipModal');
  return <TagChipModal {...theme} {...props} />;
};

export default TagChip;
