import React from 'react';
import UnthemedList from '../../../components/list/List';
import {useComponentTheme} from '@unthinkable/react-theme';

const List = props => {
  const theme = useComponentTheme('CenterList');
  return <UnthemedList {...theme} {...props} />;
};

export default List;
