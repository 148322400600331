import {useCallback} from 'react';
import {useAppStateContext} from '../../../unthinkable-components/AppState';

export const useAuthenticate = ({uri, method = 'post'}) => {
  const {post, setUser} = useAppStateContext();

  const onSubmit = useCallback(
    async (data, e) => {
      try {
        const {result} = await post({uri, props: data, method});
        setUser(result);
      } catch (err) {
        console.log('message', err.message);
      }
    },
    [uri, post, method, setUser],
  );

  return {
    onSubmit,
  };
};
