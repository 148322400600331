import {
  Col,
  Image,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useNavigate} from '@unthinkable/react-router';
import {useComponentTheme} from '@unthinkable/react-theme';
import React, {useCallback, useState} from 'react';

const Segment = ({
  mainContainer,
  inputStyle,
  tittle,
  headerContainer,
  iconStyle,
  CrossIcon,
  inputContainer,
  segmentTypeText,
  segmentTypeContainer,
  ActiveRadioIcon,
  segmentTypeWrapperStyle,
  radioActiveiconStyle,
  subTittle,
  InactiveIcon,
  saveButtonStyle,
  segmentMainContainer,
  saveText,
  headerText,
}) => {
  const [data, setData] = useState({});

  const setValue = useCallback(
    ({field, value}) => {
      let newData = {...data};
      newData[field] = value;
      setData(newData);
    },
    [data, setData],
  );

  const [selectedIndex, setIndex] = useState();

  const {segmentTitle} = data || {};

  const Actions = ({icon, title, desc, onPress}) => {
    return (
      <View style={segmentTypeContainer}>
        <View style={segmentTypeWrapperStyle}>
          <TouchableOpacity onPress={onPress}>
            <Image source={icon} style={radioActiveiconStyle} />
          </TouchableOpacity>
          <Col gap={4}>
            <Text>{title}</Text>
            <Text style={subTittle}>{desc}</Text>
          </Col>
        </View>
      </View>
    );
  };

  const segmentData = [
    {
      title: 'Single action',
      desc: 'Add users to a segment as soon as they perform an event',
    },
    {
      title: 'Inaction with time',
      desc: 'Add users to segment when they do an event but not other within a certain time',
    },
    {
      title: 'Page Visit',
      desc: 'Add users to a segment as soon as they visit a certain page',
    },
  ];

  const navigate = useNavigate();
  return (
    <>
      <View style={mainContainer}>
        <View style={headerContainer}>
          <Text style={headerText}>Create new user segment</Text>
          <TouchableOpacity
            onPress={() => {
              navigate(-1);
            }}>
            <Image source={CrossIcon} style={iconStyle} />
          </TouchableOpacity>
        </View>
        <Text style={tittle}>Segment name</Text>
        <View style={inputContainer}>
          <TextInput
            style={inputStyle}
            value={segmentTitle}
            placeholder={'Write title of segment'}
            onChangeText={text => {
              setValue({field: 'segmentTitle', value: text});
            }}
          />
        </View>
        <Text style={segmentTypeText}>User segment type</Text>
        <View style={segmentMainContainer}>
          {segmentData.map((item, index) => {
            let {title, desc} = item || {};

            let icon = selectedIndex === index ? ActiveRadioIcon : InactiveIcon;
            return (
              <Actions
                title={title}
                desc={desc}
                icon={icon}
                onPress={() => {
                  setIndex(index);
                }}
              />
            );
          })}
        </View>
        <TouchableOpacity>
          <View style={saveButtonStyle}>
            <Text style={saveText}>Save</Text>
          </View>
        </TouchableOpacity>
      </View>
    </>
  );
};
const UserSegment = props => {
  const theme = useComponentTheme('UserSegment');
  return <Segment {...theme} {...props} />;
};
export default UserSegment;
