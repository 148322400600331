import React, {useState} from 'react';
import {useDataFetch} from '../../../../controllers/useDataFetch';
import {
  acquistionAnalytics,
  engagmentAnalytics,
  getAppOpenEventWise,
  getUserDetails,
} from '../../Apis';

import {
  ScrollView,
  TouchableOpacity,
  View,
  Text,
  Image,
} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';
import {getTime} from '../../../../components/table/Utility';
import {useNavigate, useParams} from '@unthinkable/react-router';

const ExpandedItem = ({doc}) => {
  const theme = useComponentTheme('RecursiveEvents');
  let {toggleUp, toggleDown, headerTextStyle, titleTextStyle, backgroundColor} =
    theme;
  return (
    <View>
      <View style={{flexDirection: 'row'}}>
        <TouchableOpacity
          style={{flexDirection: 'row', marginBottom: 8, flex: 1}}>
          <Text
            style={{
              ...titleTextStyle,
              width: 300,
            }}>
            {doc.type}
          </Text>
          <Text style={{...titleTextStyle, marginLeft: 4, flex: 1}}>
            {doc.uniqueDeviceCount}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const ExpandedView = ({doc: data}) => {
  if (data && data.length) {
    return (
      <View style={{paddingTop: 10, paddingBottom: 10}}>
        {data.map(doc => {
          return <ExpandedItem doc={doc} />;
        })}
      </View>
    );
  } else {
    return null;
  }
};

const Item = ({doc}) => {
  const theme = useComponentTheme('RecursiveEvents');
  let {toggleUp, toggleDown, headerTextStyle, titleTextStyle, backgroundColor} =
    theme;
  return (
    <View>
      <View style={{marginBottom: 10, marginLeft: 20}}>
        <Text style={headerTextStyle}>
          {(doc.userInfo && doc.userInfo.fts) || 'N/A'}
        </Text>
      </View>
    </View>
  );
};

const UserDetails = () => {
  let params = useParams();
  const {data = []} = useDataFetch({api: getUserDetails(params)});
  const navigate = useNavigate();
  const theme = useComponentTheme('RecursiveEvents');
  let {toggleUp, toggleDown, headerTextStyle, titleTextStyle, backgroundColor} =
    theme;
  if (data && !data.length) {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <Text>loading...</Text>
      </View>
    );
  }

  return (
    <View
      style={{
        backgroundColor: 'white',
        flex: 1,
        overflow: 'hidden',
        padding: 16,
      }}>
      <TouchableOpacity
        style={{marginLeft: 16, marginBottom: 8}}
        onPress={() => {
          navigate(-1);
        }}>
        <Text style={{cursor: 'pointer'}}>{`<< back`}</Text>
      </TouchableOpacity>
      <Text
        style={{
          marginLeft: 20,
          marginBottom: 10,
          backgroundColor,
          padding: 10,
        }}>
        users(name)
      </Text>
      <ScrollView style={{flex: 1}}>
        {data.map(doc => {
          return <Item doc={doc} />;
        })}
      </ScrollView>
    </View>
  );
};

export default UserDetails;
