export const DeviceDetail = ({theme: {colors, fonts, icons}}) => {
  return {
    sm: {},
    md: {
      mainContainer: {
        // flexDirection: 'row',
        // alignItems: 'center',
        padding: 12,
      },
      textStyle: {
        ...fonts.CAPTION_SMALL,
        color: colors.NEUTRAL_LOW,
        fontSize: 12,
      },
      headerStyle: {
        ...fonts.PAGE_TITTLE_LARGE,
        color: colors.NEUTRAL_HIGH,
        fontSize: 20,
        fontWeight: 600,
      },
      userDropDownStyle: {
        flex: 1,
        borderRadius: 8,
        alignSelf: 'start',
        marginTop: 12,
      },
      dropDownListStyle: {
        ...fonts.CAPTION_LARGE,
        color: colors.NEUTRAL_HIGH,
      },
      dateFrameStyle: {
        flexDirection: 'row',
        backgroundColor: colors.SECONDARY_LOW,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 6,
        paddingBottom: 6,
        borderRadius: 4,
        marginTop: 20,
      },
      dateStyle: {
        ...fonts.PAGE_SUBHEADING_LARGE,
        color: colors.NEUTRAL_HIGH,
        flex: 1,
      },
      countStyle: {
        ...fonts.PAGE_TITLE_SMALL,
        color: colors.NEUTRAL_HIGH,
      },
      dataContainerStyle: {
        marginTop: 8,
        padding: 8,
        borderWidth: 1,
        borderColor: colors.OUTLINE,
        flexDirection: 'row',
        borderRadius: 4,
      },
      headerTextStyle: {
        flex: 1,
        ...fonts.PAGE_PARAGRAPH_MEDIUM,
        color: colors.NEUTRAL_MEDIUM,
      },
      countTextStyle: {
        ...fonts.PAGE_SUBHEADING_LARGE,
        color: colors.NEUTRAL_HIGH,
      },
      versionChipStyle: {
        marginTop: 16,
      },
    },
    Dots: icons.DotsIcon,
    arrowDownIcon: icons.arrowDownIcon,
    iconStyle: {
      height: 24,
      width: 24,
    },
  };
};
