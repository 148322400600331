import React, {useRef, useState} from 'react';
import {TouchableOpacity} from '@unthinkable/react-core-components';
import Popper from './Popper';

export const PopperComponent = ({
  children,
  backdropStyle,
  renderModal,
  onClickOutside: propOnClickOutside,
  ...props
}) => {
  const _ref = useRef(null);
  const [visible, setVisible] = useState(false);
  const onClickOutside = () => {
    setVisible(false);
    propOnClickOutside && propOnClickOutside();
  };
  return (
    <>
      <TouchableOpacity
        style={{cursor: 'pointer'}}
        ref={_ref}
        onPress={() => setVisible(m => !m)}>
        {children}
      </TouchableOpacity>
      {visible && (
        <TouchableOpacity
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
            ...backdropStyle,
          }}
          onPress={onClickOutside}></TouchableOpacity>
      )}
      <Popper referenceElement={_ref.current} mounted={visible} {...props}>
        {renderModal({hide: onClickOutside})}
      </Popper>
    </>
  );
};
