import React, {Component} from 'react';
import {
  Dimensions,
  View,
  Text,
  TouchableWithoutFeedback,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableHighlight,
  FlatList,
  StatusBarManager,
  Platform,
  StyleSheet,
  Utility,
} from '@unthinkable/react-core-components';

const {resolveExp} = Utility;
let STATUS_BAR_HEIGHT = 0;

StatusBarManager.getHeight(statusBarHeight => {
  STATUS_BAR_HEIGHT = statusBarHeight && statusBarHeight.height;
});

const TOUCHABLE_ELEMENTS = [
  'TouchableHighlight',
  'TouchableOpacity',
  'TouchableWithoutFeedback',
  'TouchableNativeFeedback',
];

class SelectorList extends Component {
  _calcPosition() {
    let {dropdownStyle, style, _buttonFrame = {}} = this.props;

    const dropdownWidth =
      (dropdownStyle && dropdownStyle.width) ||
      (style && style.width) ||
      _buttonFrame.w;
    const positionStyle = {};

    positionStyle.width = dropdownWidth;

    return positionStyle;
  }
  renderHeader = () => {
    const {suggestionHeader, dropdownHeaderStyle} = this.props;
    const {containerStyle, titleStyle} = dropdownHeaderStyle || {};
    return (
      <View style={containerStyle}>
        <Text style={titleStyle}>{suggestionHeader}</Text>
      </View>
    );
  };

  onCreateNewValue = async (params, e) => {
    const {onCreateNewClick} = this.props;
    if (onCreateNewClick) {
      let value = await onCreateNewClick({
        ...params,
        onChange: _value => {
          this._onRowPress({item: _value, isNew: true}, e);
        },
      });
      this._onRowPress({item: value, isNew: true}, e);
    }
  };
  renderCreateNew = () => {
    const {createNewItemStyle, createNew, searchValue} = this.props;

    if (!createNew || !searchValue) {
      return null;
    }

    let preservedProps;

    if (Platform.OS === 'web') {
      preservedProps = {
        onMouseDown: e => {
          e.preventDefault();
          this.onCreateNewValue({searchValue}, e);
        },
      };
    } else {
      preservedProps = {
        accessible,
        onPress: e => {
          this.onCreateNewValue({searchValue}, e);
        },
      };
    }
    const {containerStyle, labelStyle, textStyle} = createNewItemStyle || {};
    return (
      <TouchableOpacity style={containerStyle} {...preservedProps}>
        <Text style={labelStyle}>Create</Text>
        <Text style={textStyle}>{searchValue}</Text>
      </TouchableOpacity>
    );
  };

  _renderDropdown() {
    const {
      renderSeparator,
      showsVerticalScrollIndicator,
      keyboardShouldPersistTaps = 'always',
      dropdownStyle,
      dropdownListStyle,
      keyExtractor,
      suggestionHeader,
      createNew,
      data,
    } = this.props;

    const frameStyle = this._calcPosition();
    let dropdownComponent = null;

    dropdownComponent = (
      <FlatList
        ListHeaderComponent={suggestionHeader ? this.renderHeader : null}
        ListFooterComponent={createNew ? this.renderCreateNew : null}
        style={dropdownListStyle}
        data={data}
        renderItem={this._renderRow}
        ItemSeparatorComponent={renderSeparator || this._renderSeparator}
        keyExtractor={keyExtractor}
        showsHorizontalScrollIndicator={showsVerticalScrollIndicator}
        keyboardShouldPersistTaps={keyboardShouldPersistTaps}
      />
    );

    if (dropdownComponent) {
      dropdownComponent = (
        <TouchableOpacity
          activeOpacity={1}
          style={[dropdownStyle, frameStyle]}
          onPress={() => {}}>
          {dropdownComponent}
        </TouchableOpacity>
      );
    }
    return dropdownComponent;
  }

  displayText = ({item, suggestionField}) => {
    if (Array.isArray(suggestionField)) {
      return suggestionField.map(display => {
        let rowText =
          typeof item === 'object' ? resolveExp(item, display) : item;
        if (typeof rowText === 'object') {
          rowText = JSON.stringify(rowText);
        }
        return rowText;
      });
    }
    let rowText =
      typeof item === 'object' && suggestionField
        ? resolveExp(item, suggestionField)
        : item;
    if (typeof rowText === 'object') {
      rowText = JSON.stringify(rowText);
    }
    return rowText;
  };

  _renderRow = ({item, index}) => {
    const {
      renderSuggestion,
      dropdownItemStyle,
      accessible,
      suggestionField,
      secondarySuggestionField,
    } = this.props;
    const {containerStyle, textStyle, secondaryTextStyle} =
      dropdownItemStyle || {};

    let row = void 0;
    if (renderSuggestion) {
      row = renderSuggestion({
        item,
        index,
        props: this.props,
      });
    } else {
      let rowText = this.displayText({item, suggestionField});
      let rowSecondaryText = this.displayText({
        item,
        suggestionField: secondarySuggestionField,
      });
      row = (
        <View style={containerStyle}>
          <Text style={[textStyle]}>{rowText}</Text>
          {secondarySuggestionField && (
            <Text style={[secondaryTextStyle]}>{rowSecondaryText}</Text>
          )}
        </View>
      );
    }

    let preservedProps;
    if (Platform.OS === 'web') {
      preservedProps = {
        accessible,
        onMouseDown: e => {
          e.preventDefault();
          this._onRowPress({item, index}, e);
        },
      };
    } else {
      preservedProps = {
        accessible,
        onPress: e => {
          this._onRowPress({item, index}, e);
        },
      };
    }
    if (TOUCHABLE_ELEMENTS.find(name => name == row.type.displayName)) {
      const props = {...row.props};
      props.key = preservedProps.key;
      props.onPress = preservedProps.onPress;
      const {children} = row.props;
      switch (row.type.displayName) {
        case 'TouchableHighlight': {
          return <TouchableHighlight {...props}>{children}</TouchableHighlight>;
        }
        case 'TouchableOpacity': {
          return <TouchableOpacity {...props}>{children}</TouchableOpacity>;
        }
        case 'TouchableWithoutFeedback': {
          return (
            <TouchableWithoutFeedback {...props}>
              {children}
            </TouchableWithoutFeedback>
          );
        }
        case 'TouchableNativeFeedback': {
          return (
            <TouchableNativeFeedback {...props}>
              {children}
            </TouchableNativeFeedback>
          );
        }
        default:
          break;
      }
    }
    return (
      <TouchableOpacity
        onMouseMove={e => {
          e && e.preventDefault && e.preventDefault();
        }}
        className={`flatListRowFocus${index}`}
        {...preservedProps}>
        {row}
      </TouchableOpacity>
    );
  };

  _onRowPress({item, index, isNew}, e) {
    const {onSelect} = this.props;
    onSelect && onSelect({item: item, index, isNew}, e);
  }

  _renderSeparator = rowID => {
    let {separatorStyle} = this.props;
    return <View key={`spr_${rowID}`} style={separatorStyle} />;
  };

  clearValue = e => {
    this.props.clearValue && this.props.clearValue(e);
    this.onBlur(e);
  };

  render() {
    return this._renderDropdown();
  }
}

export default SelectorList;
