import React from 'react';
import {Platform} from '@unthinkable/react-core-components';

export const resolveExp = (values, key) => {
  if (!values || !key) {
    return values;
  }
  if (typeof key === 'function') {
    return key(values);
  }
  if (Array.isArray(values)) {
    var result = [];
    for (var i = 0; i < values.length; i++) {
      var row = values[i];
      var resolvedValue = resolveExp(row, key);
      if (resolvedValue !== undefined) {
        if (Array.isArray(resolvedValue)) {
          for (var j = 0; j < resolvedValue.length; j++) {
            /* for save the duplicate values for field other then _id @sourabh -- 4-2-17*/
            if (key !== '_id' || result.indexOf(resolvedValue[j]) === -1) {
              result.push(resolvedValue[j]);
            }
          }
        } else {
          if (key !== '_id' || result.indexOf(resolvedValue) === -1) {
            result.push(resolvedValue);
          }
        }
      }
    }
    return result;
  } else {
    var value = values[key];
    if (value !== undefined) {
      return value;
    }
    var index = key.indexOf('.');
    if (index === -1) {
      return;
    }
    var firstPart = key.substring(0, index);
    var nextPart = key.substring(index + 1);
    return resolveExp(values[firstPart], nextPart);
  }
};

export const getRenderComponent = (Component, props) => {
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, props);
  } else if (typeof Component === 'function') {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    } else {
      return Component(props);
    }
  }
  return Component;
};
export const detectMob = () => {
  if (
    Platform.OS !== 'web' ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};
