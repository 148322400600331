export const NewAnalytics = ({theme: {fonts, colors, icons}}) => {
  return {
    sm: {
      toggleUp: icons.ToggleUp,
      toggleDown: icons.ToggleDown,
      headerTextStyle: {
        ...fonts.PAGE_TITTLE_SMALL,
        color: colors.NEUTRAL_HIGH,
      },
      titleTextStyle: {
        ...fonts.PAGE_PARAGRAPH_MEDIUM,
        color: colors.NEUTRAL_MEDIUM,
      },
      backgroundColor: colors.PRIMARY_MEDIUM,
      RightArrowIcon: icons.ToggleRight,
      iconStyle: {
        height: 24,
        width: 24,
      },
      containerStyle: {
        borderColor: colors.NEUTRAL_OUTLINE,
        flexDirection: 'row',
        borderWidth: 1,
        padding: 4,
        marginBottom: 8,
        alignItems: 'center',
        borderRadius: 4,
      },
      mainConatiner: {
        flexDirection: 'row',
        // marginLeft: 136,
        // marginRight: 136,
      },
      textStyle: {
        ...fonts.PAGE_TITLE_LARGE1,
        color: colors.NEUTRAL_HIGH,
        marginBottom: 12,
        marginTop: 12,
        marginLeft: 12,
      },
      titleValueStyle: {
        ...fonts.PAGE_SUBHEADING_LARGE,
        color: colors.NEUTRAL_HIGH,
      },
      listStyle: {
        // height: '80vh',
        width: 410,
        marginTop: 8,
        backgroundColor: colors.BACKGROUND,
      },
      selectStyle: {
        borderColor: colors.BRAND_UPPER_MEDIUM,
        backgroundColor: colors.BRAND_LOW,
        flexDirection: 'row',
        borderWidth: 1,
        padding: 8,
        marginBottom: 8,
        alignItems: 'center',
        borderRadius: 4,
      },
    },
    md: {
      toggleUp: icons.ToggleUp,
      toggleDown: icons.ToggleDown,
      headerTextStyle: {
        ...fonts.PAGE_TITLE_SMALL,
        color: colors.NEUTRAL_HIGH,
      },
      titleTextStyle: {
        ...fonts.PAGE_PARAGRAPH_MEDIUM,
        color: colors.NEUTRAL_MEDIUM,
        marginLeft: 8,
        flex: 1,
      },
      titleValueStyle: {
        ...fonts.PAGE_SUBHEADING_LARGE,
        color: colors.NEUTRAL_HIGH,
      },

      backgroundColor: colors.SECONDARY_LOW,
      RightArrowIcon: icons.ToggleRight,
      iconStyle: {
        height: 24,
        width: 24,
      },

      selectStyle: {
        borderColor: colors.BRAND_UPPER_MEDIUM,
        backgroundColor: colors.BRAND_LOW,
        flexDirection: 'row',
        borderWidth: 1,
        padding: 8,
        marginBottom: 8,
        alignItems: 'center',
        borderRadius: 4,
      },

      containerStyle: {
        borderColor: colors.OUTLINE,
        flexDirection: 'row',
        borderWidth: 1,
        padding: 8,
        marginBottom: 8,
        alignItems: 'center',
        borderRadius: 4,
      },

      listStyle: {
        height: '80vh',
        width: 410,
        marginTop: 8,
        backgroundColor: colors.BACKGROUND,
      },
      mainConatiner: {
        flexDirection: 'row',
        marginLeft: 136,
        marginRight: 136,
      },
      textStyleConatiner: {
        ...fonts.PAGE_TITLE_LARGE,
        color: colors.NEUTRAL_HIGH,
        marginBottom: 12,
        marginTop: 12,
        marginLeft: 12,
      },

      dateStyle: {
        backgroundColor: colors.SECONDARY_LOW,
        justifyContent: 'center',
        marginTop: 12,
        // marginRight: 16,
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 8,
        marginBottom: 16,
        borderRadius: 4,
      },
    },
  };
};
