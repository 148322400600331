import React, {useState} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Platform,
} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';
import {useEventHandler} from '@unthinkable/react-event-handler';

const TimeFrameHeader = ({
  title,
  wrapperStyle,
  containerStyle,
  timeFrame,
  selectedTimeFrame,
  timeSpan,
  setParams,
  textStyle,
  docIndex,
  setDocIndex,
  setState,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const {notify} = useEventHandler();

  return (
    <View style={wrapperStyle}>
      <View style={containerStyle}>
        {timeSpan.map((item, index) => {
          return (
            <TouchableOpacity
              style={[
                timeFrame,
                ...[index === selectedIndex ? selectedTimeFrame : null],
              ]}
              onPress={() => {
                setSelectedIndex(index);
                setParams({
                  span: item && item.name && item.name.toLowerCase(),
                });
                setTimeout(() => {
                  notify('reloadNewAnalytics', {});
                }, 100);
                setDocIndex('');
                setState(false);
              }}>
              <Text style={textStyle}>{item.name}</Text>
            </TouchableOpacity>
          );
        })}
      </View>
    </View>
  );
};

const TimeFrame = props => {
  const theme = useComponentTheme('TimeFrame');
  return <TimeFrameHeader {...theme} {...props} />;
};

export default TimeFrame;
