const {useMemo} = require('react');

export const getResolvedColumnsAndWidth = ({
  columns,
  clientWidth,
  minColumnWidth,
}) => {
  let totalWidth = void 0;
  if (columns && columns.length) {
    let columnWidthConsumed = 0;
    let totalFlexColumns = 0;
    columns = columns.map(column => {
      if (!column) {
        return null;
      }
      column = {
        ...column,
      };
      if (column.width) {
        columnWidthConsumed += column.width;
      } else {
        column.flex = 1;
        column.overflow = 'hidden';
        column.minWidth = minColumnWidth;
        totalFlexColumns += column.flex || 0;
      }
      return column;
    });
    // if (columnWidthConsumed > 0 && clientWidth) {
    //   let availableFlexWidth = clientWidth - columnWidthConsumed;
    //   columns = columns.map(column => {
    //     let {width, flex, minWidth} = column;
    //     delete column.flex;
    //     if (!width && flex) {
    //       if (availableFlexWidth > 0) {
    //         const flexWidth = Math.floor(
    //           (availableFlexWidth * flex) / totalFlexColumns,
    //         );
    //         width = minWidth > flexWidth ? minWidth : flexWidth;
    //       } else {
    //         width = minWidth;
    //       }
    //     }
    //     totalWidth = (totalWidth || 0) + width;
    //     return {
    //       ...column,
    //       width,
    //     };
    //   });
    //   if (totalWidth < clientWidth) {
    //     columns.push({
    //       width: clientWidth - totalWidth,
    //     });
    //     totalWidth = clientWidth;
    //   }
    // }
  }
  return {columns, width: totalWidth};
};

export const useTableColumns = ({
  columns,
  minColumnWidth,
  clientWidth,
  verticalScrollWidth,
}) => {
  if (!columns || !columns.length) {
    return {};
  }
  const resolvedColumns = useMemo(() => {
    return getResolvedColumnsAndWidth({
      columns,
      clientWidth,
      minColumnWidth,
    });
  }, [columns, minColumnWidth, clientWidth]);
  const columnsInfo = useMemo(() => {
    const {columns} = resolvedColumns;
    const headerColumns = [...columns];
    if (verticalScrollWidth) {
      headerColumns.push({width: verticalScrollWidth, dirty: true});
    }
    return {...resolvedColumns, headerColumns};
  }, [resolvedColumns, verticalScrollWidth]);
  return columnsInfo;
};
