import React from 'react';
import {View} from '@unthinkable/react-core-components';
import ModalPageComponent from '../unthinkable-components/ModalPage';
import {useComponentTheme} from '@unthinkable/react-theme';
import {CloseSmallAction} from './form/CloseAction';

const ModalPage = ({closable, ...props}) => {
  let theme = useComponentTheme('ModalPage');
  let closeAction = void 0;
  if (closable) {
    closeAction = (
      <View style={{position: 'absolute', top: 8, right: 8}}>
        <CloseSmallAction iconStyle={{height: 16, width: 16}} />
      </View>
    );
  }
  return (
    <ModalPageComponent theme={theme} closeAction={closeAction} {...props} />
  );
};

export default ModalPage;
