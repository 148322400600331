import React, {forwardRef, useCallback} from 'react';
import View from '../View';
import StyleSheet from '../StyleSheet';
import './ScrollView.css';

const ScrollView = forwardRef(
  (
    {
      style,
      contentContainerStyle,
      keyboardShouldPersistTaps,
      showsVerticalScrollIndicator = true,
      showsHorizontalScrollIndicator = true,
      horizontal,
      className = '',
      onScroll: onScrollProps,
      flex,
      ...props
    },
    ref,
  ) => {
    const onScroll = useCallback(
      e => {
        let eventValues = e.target;
        onScrollProps && onScrollProps(eventValues);
      },
      [onScrollProps],
    );

    if (Array.isArray(style)) {
      style = StyleSheet.flatten(style);
    }
    if (contentContainerStyle && Array.isArray(contentContainerStyle)) {
      contentContainerStyle = StyleSheet.flatten(contentContainerStyle);
    }
    // This is for hide-vertical-scroll-indicator we use css class for hide it and add to className and Pass
    if (!showsVerticalScrollIndicator) {
      className += ' hide-vertical-scroll-indicator';
    }
    // This is for hide-horizontal-scroll-indicator
    if (!showsHorizontalScrollIndicator) {
      className += ' hide-horizontal-scroll-indicator';
    }
    style = {flex: 1, overflow: 'auto', ...style, ...contentContainerStyle};
    if (horizontal) {
      style['flexDirection'] = 'row';
    } else if (horizontal === false) {
      style.overflowX = 'hidden';
    }
    if (flex !== undefined) {
      style.flex = flex === false ? void 0 : flex;
    }
    return (
      <View
        {...props}
        ref={ref}
        className={className}
        style={style}
        onScroll={onScroll}
      />
    );
  },
);

export default ScrollView;
