import {Platform} from '@unthinkable/react-core-components';
import * as React from 'react';

export const filterData = ({searchValue, data, suggestionField}) => {
  if (Array.isArray(suggestionField)) {
    suggestionField = suggestionField[0];
  }
  if (!data || !data.length) {
    return [];
  }
  if (!searchValue || !searchValue.trim().length) {
    return data;
  }
  searchValue = searchValue.trim().toLowerCase();
  return data.filter(doc => {
    if (!searchValue || !searchValue.trim().length) {
      return true;
    }
    let value = doc;
    if (suggestionField) {
      value = doc[suggestionField];
    }

    if (value) {
      value = value.toLowerCase();
    }
    if (value.indexOf(searchValue) === 0) {
      return true;
    }
  });
};

export const getValueToDisplay = ({value, valueField, keyField, options}) => {
  if (Array.isArray(value)) {
    value = value.length ? value[0] : '';
  }
  if (options?.length) {
    if (keyField && valueField && value !== undefined && value !== null) {
      for (let option of options) {
        if (typeof option === 'object' && value === option[keyField]) {
          return option[valueField];
        }
      }
    }
    return value;
  } else if (value && valueField) {
    return value[valueField];
  } else {
    return value;
  }
};

export const useMergeRefs = (...refs) => {
  const targetRef = React.useRef();

  React.useEffect(() => {
    refs.forEach(ref => {
      if (!ref) return;
      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

export const detectMob = () => {
  if (
    Platform.OS !== 'web' ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};

export const isMobile = detectMob();
