import React from 'react';
import View from './View';

const Wrap = ({
  gap,
  hGap,
  vGap,
  children,
  numColumns,
  minColumns = 1,
  maxColumns,
}) => {
  if (hGap === undefined) {
    hGap = gap;
  }
  if (vGap === undefined) {
    vGap = gap;
  }
  const wrapperProps = {
    flexDirection: 'row',
    flexWrap: 'wrap',
  };
  const itemWrapperProps = {};
  if (hGap) {
    itemWrapperProps.marginRight = hGap;
    wrapperProps.marginRight = hGap * -1;
  }
  if (vGap) {
    wrapperProps.marginTop = (vGap / 2) * -1;
    wrapperProps.marginBottom = (vGap / 2) * -1;
    itemWrapperProps.marginTop = vGap / 2;
    itemWrapperProps.marginBottom = vGap / 2;
  }

  children = React.Children.toArray(children).filter(child => !!child);

  let childCount = React.Children.count(children);
  let lastRowNumColumns;
  let lastRowFirstIndex;
  if (maxColumns && childCount) {
    lastRowNumColumns = childCount % maxColumns;
    lastRowFirstIndex = childCount - lastRowNumColumns;
  }

  const childrenToRender = React.Children.map(children, (child, index) => {
    let _numColumns = maxColumns;
    if (numColumns) {
      _numColumns = numColumns;
    } else if (lastRowFirstIndex <= index) {
      if (minColumns && lastRowNumColumns < minColumns) {
        _numColumns = minColumns;
      } else {
        _numColumns = lastRowNumColumns;
      }
    }

    let component = (
      <View key={index} style={{...itemWrapperProps}}>
        {child}
      </View>
    );
    if (_numColumns) {
      component = (
        <View style={{width: `${100 / _numColumns}%`}}>{component}</View>
      );
    }
    return component;
  });
  return (
    <View style={{overflow: 'hidden'}}>
      <View style={wrapperProps}>{childrenToRender}</View>
    </View>
  );
};

export default Wrap;
