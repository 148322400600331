export const FloatingLabel = ({theme: {fonts, colors}}) => {
  return {
    containerStyle: {position: 'absolute', left: 12, top: 14},
    floatingLabelContainerStyle: {top: 6},
    labelStyle: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_MEDIUM,
    },
    floatingLabelStyle: {
      ...fonts.FOOTNOTE,
      color: colors.NEUTRAL_LOW,
    },
  };
};
