export const VersionChip = ({theme: {colors, fonts, icons}}) => {
  return {
    sm: {
      selectedCard: {
        flexDirection: 'row',
        borderRadius: 8,
        marginLeft: 12,
        borderWidth: 1,
        borderColor: colors.BRAND_UPPER_MEDIUM,
        marginBottom: 12,
        backgroundColor: colors.BRAND_LOW,
        alignSelf: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer',
      },
      containerStyle: {
        flexDirection: 'row',
        borderRadius: 8,
        marginLeft: 8,
        borderWidth: 1,
        borderColor: colors.OUTLINE,
        marginBottom: 12,
        backgroundColor: colors.SURFACE2,
        alignSelf: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer',
      },
      textStyle: {
        ...fonts.PAGE_SUBHEADING_SMALL,
        padding: 8,
        color: colors.NEUTRAL_HIGH,
      },
      closeChipWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
    md: {
      selectedCard: {
        flexDirection: 'row',
        borderRadius: 8,
        marginLeft: 12,
        borderWidth: 1,
        borderColor: colors.BRAND_UPPER_MEDIUM,
        marginBottom: 12,
        backgroundColor: colors.BRAND_LOW,
        alignSelf: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer',
      },
      containerStyle: {
        flexDirection: 'row',
        borderRadius: 8,
        marginLeft: 8,
        borderWidth: 1,
        borderColor: colors.OUTLINE,
        marginBottom: 12,
        backgroundColor: colors.SURFACE2,
        alignSelf: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer',
      },
      textStyle: {
        ...fonts.PAGE_PARAGRAPH_SMALL,
        padding: 8,
        color: colors.NEUTRAL_HIGH,
      },
      percentStyle: {
        ...fonts.PAGE_SUBHEADING_SMALL,
        color: colors.NEUTRAL_HIGH,
      },
      closeChipWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
    InfoIcon: icons.InfoIcon,
    CrossIcon: icons.CrossIcon,
    iconsInfo: {
      height: 16,
      width: 16,
      marginLeft: 4,
      marginRight: 8,
    },
    iconStyle: {
      height: 24,
      width: 24,
      marginRight: 8,
    },
  };
};
