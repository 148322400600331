export const NewInsatallUsers = ({theme: {colors, fonts, icons}}) => {
  return {
    sm: {},
    md: {
      mainContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 12,
      },
      textStyle: {
        ...fonts.CAPTION_SMALL,
        color: colors.NEUTRAL_LOW,
        fontSize: 12,
      },
      headerStyle: {
        ...fonts.PAGE_TITTLE_LARGE,
        color: colors.NEUTRAL_HIGH,
        fontSize: 20,
        fontWeight: 600,
      },
      formContainer: {
        marginTop: 16,
        marginLeft: 95,
        marginRight: 95,
      },
      textTittle: {
        color: colors.NEUTRAL_MEDIUM,
        fontSize: 16,
        padding: 8,
      },
      textDisableStyle: {
        // ...fonts.PAGE_SUBHEADING_SMALL,
        padding: 8,
        marginTop: 24,
        color: colors.NEUTRAL_MEDIUM,
        backgroundColor: colors.SURFACE2,
        borderRadius: 8,
        fontSize: 16,
      },
      ChannelListStyle: {
        ...fonts.PAGE_SUBHEADING_SMALL,
        padding: 8,
        marginTop: 24,
        color: colors.NEUTRAL_MEDIUM,
        backgroundColor: colors.SURFACE2,
        borderRadius: 8,
        fontSize: 16,
      },
      textInputReportFiledStyle: {
        borderWidth: 1,
        borderColor: colors.OUTLINE,
        borderRadius: 8,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 13,
        paddingBottom: 13,
        marginTop: 12,
      },
      reportInputStyle: {
        color: colors.NEUTRAL_MEDIUM,
        fontSize: 16,
      },
      segmentsStyle: {
        color: colors.BRAND_UPPER_MEDIUM,
        fontSize: 14,
      },
      segmentFiled: {
        flex: 1,
        fontSize: 14,
      },
      userDropDownStyle: {
        flex: 1,
        marginTop: 12,
        borderRadius: 8,
      },
      dropDownListStyle: {
        ...fonts.PAGE_PARAGRAPH_MEDIUM,
        color: colors.NEUTRAL_HIGH,
        backgroundColor: 'red',
      },
      selectChannelsStyle: {
        borderWidth: 1,
        borderColor: colors.OUTLINE,
        borderRadius: 8,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 13,
        paddingBottom: 13,
        fontSize: 16,
        marginTop: 12,
        color: colors.NEUTRAL_MEDIUM,
        flexDirection: 'row',
      },
      channeltextStyle: {
        ...fonts.PAGE_PARAGRAPH_MEDIUM,
        color: colors.NEUTRAL_HIGH,
        marginRight: 2,
      },
    },
    DotsIcon: icons.DotsIcon,
    PlusIcon: icons.PlusIcon,
    DownArrowIcon: icons.DownArrowIcon,
    CheckedIcon: icons.CheckedIcon,
    iconStyle: {
      height: 24,
      width: 24,
    },
    plusIconStyle: {
      marginRight: 6,
      height: 16,
      width: 16,
    },
    DownArrowIconStyle: {
      height: 16,
      width: 16,
    },
    CheckedIconStyle: {
      height: 24,
      width: 24,
      marginRight: 12,
    },
  };
};
