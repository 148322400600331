export const Login = ({theme: {fonts, colors, icons}}) => {
  return {
    LOCOMOIcon: icons.LOCOMO,
    EmpoweringGrowthIcon: icons.EmpoweringGrowth,
    mainContainerStyle: {height: '100%', width: '100%'},
    topContainerStyle: {
      alignItems: 'center',
      justifyContent: 'center',
      height: '25%',
    },
    LOCOMOIconStyle: {height: 39, width: 247, margin: 8},
    EmpoweringGrowthIconStyle: {height: 24, width: 201, margin: 8},
    bottomContainerStyle: {
      height: '75%',
      width: window.innerWidth,
      backgroundImage: `url(${icons.LoginBackgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      alignItems: 'flex-end',
    },
  };
};
