export const Toast = ({
  theme: {
    colors,
    fonts: {FOOTNOTE},
    spacing: {sm},
  },
}) => {
  return {
    success: {
      containerStyle: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 30,
        justifyContent: 'center',
        alignItems: 'center',
      },
      textContainerStyle: {
        backgroundColor: colors.ACCENT1_HIGH,
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 10,
        paddingLeft: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,
      },
      textStyle: {color: colors.ACCENT1_MEDIUM, ...FOOTNOTE, paddingLeft: sm},
    },
    error: {
      containerStyle: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 30,
        justifyContent: 'center',
        alignItems: 'center',
      },
      textContainerStyle: {
        backgroundColor: colors.ACCENT2_HIGH,
        padding: sm,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,
      },
      textStyle: {color: colors.ACCENT2_MEDIUM, ...FOOTNOTE, paddingLeft: sm},
    },
  };
};
