import React, {forwardRef} from 'react';

import View from './View';

const Row = forwardRef(({gap, style, children}, ref) => {
  let childrenArray = React.Children.toArray(children).filter(
    _child => !!_child,
  );

  const childrenToRender = React.Children.map(childrenArray, (child, index) => {
    if (!child) {
      return null;
    }
    return (
      <>
        {index > 0 ? <View style={{width: gap}} /> : void 0}
        {child}
      </>
    );
  });
  if (!childrenToRender?.length) {
    return null;
  }
  return (
    <View ref={ref} style={[{flexDirection: 'row'}, style]}>
      {childrenToRender}
    </View>
  );
});

export default Row;
