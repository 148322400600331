import React from 'react';
import {Text, View} from '@unthinkable/react-core-components';

const Signup = () => {
  return (
    <View>
      <Text>Signup</Text>
    </View>
  );
};

export default Signup;
