export const RecursiveEvents = ({theme: {fonts, colors, icons}}) => {
  return {
    toggleUp: icons.ToggleUp,
    toggleDown: icons.ToggleDown,
    headerTextStyle: {
      ...fonts.TITLE,
      color: colors.NEUTRAL_HIGH,
    },
    titleTextStyle: {
      ...fonts.HEADING1,
      color: colors.NEUTRAL_HIGH,
    },
    backgroundColor: colors.PRIMARY_MEDIUM,
  };
};
