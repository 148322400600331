import React from 'react';
import {Route} from '@unthinkable/react-router';

import {TempScreen, Home} from '../modules/home/views/Home';
import UserDetails from '../modules/event/views/user-Details/UserDetails';
import {ModalContainer} from '../components/ModalContainer';
import Modal from '../components/ModalPage';
import UserSegment from '../components/segmentModal/UserSegment';
import DeviceDetails from '../modules/event/views/device-Details/DeviceDetails';

const HomeRoute = (
  <Route
    path="home"
    elements={{
      view: <Home />,
    }}
  />
);

const userDetaiView = (
  <Route
    path="userDetails"
    elements={{
      view: <UserDetails />,
    }}
  />
);
const deviceDetailView = (
  <Route
    path="deviceDetails"
    elements={{
      view: <DeviceDetails />,
    }}
  />
);

const tempScreenRoute = (
  <Route
    path="add-segment"
    elements={{
      view: (
        <Modal size={'auto'}>
          <UserSegment />
        </Modal>
      ),
    }}
  />
);

export default [HomeRoute, userDetaiView, tempScreenRoute, deviceDetailView];
