import React from 'react';
import {View} from '@unthinkable/react-core-components';

const ModalPage = ({closeAction, children, theme, size, ...props}) => {
  let {
    backdropStyle,
    position,
    containerStyle,
    width,
    maxWidth,
    minWidth,
    height,
    maxHeight,
    minHeight,
    sizes,
  } = theme || {};

  let sizeWiseStyle = (size && sizes && sizes[size]) || {};
  width = props.width || sizeWiseStyle.width || width;
  maxWidth = props.maxWidth || sizeWiseStyle.maxWidth || maxWidth;
  minWidth = props.minWidth || sizeWiseStyle.minWidth || minWidth;
  height = props.height || sizeWiseStyle.height || height;
  maxHeight = props.maxHeight || sizeWiseStyle.maxHeight || maxHeight;
  minHeight = props.minHeight || sizeWiseStyle.minHeight || minHeight;

  containerStyle = {
    ...containerStyle,
  };
  backdropStyle = {
    ...backdropStyle,
  };

  if (width) {
    containerStyle.width = width;
  }
  if (minWidth) {
    containerStyle.minWidth = minWidth;
  }
  if (maxWidth) {
    containerStyle.maxWidth = maxWidth;
  }
  if (height) {
    containerStyle.height = height;
  }
  if (minHeight) {
    containerStyle.minHeight = minHeight;
  }
  if (maxHeight) {
    containerStyle.maxHeight = maxHeight;
  }
  if (position === 'screenBottom') {
    backdropStyle.justifyContent = 'flex-end';
    containerStyle.width = '100%';
    containerStyle.maxHeight = maxHeight;
  } else if (position === 'screenCenter') {
    backdropStyle.alignItems = 'center';
    backdropStyle.justifyContent = 'center';
  }

  return (
    <View
      style={[
        {position: 'absolute', top: 0, left: 0, bottom: 0, right: 0},
        backdropStyle,
      ]}>
      <View style={containerStyle}>
        {children}
        {closeAction}
      </View>
    </View>
  );
};

export default ModalPage;
