import React, {useEffect, useState} from 'react';
import {
  Image,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';
import TagChip from '../tagChip/TagChipModal';
import {useEventHandler} from '@unthinkable/react-event-handler';
export const ReleaseVersionChip = ({
  textStyle,
  containerStyle,
  InfoIcon,
  CrossIcon,
  iconStyle,
  iconsInfo,
  item,
  selectedCard,
  appOpenCount,
  data,
  showSelected,
  selectShow,
  ShowVersionCard,
  setShowVersionCard,
  versionCard,
  setVersionCard,
  percentStyle,
  spanFilter,
}) => {
  let span =
    spanFilter && spanFilter.span ? spanFilter.span : data && data.span;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [versionItem, setVersionItem] = useState();
  const {notify} = useEventHandler();

  let {Item} = versionCard || {};
  let {version} = Item || {};

  let component = versionCard ? (
    <View>
      <View style={selectedCard}>
        <Text style={textStyle}>(A){version} (90%)</Text>
        <TouchableOpacity
          onMouseEnter={() => {
            setIsModalVisible(true);
            setVersionItem(Item);
          }}
          onMouseLeave={() => {
            setIsModalVisible(false);
          }}>
          <Image source={InfoIcon} style={iconsInfo} />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setVersionCard(void 0);
            setShowVersionCard(true);
            notify('reloadNewAnalytics', {
              version: undefined,
              platform: undefined,
              span: span,
            });

            notify('reloadRightMenu', {
              endDate:
                spanFilter && spanFilter.endDate
                  ? spanFilter.endDate
                  : data && data.endDate,
              startDate:
                spanFilter && spanFilter.startDate
                  ? spanFilter.startDate
                  : data && data.startDate,
              span,
              type:
                spanFilter && spanFilter.type
                  ? spanFilter.type
                  : data && data.type,
              versionInfo: {},
            });
          }}>
          <Image source={CrossIcon} style={iconStyle} />
        </TouchableOpacity>
      </View>
      {isModalVisible ? (
        <View style={{position: 'absolute', zIndex: 1, top: 50, left: 90}}>
          <TagChip versionCard={versionItem} />
        </View>
      ) : null}
    </View>
  ) : (
    <View>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        {item.map(doc1 => {
          return (
            <TouchableOpacity
              style={containerStyle}
              onPress={() => {
                setVersionCard({Item: doc1});
                setShowVersionCard(false);
                notify('reloadNewAnalytics', {
                  version: doc1.version,
                  platform: doc1.platform,
                  span: span,
                });

                notify('reloadRightMenu', {
                  type:
                    spanFilter && spanFilter.type
                      ? spanFilter.type
                      : data && data.type,
                  endDate:
                    spanFilter && spanFilter.endDate
                      ? spanFilter.endDate
                      : data && data.endDate,
                  startDate:
                    spanFilter && spanFilter.startDate
                      ? spanFilter.startDate
                      : data && data.startDate,
                  span,
                  versionInfo: {version: doc1.version, platform: doc1.platform},
                });
              }}>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Text style={textStyle}>
                  ({doc1.platform == 'android' ? 'A' : 'I'}){doc1.version}
                </Text>
                <Text style={percentStyle}>
                  ({((doc1.uniqueCount / appOpenCount) * 100).toFixed(1)}
                  %)
                </Text>
              </View>
              <TouchableOpacity
                onMouseEnter={() => {
                  setIsModalVisible(true);
                  setVersionItem(doc1);
                }}
                onMouseLeave={() => {
                  setIsModalVisible(false);
                }}>
                <Image source={InfoIcon} style={iconsInfo} />
              </TouchableOpacity>
            </TouchableOpacity>
          );
        })}
      </ScrollView>

      {isModalVisible ? (
        <View style={{position: 'absolute', zIndex: 1, top: 50, left: 90}}>
          <TagChip versionCard={versionItem} />
        </View>
      ) : null}
    </View>
  );
  return component;
};

const VersionChip = props => {
  const theme = useComponentTheme('VersionChip');
  return <ReleaseVersionChip {...theme} {...props} />;
};
export default VersionChip;
