let url = process.env.REACT_APP_URL;

// url = 'http://localhost:5002';


const BASE_URL = url;

const fetchUrl = BASE_URL;
const postUrl = BASE_URL;
const uploadUrl = BASE_URL;
const downloadUrl = BASE_URL;

export default {
  fetchUrl,
  postUrl,
  uploadUrl,
  downloadUrl,
};
