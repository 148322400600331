export const NewHeader = ({theme: {colors, fonts, icons}}) => {
  return {
    sm: {
      wraperStyle: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 24,
        paddingBottom: 16,
        backgroundColor: colors.BACKGROUND,
      },
      containerStyle: {
        flexDirection: 'row',
        alignItems: 'center',
      },
      textStyle: {
        ...fonts.PAGE_HEADER,
        color: colors.NEUTRAL_HIGH,
        marginLeft: 12,
      },
      secondaryTitleStyle: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      },

      textWrapper: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 32,
        borderWidth: 1,
        fontSize: 14,
        padding: 7,
        borderRadius: 8,
        borderColor: colors.NEUTRAL_HIGH,
        cursor: 'pointer',
      },

      helpButtonTextStyle: {
        ...fonts.PAGE_SUBHEADING_SMALL,
        color: colors.NEUTRAL_HIGH,
      },

      iconStyle: {
        height: 32,
        width: 32,
      },
      avatarStyle: {
        borderRadius: 8,
        size: 32,
        textStyle: {
          color: colors.NEUTRAL_HIGH,
        },
      },
      ArrowLeftIconStyle: {
        height: 20,
        width: 20,
      },
      MessageQuestionIcon: {
        height: 24,
        width: 24,
        marginRight: 16,
      },
    },
    md: {
      wraperStyle: {
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 24,
        paddingBottom: 16,
        backgroundColor: colors.BACKGROUND,
      },
      containerStyle: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
      },
      textStyle: {
        ...fonts.PAGE_HEADER,
        color: colors.NEUTRAL_HIGH,
        marginLeft: 12,
      },
      secondaryTitleStyle: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      },

      textWrapper: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 32,
        borderWidth: 1,
        fontSize: 14,
        padding: 7,
        borderRadius: 8,
        borderColor: colors.NEUTRAL_HIGH,
        cursor: 'pointer',
      },

      helpButtonTextStyle: {
        ...fonts.PAGE_SUBHEADING_SMALL,
        color: colors.NEUTRAL_HIGH,
      },
      MessageQuestionIcon: {
        height: 16,
        width: 16,
        marginRight: 6,
      },
    },
    iconStyle: {
      height: 32,
      width: 32,
    },
    avatarStyle: {
      borderRadius: 8,
      size: 32,
      backgroundColor: 'purple',
      textStyle: {
        color: colors.BACKGROUND,
      },
    },
    FloomLogo: icons.FloomLogo,
    MessageQuestion: icons.MessageQuestion,
    ProfileAvatar: icons.ProfileAvatar,
    ArrowLeft: icons.ArrowLeft,
    DotsIcon: icons.DotsIcon,
  };
};
