import {
  Table,
  BorderedTable,
  GroupRow,
  RecursiveTable,
  ColoredTable,
} from './Table';

import {
  MenuBar,
  TopMenuBar,
  ModalTopMenuBar,
  LeftMenuBar,
  BottomMenuBar,
  LeftSideMenuBar,
  LeftSectionsMenuBar,
  AbsoluteMenuBar,
  LeftMenuHeader,
} from './MenuBar';
import {
  DateCell,
  TextCell,
  TitleCell,
  TaggedTextCell,
  ChipBadgeCell,
  LinkCell,
  PrimaryCell,
  StandardCell,
  PrimaryDetailCell,
  DesignColumnCell,
  AttachmentCountCell,
} from './CellRenders';
import {Form, FormGroup} from './Form';

import {Input, LabelledInput, InputCell, LabelledInputCell} from './Inputs';
import {
  MenuPage,
  TopMenuPage,
  BottomMenuPage,
  LeftMenuPage,
  LeftMenusHeaderPage,
} from './MenuPage';
import {ModalPage} from './ModalPage';
import {
  Header,
  ModalHeader,
  ModalFooter,
  ViewHeader,
  HeaderTitle,
  HeaderActions,
  ModalContainer,
} from './Header';
import {FloatingLabel} from './FloatingLabel';
import {Toast} from './Toast';
import {Button} from './Button';
import {DateInput, LabelledDateInput} from './DateInput';
import ThemedComponents from '../../../theme-component/Theme';
import {TagChipModal} from '../../../components/tagChip/Theme';
import {NewInsatallUsers} from '../../../components/newInstallsUsers/Theme';
import {ReusableButtons} from '../../../components/reusableButton/Theme';
import {
  AutoComplete,
  LabelledAutoComplete,
  AutoCompleteCell,
  LabelledAutoCompleteCell,
} from './AutoComplete';
import {TimeFrame} from '../../../components/timeFrameHeader/Theme';
import {VersionChip} from '../../../components/releaseVersionChip/Theme';
import {RightSide} from '../../../components/RightSideView/Theme';
import {NewHeader} from '../../../components/newHeader/Theme';
import {UserSegment} from '../../../components/segmentModal/Theme';
import {DeviceDetail} from '../../../modules/event/views/device-Details/Theme';
import {NotificationCards} from '../../../components/notification-card/theme';
export default {
  Table,
  ColoredTable,
  BorderedTable,
  GroupRow,
  RecursiveTable,
  Button,
  MenuBar,
  TopMenuBar,
  ModalTopMenuBar,
  LeftMenuBar,
  BottomMenuBar,
  LeftSideMenuBar,
  LeftSectionsMenuBar,
  AbsoluteMenuBar,
  LeftMenuHeader,
  DateCell,
  TextCell,
  TitleCell,
  TaggedTextCell,
  ChipBadgeCell,
  LinkCell,
  PrimaryCell,
  StandardCell,
  PrimaryDetailCell,
  DesignColumnCell,
  AttachmentCountCell,
  Form,
  FormGroup,
  Input,
  LabelledInput,
  InputCell,
  LabelledInputCell,
  MenuPage,
  TopMenuPage,
  BottomMenuPage,
  LeftMenuPage,
  LeftMenusHeaderPage,
  ModalPage,
  Header,
  ModalHeader,
  ModalFooter,
  ViewHeader,
  HeaderTitle,
  HeaderActions,
  NewHeader,
  RightSide,
  VersionChip,
  ModalContainer,
  FloatingLabel,
  Toast,
  DateInput,
  LabelledDateInput,
  TagChipModal,
  NewInsatallUsers,
  ReusableButtons,
  AutoComplete,
  LabelledAutoComplete,
  AutoCompleteCell,
  LabelledAutoCompleteCell,
  TimeFrame,
  UserSegment,
  DeviceDetail,
  NotificationCards,
  ...ThemedComponents,
};
