import React from 'react';
import {
  View,
  Text,
  Image,
  ScrollView,
} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';
import DeviceDetails from '../../modules/event/views/device-Details/DeviceDetails';
import NotificationCards from '../notification-card/NotificationCard';

const RightSideView = ({
  title,
  containerStyle,
  EmptyImage,
  iconsStyle,
  WrapperStyle,
  textStyle,
  state,
  stateData,
  setVersionCard,
  versionCard,
  setShowVersionCard,
  notificationUser,
}) => {
  return (
    <ScrollView style={containerStyle}>
      {/* <NewInsatallUsers /> */}
      {/* <DrillDownData /> */}
      {state ? (
        <DeviceDetails
          state={state}
          stateData={stateData}
          setVersionCard={setVersionCard}
          versionCard={versionCard}
          setShowVersionCard={setShowVersionCard}
          notificationUser={notificationUser}
        />
      ) : (
        <View style={WrapperStyle}>
          <Image source={EmptyImage} style={iconsStyle} />
          <Text style={textStyle}>
            Select one metric 👈 for futher drill down report
          </Text>
        </View>
      )}
    </ScrollView>
  );
};

const RightSide = props => {
  const theme = useComponentTheme('RightSide');
  return <RightSideView {...theme} {...props} />;
};
export default RightSide;
