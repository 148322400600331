import React from "react";
import View from "./View";

const Col = ({ gap, style, children }) => {
  let childrenArray = React.Children.toArray(children).filter(
    (_child) => !!_child
  );

  const childrenToRender = React.Children.map(childrenArray, (child, index) => {
    if (!child) {
      return null;
    }
    return (
      <>
        {index > 0 ? <View style={{ height: gap }} /> : void 0}
        {child}
      </>
    );
  });
  if (!childrenToRender?.length) {
    return null;
  }
  return (
    <View style={[{ flexDirection: "column" }, style]}>{childrenToRender}</View>
  );
};

export default Col;
