import React from 'react';
import {View, Text, Modal} from '@unthinkable/react-core-components';
import {Router, Route} from '@unthinkable/react-router';
import {LeftSideMenuBar} from '../../../components/MenuBar';
import {LeftMenuPage} from '../../../components/MenuPage';
import {LeftMenuHeader} from '../../../custom-components/LeftMenuHeader';
import {useTheme} from '@unthinkable/react-theme';
import AllEvents from '../../event/views/allevents/AllEvents';
import RecursiveEvents from '../../event/views/recursive-events/RecursiveEvents';
import Acquisition from '../../event/views/acquisition/Acquisition';
import Retention from '../../event/views/retention/Retention';
import Engagement from '../../event/views/engagement/Engagement';
import UserEvents from '../../event/views/user-events/UserEvents';
import VersionWiseEngagement from '../../event/views/versionWise-engagement/VersionWiseEngagement';
import DesiredEvents from '../../event/views/desired-events/DesiredEvents';
import Departments from '../../event/views/add-departments/AddDepartments';
import NewAnalytics from '../../event/views/newAnalytics/NewAnalytics';
import NewHeader from '../../../components/newHeader/newHeader';
import TimeFrame from '../../../components/timeFrameHeader/timeFrameHeader';
import TagChip from '../../../components/tagChip/TagChipModal';
import Segment from '../../../components/segmentModal/UserSegment';
import UserSegment from '../../../components/segmentModal/UserSegment';
// const menus = () => {
//   let icons = useTheme('icons');

let menus = [
  //   {
  //     path: 'NewAnalytics',
  //     label: 'NewAnalytics',
  //     icon: icons.OpenFeature,
  //     activeIcon: icons.OpenFeatureActive,
  //     index: true,
  //   },
  //   {
  //     path: 'recursive',
  //     label: 'AppVisits',
  //     icon: icons.OpenFeature,
  //     activeIcon: icons.OpenFeatureActive,
  //     index: true,
  //   },
  //   {
  //     path: 'acquisition',
  //     label: 'Acquisition',
  //     icon: icons.OpenFeature,
  //     activeIcon: icons.OpenFeatureActive,
  //     index: true,
  //   },
  //   {
  //     path: 'retention',
  //     label: 'Retention',
  //     icon: icons.OpenFeature,
  //     activeIcon: icons.OpenFeatureActive,
  //     index: true,
  //   },
  //   {
  //     path: 'engagement',
  //     label: 'Engagement',
  //     icon: icons.OpenFeature,
  //     activeIcon: icons.OpenFeatureActive,
  //     index: true,
  //   },
  //   {
  //     path: 'userrealtimeevents',
  //     label: 'User Events',
  //     icon: icons.OpenFeature,
  //     activeIcon: icons.OpenFeatureActive,
  //     index: true,
  //   },
  //   {
  //     path: 'versionWiseEngagement',
  //     label: 'Version Wise Engagemnet',
  //     icon: icons.OpenFeature,
  //     activeIcon: icons.OpenFeatureActive,
  //     index: true,
  //   },
  //   {
  //     path: 'desiredEvents',
  //     label: 'Desired Events',
  //     icon: icons.OpenFeature,
  //     activeIcon: icons.OpenFeatureActive,
  //     index: true,
  //   },
  //   {
  //     path: 'addDepartments',
  //     label: 'add Dashboard',
  //     icon: icons.OpenFeature,
  //     activeIcon: icons.OpenFeatureActive,
  //     index: true,
  //   },
];

//   return menus;
// };

export const Home = () => {
  return (
    // <View>
    //   <NewHeader />
    //   <TimeFrame />
    // </View>
    <Router layout={<LeftMenuPage />}>
      <Route
        elements={{
          menus: (
            // <LeftSideMenuBar renderHeader={LeftMenuHeader} menus={menus()} />
            <NewAnalytics />
          ),
        }}
      />

      {/* <Route
        path="all"
        elements={{
          view: <AllEvents />,
        }}
      />
      <Route
        path="recursive"
        elements={{
          view: <Engagement />,
        }}
      />
      <Route
        path="acquisition"
        elements={{
          view: <Acquisition />,
        }}
      />
      <Route
        path="retention"
        elements={{
          view: <Retention />,
        }}
      />
      <Route
        path="engagement"
        elements={{
          view: <RecursiveEvents />,
        }}
      />
      <Route
        path="userrealtimeevents"
        elements={{
          view: <UserEvents />,
        }}
      />
      <Route
        path="versionWiseEngagement"
        elements={{
          view: <VersionWiseEngagement />,
        }}
      />

      <Route
        path="desiredEvents"
        elements={{
          view: <DesiredEvents />,
        }}
      />

      <Route
        path="addDepartments"
        elements={{
          view: <Departments />,
        }}
      />
      <Route
        path="NewAnalytics"
        elements={{
          view: <NewAnalytics />,
        }}
      /> */}
    </Router>
  );
};

export const TempScreen = () => {
  return <UserSegment />;
};

// const TempScreen = (
//   <Route
//     path="add-segment"
//     elements={{
//       view: (
//         <Modal size="extra_small">
//           <Text>hello</Text>
//         </Modal>
//       ),
//     }}
//   />
// );

// export default [Home /* Template */];
