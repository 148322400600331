import React, {useState} from 'react';
import {useDataFetch} from '../../../../controllers/useDataFetch';
import {getUserDetails, getUtmGroupBy} from '../../Apis';

import {
  ScrollView,
  TouchableOpacity,
  View,
  Text,
  Image,
  Col,
  Dots,
} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';
import {getTime} from '../../../../components/table/Utility';
import {useNavigate, useParams} from '@unthinkable/react-router';
import {AutoComplete} from '../../../../components/form-editors/AutoComplete';
import {MoreAction} from '../../../../components/MoreAction';
import ReleaseVersionChip from '../../../../components/releaseVersionChip/ReleaseVersionChip';
import NotificationCards from '../../../../components/notification-card/NotificationCard';
const options = [{name: 'All Users'}, {name: 'New User'}];

const Item = ({doc, dataContainerStyle, headerTextStyle}) => {
  const theme = useComponentTheme('RecursiveEvents');
  let {toggleUp, toggleDown, titleTextStyle, backgroundColor, countTextStyle} =
    theme || {};
  return (
    <View>
      <View style={dataContainerStyle}>
        <Text style={headerTextStyle}>{`${doc._id} ` || 'n/a'}</Text>
        <Text style={countTextStyle}>{`${doc.count}` || 'n/a'}</Text>
      </View>
    </View>
  );
};

const DeviceDetails = ({
  state,
  setState,
  stateData,
  mainContainer,
  textStyle,
  headerStyle,
  dropDownListStyle,
  userDropDownStyle,
  dateFrameStyle,
  dateStyle,
  countStyle,
  dataContainerStyle,
  headerTextStyle,
  countTextStyle,
  Dots,
  versionChipStyle,
  arrowDownIcon,
  iconStyle,
  versionCard,
  setVersionCard,
  setShowVersionCard,
  notificationUser,
}) => {
  let {startDate, endDate, type, version, platform, title, span} =
    stateData || {};

  const date = getTime(startDate);

  const {data} = useDataFetch({
    initialState: {startDate, endDate, type, version, platform, span},
    api: getUtmGroupBy,
    reloadEvents: ['reloadRightMenu'],
  });

  // const navigate = useNavigate();

  const theme = useComponentTheme('RecursiveEvents');
  // let {backgroundColor} = theme;
  if (data && !data.length) {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <Text>loading...</Text>
      </View>
    );
  }

  let sumValue =
    data &&
    data.reduce(function (a, b) {
      return a + b.count;
    }, 0);
  return (
    <>
      <View style={mainContainer}>
        <View style={{flexDirection: 'row'}}>
          <View style={{flex: 1}}>
            <Col gap={8} style={{flex: 1}}>
              <Text style={textStyle}>Home/{title}</Text>
              <Text style={headerStyle}>{title}</Text>
            </Col>
          </View>

          <View>
            <MoreAction
              icon={Dots}
              style={iconStyle}
              actions={[
                {
                  title: 'View Saved Configuration',
                  onPress: () => {},
                },
                {
                  title: 'Save Configuration',
                  onPress: () => {},
                },
                {
                  title: 'Setup new Configuration',
                  onPress: () => {},
                },
                {
                  title: 'Shared configuration',
                  onPress: () => {},
                },
                {
                  title: 'Export as CSV',
                  onPress: () => {},
                },
              ]}
            />
          </View>
        </View>

        {/* <View style={userDropDownStyle}>
          <AutoComplete
            style={dropDownListStyle}
            options={options}
            arrowDownIcon={arrowDownIcon}
            suggestionField="name"
            placeholder={'All Users'}
            valueField="name"
            value={user}
            onChange={item => {
              setUsers(item.name);
            }}
          />
        </View> */}
        <View style={dateFrameStyle}>
          <Text style={dateStyle}>{date}</Text>
          {(stateData.type && stateData.type === 'install') ||
          stateData.type === 'Act. Users' ||
          stateData.type === 'Events/Visit' ||
          stateData.type === 'Visit/User' ||
          stateData.type === 'Unique opens' ||
          stateData.type === 'Notification/User' ||
          stateData.type === 'Churn rate- D2+ (%)' ||
          stateData.type === 'Churn rate- D0-D2 (%)' ? (
            <Text style={countStyle}>
              {stateData.type === 'install'
                ? stateData.installPipelineuniqueCount
                : stateData.type === 'Events/Visit'
                ? stateData.eventsPerUsers_Upto_now
                : stateData.type === 'Visit/User'
                ? stateData.visir_per_users_upto_now
                : stateData.type === 'Unique opens'
                ? stateData.percentage_visit_upto_now
                : stateData.type === 'Notification/User'
                ? stateData.notificationCount
                : stateData.type === 'Churn rate- D2+ (%)'
                ? stateData.churnCount
                : stateData.type === 'Churn rate- D0-D2 (%)'
                ? stateData.churnCountDZero
                : stateData.activeUser_end}
            </Text>
          ) : (
            <Text style={countStyle}>{sumValue}</Text>
          )}
        </View>
        <View style={versionChipStyle}>
          <ReleaseVersionChip
            item={stateData.versionList}
            appOpenCount={stateData.appOpenEventsuniqueCount}
            versionCard={versionCard}
            setVersionCard={setVersionCard}
            spanFilter={stateData}
            setShowVersionCard={setShowVersionCard}
            data={data}
          />
        </View>
      </View>
      <View
        style={{
          backgroundColor: 'white',
          flex: 1,
          overflow: 'hidden',
          paddingLeft: 16,
          paddingRight: 16,
          paddingBottom: 16,
        }}>
        {/* <TouchableOpacity
          style={{marginLeft: 16, marginBottom: 8}}
          onPress={() => {
            navigate(-1);
          }}>
          <Text style={{cursor: 'pointer'}}>{`<< back`}</Text>
        </TouchableOpacity> */}
        {/* <Text
          style={{
            marginLeft: 20,
            marginBottom: 10,
            // backgroundColor,
            padding: 10,
          }}>
          Ads(count)
        </Text> */}

        {notificationUser ? (
          <NotificationCards data={stateData} />
        ) : (
          <ScrollView style={{flex: 1}}>
            {data &&
              data.map(doc => {
                return (
                  <Item
                    doc={doc}
                    dataContainerStyle={dataContainerStyle}
                    headerTextStyle={headerTextStyle}
                    countTextStyle={countTextStyle}
                  />
                );
              })}
          </ScrollView>
        )}
      </View>
    </>
  );
};
const DeviceDetail = props => {
  const theme = useComponentTheme('DeviceDetail');
  return <DeviceDetails {...theme} {...props} />;
};

export default DeviceDetail;
