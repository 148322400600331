import {useCallback, useEffect, useRef, useState} from 'react';
import {useAppStateContext} from '../unthinkable-components/AppState';
import {useEventHandler} from '@unthinkable/react-event-handler';
import {useParams} from '@unthinkable/react-router';
import {useToast} from '../components/toast/Toast';

export const useDataFetch = ({
  initialState,
  skipInitialLoad,
  api,
  reloadEvents,
  reloadParams = [],
  addRowPosition,
  onDataLoad,
  isLoadMoreRequired,
  loadMoreApi,
  processLoadMoreData,
  initialLimit,
}) => {
  let dataRef = useRef({
    state: {
      loading: true,
      params: {},
      ...initialState,
    },
  });

  let [toggle, setToggle] = useState(true);

  const state = dataRef.current.state;
  const setState = useCallback(
    newState => {
      let oldValue = dataRef.current.state;
      dataRef.current.state = {...oldValue, ...newState};
      setToggle(!toggle);
    },
    [state, toggle, setToggle],
  );
  const {fetch} = useAppStateContext();
  const {listen, unlisten} = useEventHandler();
  let toast = useToast();

  const params = useParams();
  const filters = {};

  const loadData = async ({params: eventParams} = {}) => {
    try {
      let _api = api;
      if (typeof _api == 'function') {
        _api = _api(params, state, {filters, ...eventParams});
      }

      if (initialLimit && _api) {
        _api = {..._api};
        _api.props = {limit: initialLimit, ..._api.props};
      }
      let response = await fetch(_api);

      if (response.result) {
        response.data = response.result;
        delete response.result;
      }

      if (onDataLoad) {
        response = onDataLoad(response);
      }
      let oldParams = dataRef.current.state.params;
      let newState = {
        ...response,
        params: {...oldParams, ...eventParams},
        loading: false,
        loaded: true,
        hasMore: true,
      };

      setState(newState);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        err = new Error(err.response.data.error);
      }
      toast({message: err.message, type: 'Error'});
    }
  };

  const onRefreshEvent = useCallback(
    ({params}) => {
      loadData({params});
    },
    [state, setState, toggle, setToggle],
  );

  useEffect(() => {
    if (!skipInitialLoad) {
      loadData();
    }
  }, []);

  useEffect(() => {
    if (reloadEvents && reloadEvents.length) {
      reloadEvents.forEach(e => listen(e, onRefreshEvent));
    }
    return () => {
      if (reloadEvents && reloadEvents.length) {
        reloadEvents.forEach(e => unlisten(e, onRefreshEvent));
      }
    };
  }, [onRefreshEvent]);

  const doLoadMore = async () => {
    const _loadMoreApi = loadMoreApi({
      api,
      state,
      params,
      loadMoreParams: state.loadMoreParams,
    });
    if (_loadMoreApi) {
      let response = await fetch(_loadMoreApi);
      let newState =
        processLoadMoreData && processLoadMoreData(state, response);
      if (newState) {
        setState(newState);
      }
    }
  };
  useEffect(() => {
    if (state.loadingMore) {
      doLoadMore();
    }
  }, [state.loadingMore]);

  // useEffect(() => {
  //   loadData();
  // }, [...reloadParams]);

  const loadmoreCallback = async loadMoreParams => {
    if (!state.hasMore) {
      // console.log('@@@@ data already loaded, no more data....');
      return;
    }
    if (state.loadingMore) {
      // console.log('@@@@ already loading....');
      return;
    }

    const loadMoreRequired =
      isLoadMoreRequired && isLoadMoreRequired({state, loadMoreParams, params});
    if (!loadMoreRequired) {
      return;
    }
    setState({loadingMore: true, loadMoreParams});
  };
  const loadMore = useCallback(
    params => {
      loadmoreCallback(params);
    },
    [state, setState, toggle, loadMoreApi],
  );

  const setParams = useCallback(
    newParams => {
      let {params} = state;
      params = {...params, ...newParams};
      // let newState = {...state, params};
      setState({params});
    },
    [state, setState],
  );

  const addRow = useCallback(
    values => {
      const {data} = state;
      let newRow = {_id: `new_${new Date().getTime()}`, ...values};
      let newData = data ? [...data] : [];
      if (addRowPosition === 'top') {
        newData.unshift(newRow);
      } else if (addRowPosition === 'bottom') {
        newData.push(newRow);
      }
      setState({
        data: newData,
      });
    },
    [state, setState, addRowPosition],
  );

  const updateRow = useCallback(
    (row, idField, idValue) => {
      if (idValue === undefined) {
        idValue = row[idField];
      }
      const {data} = state;
      const rowIndex = data
        ? data.findIndex(_row => _row[idField] === idValue)
        : -1;
      if (rowIndex >= 0) {
        let newData = [...data];
        newData[rowIndex] = row;
        setState({
          data: newData,
        });
      }
    },
    [state, setState],
  );

  const extraProps = {setParams, loadMore, updateRow, addRow};

  return {...state, ...extraProps};
};
