import React, {useCallback} from 'react';
import {FlatList, Image, Text, View} from '@unthinkable/react-core-components';

const List = props => {
  const {
    loadMore,
    loading,
    loaded,
    noDataStyle,
    noDataText,
    renderFixedHeader,
    renderItem: _renderItem,
    isSeparatorRequired,
    renderSeparator,
    onScroll: pOnScroll,
    ...rest
  } = props;
  const {data, keyExtractor} = props;

  const renderLoading = useCallback(() => {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <Text>Loading...</Text>
      </View>
    );
  }, [loading, loaded]);

  let renderNoData = useCallback(() => {
    if (noDataStyle && noDataText && loaded && (!data || !data.length)) {
      return (
        <>
          <View
            style={[
              {
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              },
              noDataStyle.containerStyle,
            ]}>
            <Image source={noDataStyle.icon} style={noDataStyle.iconStyle} />
            <Text style={noDataStyle.emptyTextStyle}>{noDataText}</Text>
          </View>
        </>
      );
    }
    return <></>;
  }, [noDataStyle, noDataText, loaded, data]);

  if (!noDataStyle || !noDataText) {
    renderNoData = void 0;
  }

  let renderItem = renderSeparator
    ? useCallback(
        ({item, index}) => {
          let key = keyExtractor ? keyExtractor(item, index) : index;
          const prevItem = index > 0 ? data[index - 1] : undefined;
          const separatorRequired = isSeparatorRequired
            ? isSeparatorRequired({prevItem, item})
            : true;
          return (
            <View key={key}>
              {separatorRequired ? renderSeparator({item, index}) : null}
              {_renderItem({item, index})}
            </View>
          );
        },
        [_renderItem, data],
      )
    : _renderItem;

  const onScroll = useCallback(
    e => {
      pOnScroll && pOnScroll(e);

      const {nativeEvent} = e;
      if (nativeEvent) {
        const {
          contentSize: {height},
          contentOffset: {y},
        } = nativeEvent;

        let loadMoreOffset = height * 0.5;
        if (y && height && y > loadMoreOffset) {
          //if y has passed the 50% mark then we can load more data
          //we can load more records here
          loadMore && loadMore();
        }
        return;
      }

      const {scrollTop, scrollHeight} = e;

      if (scrollTop > scrollHeight / 2 - 200) {
        loadMore && loadMore();
      }
    },
    [loadMore, pOnScroll, data],
  );

  return (
    <>
      {renderFixedHeader ? <View>{renderFixedHeader}</View> : null}
      <FlatList
        loading={loading}
        loaded={loaded}
        renderLoading={renderLoading}
        renderNoData={renderNoData}
        renderItem={renderItem}
        ListEmptyComponent={renderNoData}
        onScroll={onScroll}
        {...rest}
      />
    </>
  );
};

export default List;
