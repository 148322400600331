const Page = () => {
  return {
    layout: {
      md: {
        fillParent: true,

        columns: [
          {id: 'menus'},
          {
            id: 'viewContainer',
            fillParent: true,
            rows: [{id: 'header'}, {id: 'view', fillParent: true}],
          },
        ],
      },
      sm: {
        fillParent: true,

        rows: [{id: 'header'}, {id: 'view', fillParent: true}, {id: 'menus'}],
      },
    },
  };
};

export default {
  Page,
};
