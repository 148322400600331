export const TagChipModal = ({theme: {colors, fonts, icons}}) => {
  return {
    sm: {},
    md: {
      modalContainer: {
        borderRadius: 6,
        alignSelf: 'center',
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        backgroundColor: '#FFFFFF',
      },
      textContainer: {
        marginLeft: 12,
        marginRight: 12,
        marginTop: 8,
      },
      textStyle: {
        color: colors.NEUTRAL_HIGH,
      },
      timeText: {
        color: '#AAAAAA',
        marginTop: 8,
        marginBottom: 8,
        fontSize: 14,
      },
      listContainer: {
        marginLeft: 10,
        marginTop: 4,
        marginBottom: 8,
        color: colors.NEUTRAL_HIGH,
      },
    },
  };
};
