import React from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Platform,
} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';
import {Avatar} from '@unthinkable/react-avatar';
import {useAppStateContext} from '../../unthinkable-components/AppState';

const NewHeaderComponent = ({
  title,
  wraperStyle,
  FloomLogo,
  containerStyle,
  textStyle,
  secondaryTitleStyle,
  textWrapper,
  MessageQuestion,
  iconStyle,
  ProfileAvatar,
  avatarStyle,
  MessageQuestionIcon,
  headerText = 'Floom',
  MessageQuestionNative,
  helpButtonTextStyle,
  avatarColor,
  ArrowLeft,
  ArrowLeftIconStyle,
  DotsIcon,
}) => {
  let {user} = useAppStateContext();

  return (
    <View style={wraperStyle}>
      <View style={containerStyle}>
        <View style={{flex: 1, flexDirection: 'row'}}>
          {Platform.OS === 'web' ? (
            <Image source={FloomLogo} style={iconStyle} />
          ) : // <Image source={ArrowLeft} style={ArrowLeftIconStyle} />
          null}
          <Text style={textStyle}>{headerText}</Text>
        </View>
        <View style={secondaryTitleStyle}>
          {Platform.OS === 'web' ? (
            <TouchableOpacity style={textWrapper}>
              <Image source={MessageQuestion} style={MessageQuestionIcon} />
              {Platform.OS === 'web' ? (
                <Text style={helpButtonTextStyle}>Need help?</Text>
              ) : null}
            </TouchableOpacity>
          ) : (
            <TouchableOpacity>
              <Image
                source={MessageQuestionNative}
                style={MessageQuestionIcon}
              />
            </TouchableOpacity>
          )}
          {Platform.OS !== 'web' ? (
            <View style={{flexDirection: 'row'}}>
              <Image source={DotsIcon} style={MessageQuestionIcon} />
              <Image source={MessageQuestion} style={MessageQuestionIcon} />
            </View>
          ) : null}
          <Avatar value={user?.name} {...avatarStyle} />
        </View>
      </View>
    </View>
  );
};
const NewHeader = props => {
  const theme = useComponentTheme('NewHeader');
  return <NewHeaderComponent {...theme} {...props} />;
};
export default NewHeader;
