import React, {useState} from 'react';
import {View, Text, TouchableOpacity} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';
const NotificationCard = ({
  textStyle,
  cardWrapperStyle,
  countStyle,
  pushNotificationTextStyle,
  data,
}) => {
  const notifications = ['Sent', 'Delivered', 'Clicked'];

  let {
    notificationClickedtotalCount = 0,
    notificationDeliveredtotalCount = 0,
    notificationSentOnlytotalCount = 0,
  } = data || {};
  const notificationCount = [
    notificationSentOnlytotalCount,
    notificationDeliveredtotalCount,
    notificationClickedtotalCount,
  ];

  return (
    <View>
      <View style={cardWrapperStyle}>
        <View style={{flexDirection: 'row', padding: 8}}>
          <View style={{flex: 1}}>
            <Text style={pushNotificationTextStyle}>Push Notification</Text>
            {notifications.map(item => {
              return <Text style={textStyle}>{item}</Text>;
            })}
          </View>
          <View>
            <TouchableOpacity>
              <Text style={{...countStyle, color: '#7DCD28'}}>
                See templates
              </Text>
            </TouchableOpacity>
            {notificationCount &&
              notificationCount.map(item => {
                return <Text style={countStyle}>{item}</Text>;
              })}
          </View>
        </View>
      </View>
    </View>
  );
};

const NotificationCards = props => {
  const theme = useComponentTheme('NotificationCards');
  return <NotificationCard {...theme} {...props} />;
};
export default NotificationCards;
