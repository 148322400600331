export const Header = ({theme: {colors, fonts, icons}}) => {
  return {
    backIcon: icons.Back,
    containerStyle: {
      padding: 12,
      backgroundColor: colors.SURFACE1,
      alignItems: 'center',
    },
    titleStyle: {
      ...fonts.TITLE,
      color: colors.NEUTRAL_HIGH,
    },
  };
};

export const HeaderTitle = ({theme: {colors, fonts}}) => {
  return {
    iconContainerStyle: {
      backgroundColor: colors.PRIMARY_LOW,
      height: 36,
      width: 36,
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 12,
    },
    iconStyle: {
      height: 20,
      width: 20,
    },
    labelStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
    titleStyle: {
      ...fonts.HEADING1,
      color: colors.NEUTRAL_HIGH,
    },
    secondaryTitleStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};

export const HeaderActions = () => {
  return {
    gap: 12,
    containerStyle: {
      marginLeft: 12,
      marginRight: 6,
      alignItems: 'center',
    },
  };
};

export const ModalHeader = ({theme: {colors, fonts}}) => {
  return {
    gap: 12,
    actionGap: 8,
    containerStyle: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
      // paddingBottom: 12,
      alignItems: 'center',
    },
    iconContainerStyle: {
      backgroundColor: colors.PRIMARY_LOW,
      height: 36,
      width: 36,
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconStyle: {
      height: 20,
      width: 20,
    },
    labelStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
    titleStyle: {
      ...fonts.HEADING1,
      color: colors.NEUTRAL_HIGH,
    },
    secondaryTitleStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};

export const ModalFooter = ({theme: {colors, fonts}}) => {
  return {
    actionGap: 8,
    containerStyle: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 12,
      paddingBottom: 20,
      alignItems: 'center',
    },
  };
};

export const ViewHeader = ({theme: {colors, fonts}}) => {
  return {
    gap: 12,
    actionGap: 8,
    containerStyle: {
      paddingBottom: 12,
      alignItems: 'center',
    },
    iconContainerStyle: {
      backgroundColor: colors.PRIMARY_LOW,
      height: 36,
      width: 36,
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconStyle: {
      height: 20,
      width: 20,
    },
    labelStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
    titleStyle: {
      ...fonts.HEADING1,
      color: colors.NEUTRAL_HIGH,
    },
    secondaryTitleStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};

export const ModalContainer = ({theme: {colors, fonts}}) => {
  return {
    closeStyle: {
      position: 'absolute',
      top: -8,
      right: -8,
      height: 24,
      width: 24,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.SURFACE2,
      borderRadius: 24,
    },
  };
};


