export const LoginForm = ({theme: {fonts, colors, icons}}) => {
  return {
    containerStyle: {
      position: 'absolute',
      right: '10%',
      bottom: '30%',
      justifyContent: 'space-around',
      backgroundColor: '#FCFCFC',
      paddingTop: '3%',
      paddingBottom: '3%',
      paddingRight: '3%',
      paddingLeft: '3%',
      borderWidth: 1,
      borderColor: '#E5E5EA',
      borderRadius: 8,
    },
    headerContainerStyle: {
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '10%',
    },
    headerTextStyle: {...fonts.SECTION_HEADING1, color: colors.NEUTRAL_MEDIUM},
    textInputContainerStyle: {
      marginTop: '7%',
      marginBottom: '7%',
      paddingTop: '7%',
      paddingRight: '7%',
      paddingBottom: '7%',
      paddingLeft: '7%',
      backgroundColor: colors.SURFACE1,
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      borderRadius: 8,
    },
    FormTextContainerStyle: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    radioButtonStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
      cursor: 'pointer',
    },
    forgotPasswordTextStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.PRIMARY_HIGH,
      cursor: 'pointer',
    },
    loginButtonStyle: {
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '12%',
      marginBottom: '12%',
      paddingTop: '6%',
      paddingBottom: '6%',
      backgroundColor: colors.SUCCESS_HIGH,
      borderRadius: 8,
      cursor: 'pointer',
    },
    loginTextStyle: {...fonts.ACTION, color: colors.BACKGROUND},
    saparatorContainerStyle: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#E5E5EA',
      height: 1,
      marginBottom: '12%',
    },
    saperatorTextStyle: {
      padding: 10,
      backgroundColor: '#FCFCFC',
      ...fonts.BODY3,
      color: colors.NEUTRAL_LOW,
    },
  };
};
