const CenterList = ({theme: {colors, fonts, icons}}) => {
  return {
    sm: {
      style: {
        flex: 1,
      },
    },
    md: {
      style: {
        flex: 1,
        cursor: 'pointer',
      },
    },

    noDataStyle: {
      containerStyle: {
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: '',
      },
      icon: icons.EmptyMessage,
      iconStyle: {
        height: 137,
        width: 137,
      },
      emptyTextStyle: {
        ...fonts.PAGE_PARAGRAPH_LARGE,
        color: '',
        marginTop: 24,
      },
    },
  };
};

export default {
  CenterList,
};
