import React from 'react';
import {PopperComponent} from './PopperComponent';
import {MobilePopper} from './MobilePopper';
const WithModal = props => {
  const {position, children} = props;

  if (position?.includes('screen') || position?.includes('Screen')) {
    return <MobilePopper {...props} />;
  }
  return <PopperComponent {...props}>{children}</PopperComponent>;
};

export default WithModal;
