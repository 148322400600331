const Avatar = '/images/Avatar.svg';
const LOCOMO = '/images/LOCOMO.svg';
const EmpoweringGrowth = '/images/EmpoweringGrowth.svg';
const LoginBackgroundImage = '/images/LoginBackgroundImage.svg';
const ToggleUp = '/images/ToggleUp.svg';
const ToggleDown = '/images/ToggleDown.svg';
const FloomLogo = '/images/FloomLogo.svg';
const MessageQuestion = '/images/MessageQuestion.svg';
const ProfileAvatar = '/images/ProfileAvatar.svg';
const MessageQuestionNative = '/images/MessageQuestionNative.svg';
const EmptyImage = '/images/EmptyImage.svg';
const ToggleRight = '/images/toggleRight.svg';
const InfoIcon = '/images/InfoIcon.svg';
const CrossIcon = '/images/CrossIcon.svg';
const DotsIcon = '/images/DotsIcon.svg';
const PlusIcon = '/images/PlusIcon.svg';
const DownArrowIcon = '/images/DownArrowIcon.svg';
const CheckedIcon = '/images/CheckedIcon.svg';
const ShareIcon = '/images/ShareIcon.svg';
const ActiveRadioIcon = '/images/ActiveRadioIcon.svg';
const InactiveIcon = '/images/InactiveIcon.svg';
const Cross = '/images/Cross.svg';
const arrowDownIcon = '/images/arrowDownIcon.svg';
const ArrowLeft = '/images/ArrowLeft.svg';

export default {
  Avatar,
  LOCOMO,
  EmpoweringGrowth,
  LoginBackgroundImage,
  ToggleUp,
  ToggleDown,
  FloomLogo,
  MessageQuestion,
  ProfileAvatar,
  MessageQuestionNative,
  EmptyImage,
  ToggleRight,
  InfoIcon,
  CrossIcon,
  DotsIcon,
  PlusIcon,
  DownArrowIcon,
  CheckedIcon,
  ShareIcon,
  ActiveRadioIcon,
  InactiveIcon,
  Cross,
  arrowDownIcon,
  ArrowLeft,
};
