import React from 'react';
import {Route} from '@unthinkable/react-router';
import {Login} from '../modules/login/views/Login';
import Signup from '../modules/login/views/Signup';

const LoginRoute = (
  <Route
    path="login"
    elements={{
      view: <Login />,
    }}
  />
);

const SignupRute = (
  <Route
    path="signup"
    elements={{
      view: <Signup />,
    }}
  />
);

export default [LoginRoute, SignupRute];
