import {Login} from './Login';
import {LoginForm} from './LoginForm';
import {RecursiveEvents} from './RecursiveEvents';
import {NewAnalytics} from './NewAnalytics';

import {VersionWiseEngagement} from './VersionWiseEngagement';

export default {
  Login,
  LoginForm,
  RecursiveEvents,
  VersionWiseEngagement,
  NewAnalytics,
};
