import React, {useCallback} from 'react';
import {useNavigate} from '@unthinkable/react-router';
import {TouchableOpacity, Image} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';

const CloseActionComponent = ({
  onPress,
  pop = -1,
  icon,
  containerStyle,
  iconStyle,
}) => {
  let navigate = useNavigate();
  const onPressCallback = useCallback(e => {
    if (onPress) {
      onPress(e);
    } else {
      navigate(pop);
    }
  });
  return (
    <TouchableOpacity style={containerStyle} onPress={onPressCallback}>
      <Image style={iconStyle} source={icon} />
    </TouchableOpacity>
  );
};

export const CloseAction = props => {
  const theme = useComponentTheme('CloseAction') || {};
  return <CloseActionComponent {...theme} {...props} />;
};

export const CloseSmallAction = props => {
  const theme = useComponentTheme('CloseSmallAction') || {};
  return <CloseActionComponent {...theme} {...props} />;
};
