import React, {useCallback} from 'react';
import {
  View,
  ScrollView,
  Text,
  TouchableOpacity,
  Image,
} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';
import {WithModal} from '@unthinkable/react-popper';

export const MoreActionComponent = ({
  positionX,
  positionY,
  icon,
  imageContainerStyle,
  imageStyle,
  actionHeight,
  actionWidth,
  actionContainerStyle,
  actionScrollStyle,
  modalContainerStyle,
  titleStyle,
  actions,
  onPressData,
}) => {
  if (!actions?.length) {
    return null;
  }
  const renderModal = useCallback(({hide}) => {
    return (
      <View style={{...modalContainerStyle}}>
        <ScrollView style={actionScrollStyle}>
          {actions.map(action => {
            let {title, onPress} = action;
            return (
              <TouchableOpacity
                style={actionContainerStyle}
                onPress={() => {
                  onPress && onPress(onPressData);
                  hide();
                }}>
                <Text style={titleStyle}>{title}</Text>
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      </View>
    );
  });
  let modalHeight = actions.length * actionHeight;
  let modalWidth = actionWidth;
  return (
    <View
      style={{
        cursor: 'pointer',
        ...imageContainerStyle,
      }}>
      <WithModal renderModal={renderModal}>
        <Image source={icon} style={imageStyle} />
      </WithModal>
    </View>
  );
};

export const MoreAction = props => {
  let theme = useComponentTheme('MoreAction');
  return <MoreActionComponent {...theme} {...props} />;
};
