import React from 'react';
import {Image, Text, View} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';
import {LoginForm} from './LoginForm';

export const Login = () => {
  const theme = useComponentTheme('Login');
  const {
    LOCOMOIcon,
    EmpoweringGrowthIcon,
    mainContainerStyle,
    topContainerStyle,
    LOCOMOIconStyle,
    EmpoweringGrowthIconStyle,
    bottomContainerStyle,
  } = theme;

  return (
    <View style={mainContainerStyle}>
      <View style={topContainerStyle}>
        <Image source={LOCOMOIcon} style={LOCOMOIconStyle} />
        <Image source={EmpoweringGrowthIcon} />
      </View>
      <View style={bottomContainerStyle}>
        <LoginForm />
      </View>
    </View>
  );
};
