import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import axios from 'axios';
import {Platform, Text, View} from '@unthinkable/react-core-components';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useReplace} from '@unthinkable/react-router';

const AppStateContext = React.createContext();

const useAppState = props => {
  const {loginRoute, userRoute} = props;
  let [state, setAppState] = useState(props);

  let replace = useReplace();

  const {
    config: {fetchUrl, postUrl, uploadUrl, downloadUrl},
    api,
  } = state;

  const setState = useCallback(
    value => {
      let newState = {...state, ...value};
      setAppState(newState);
    },
    [state],
  );
  let {token} = state;
  const setUser = useCallback(
    result => {
      let {token} = result;
      AsyncStorage.setItem('token', token);
      setState(result);
      replace(userRoute);
    },
    [state],
  );
  const logout = useCallback(() => {
    AsyncStorage.removeItem('token');
    setState({status: 'anonyms'});
    replace(loginRoute);
  }, [setState]);
  const upload = useCallback(
    async (file, options = {}) => {
      let name = file.name.replace(/[&\/\\#,^@!+()$~%" "'":*?<>{}-]/g, '_');
      if (Platform.OS === 'web') {
        file = new File([file], name, {type: file.type});
      } else {
        file.name = name;
      }
      let {multiPart = true, uri = '/upload'} = options;
      if (multiPart) {
        let formData = new FormData();
        formData.append('file', file);
        // formData.append('token', token);

        return axios
          .post(`${uploadUrl}${uri}`, formData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          })
          .then(res => {
            let result = res.data;
            result = result.result;
            return result;
          })
          .catch(e => {
            console.log('@@@@ error in file upload', e);
          });
      } else {
        console.warn(new Error('Upload not supported without multiPart'));
      }
    },
    [uploadUrl],
  );

  const resourceUrl = useCallback(
    file => {
      if (!file || !file.id) {
        return;
      }
      const url = `${downloadUrl}/files/${file.id}`;
      return url;
    },
    [downloadUrl],
  );

  const fetch = useCallback(
    async ({uri, props}) => {
      let url = fetchUrl + '' + uri;

      let headers = {};
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
      let resp = await axios.get(url, {params: props, headers});
      resp = resp.data;
      return resp;
    },
    [fetchUrl, token],
  );

  const post = useCallback(
    async ({uri, props, method = 'post'}) => {
      let headers = {};
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }

      try {
        let resp = await axios['post'](postUrl + '' + uri, props, {
          headers,
        });
        resp = resp.data;
        return resp;
      } catch (e) {
        if (e.response && e.response.data && e.response.data.error) {
          e = new Error(e.response.data.error);
        }

        throw e;
      }
    },
    [postUrl, token],
  );

  useEffect(async () => {
    let token = await AsyncStorage.getItem('token');
    if (token) {
      let params = api({token});
      try {
        let resp = await post(params);
        let {result} = resp;
        if (result) {
          setState({status: 'intialized', ...result, token});
          replace(userRoute);
        } else {
          setState({status: 'anonyms'});
        }
      } catch (e) {
        console.log('@@@@ Error:', e.message);
        if (e.message === 'invalid_token') {
          await AsyncStorage.removeItem('token');
          setState({status: 'anonyms'});
        }
      }
    } else {
      setState({status: 'anonyms'});
    }
  }, []);
  const value = useMemo(() => {
    return {post, fetch, upload, resourceUrl, setUser, logout, ...state};
  }, [state]);
  return value;
};

export const AppStateProvider = ({
  userRoute,
  loginRoute,
  api,
  config,
  children,
}) => {
  let value = useAppState({
    api,
    config,
    userRoute,
    loginRoute,
    status: 'initializing',
  });
  let {status} = value;

  if (status === 'initializing') {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
};
export const useAppStateContext = () => {
  return useContext(AppStateContext);
};
