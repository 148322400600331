import {
  useNavigate,
  useParams,
  usePush,
  useRouteContext,
} from '@unthinkable/react-router';
import {useCallback} from 'react';

export const useUserDetails = () => {
  let navigate = useNavigate();
  let {pathname, params} = useRouteContext();
  return useCallback(
    ({usersList}) => {
      navigate(`/userDetails`, {
        params: {usersList},
      });
    },
    [navigate, pathname, params],
  );
};

export const navigateToNewUserSegment = () => {
  const navigate = useNavigate();
  const params = useParams();
  return useCallback(() => {
    navigate(`/add-segment`, {
      params: {
        defaultValues: {...params},
      },
    });
  }, [navigate, params]);
};

export const groupByUtmAd = () => {
  let navigate = useNavigate();
  let {pathname, params} = useRouteContext();
  return useCallback(
    ({startDate, endDate, type}) => {
      navigate(`/deviceDetails`, {
        params: {startDate, endDate, type},
      });
    },
    [navigate, pathname, params],
  );
};

export const NewIntallUserNavigate = () => {
  let navigate = useNavigate();
  let {pathname, params} = useRouteContext();
  return useCallback(() => {
    navigate(`/new-install`, {
      params: {name: 'rahul', ...params},
    });
  }, [navigate, pathname, params]);
};
