export const MenuPage = ({theme: {colors}}) => {
  return {
    layout: {
      md: {
        fillParent: true,
        backgroundColor: colors.BACKGROUND,
        columns: [
          {id: 'menus'},
          {
            id: 'viewContainer',
            fillParent: true,
            rows: [
              {
                id: 'headerContainer',
                backgroundColor: colors.SURFACE2,
                containerStyle: {
                  paddingLeft: 24,
                  paddingRight: 24,
                  paddingTop: 24,
                  paddingBottom: 18,
                },
                columns: [
                  {id: 'header', fillParent: true},
                  {id: 'headerActions'},
                ],
              },
              {
                id: 'view',
                fillParent: true,
              },
            ],
          },
        ],
      },
      sm: {
        fillParent: true,
        backgroundColor: colors.BACKGROUND,
        rows: [
          {
            id: 'headerContainer',
            columns: [{id: 'header', fillParent: true}, {id: 'headerActions'}],
          },
          {id: 'view', fillParent: true},
          {id: 'menus'},
        ],
      },
    },
    menus: {
      md: {
        position: 'left',
      },
      sm: {
        position: 'bottom',
      },
    },
  };
};

export const TopMenuPage = ({theme: {colors}}) => {
  return {
    layout: {
      fillParent: true,
      backgroundColor: colors.BACKGROUND,
      rows: [
        {
          id: 'headerContainer',
          columns: [{id: 'header', fillParent: true}, {id: 'headerActions'}],
          containerStyle: {
            paddingTop: 12,
            paddingBottom: 12,
          },
        },
        {id: 'menus'},
        {
          id: 'menuHeader',
        },
        {
          id: 'view',
          containerStyle: {
            paddingTop: 12,
            paddingBottom: 12,
          },
          fillParent: true,
        },
      ],
    },
    menus: {
      position: 'top',
    },
  };
};

export const BottomMenuPage = ({theme: {colors}}) => {
  return {
    layout: {
      fillParent: true,
      backgroundColor: colors.BACKGROUND,

      rows: [
        {
          id: 'headerContainer',
          columns: [{id: 'header', fillParent: true}, {id: 'headerActions'}],
        },
        {id: 'view', fillParent: true},
        {id: 'menus'},
      ],
    },
    menus: {
      position: 'bottom',
    },
  };
};

export const LeftMenuPage = ({theme: {colors}}) => {
  return {
    layout: {
      fillParent: true,
      backgroundColor: colors.BACKGROUND,
      rows: [
        {id: 'menus'},
        {
          id: 'viewContainer',
          fillParent: true,
          containerStyle: {
            margin: 20,
          },
          column: [
            {id: 'header'},
            {
              id: 'headerContainer',
              columns: [
                {id: 'headerTitle', fillParent: true},
                {id: 'headerActions'},
              ],
            },
            {id: 'view', fillParent: true},
          ],
        },
      ],
    },
  };
};

export const LeftMenusHeaderPage = ({theme: {colors}}) => {
  return {
    layout: {
      fillParent: true,
      backgroundColor: colors.BACKGROUND,
      containerStyle: {
        paddingTop: 12,
        paddingBottom: 12,
      },
      columns: [
        {
          id: 'menusContainer',
          containerStyle: {
            marginRight: 24,
          },
          rows: [
            {
              id: 'menusHeader',
              containerStyle: {
                marginBottom: 12,
              },
            },
            {id: 'menus', fillParent: true},
          ],
        },
        {
          id: 'viewContainer',
          fillParent: true,
          rows: [
            {
              id: 'headerContainer',
              containerStyle: {
                marginBottom: 12,
              },
              columns: [
                {id: 'headerTitle', fillParent: true},
                {id: 'headerActions'},
              ],
            },
            {id: 'view', fillParent: true},
          ],
        },
      ],
    },
  };
};
