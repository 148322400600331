import React from 'react';
import {
  LocationContextProvider,
  RouteContextProvider,
} from '@unthinkable/react-router';

import {MediaQueryProvider, ThemeProvider} from '@unthinkable/react-theme';

import theme from './theme';
import Router from './routes';
import {EventHandlerProvider} from '@unthinkable/react-event-handler';
import {AppStateProvider} from './unthinkable-components/AppState';
import {getUserByToken} from './modules/login/apis';

import {Toast, ToastProvider} from './components/toast/Toast';

import Config from './Config';

function App() {
  const {
    base: {breakpoints},
  } = theme;

  return (
    <LocationContextProvider location={'/login'}>
      <RouteContextProvider pathname="">
        <EventHandlerProvider>
          <AppStateProvider
            api={getUserByToken}
            config={Config}
            loginRoute="/login"
            userRoute="/home/NewAnalytics">
            <MediaQueryProvider breakpoints={breakpoints}>
              <ThemeProvider {...theme}>
                <ToastProvider>
                  <>
                    <Router />
                    <Toast />
                  </>
                </ToastProvider>
              </ThemeProvider>
            </MediaQueryProvider>
          </AppStateProvider>
        </EventHandlerProvider>
      </RouteContextProvider>
    </LocationContextProvider>
  );
}

export default App;
