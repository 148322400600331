import React, {Component, useCallback, useEffect, useState} from 'react';
import {
  Dimensions,
  View,
  Text,
  TouchableWithoutFeedback,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableHighlight,
  FlatList,
  Platform,
  Utility,
  TextInput,
} from '@unthinkable/react-core-components';
import {WithModal} from '@unthinkable/react-popper';

const {resolveExp} = Utility;
const TOUCHABLE_ELEMENTS = [
  'TouchableHighlight',
  'TouchableOpacity',
  'TouchableWithoutFeedback',
  'TouchableNativeFeedback',
];

export const AutoCompleteBottom = props => {
  const {
    renderSeparator,
    showsVerticalScrollIndicator,
    keyboardShouldPersistTaps = 'always',
    keyExtractor,
    data,
    separatorStyle,
    onSelect,
    children,
    renderSuggestion,
    dropdownItemStyle,
    accessible,
    suggestionField,
    secondarySuggestionField,
    fetchSuggestions,
    placeholder = 'Type here...',
    bottomModalStyle,
  } = props;
  const [searchValue, setSearchValue] = useState();

  useEffect(() => {
    fetchSuggestions();
  }, []);

  const onChangeSearchValue = useCallback(
    searchText => {
      setSearchValue(searchText);
      fetchSuggestions(searchText);
    },
    [setSearchValue, fetchSuggestions],
  );

  const displayText = ({item, suggestionField}) => {
    if (Array.isArray(suggestionField)) {
      return suggestionField.map(display => {
        let rowText =
          typeof item === 'object' ? resolveExp(item, display) : item;
        if (typeof rowText === 'object') {
          rowText = JSON.stringify(rowText);
        }
        return rowText;
      });
    }
    let rowText =
      typeof item === 'object' && suggestionField
        ? resolveExp(item, suggestionField)
        : item;
    if (typeof rowText === 'object') {
      rowText = JSON.stringify(rowText);
    }
    return rowText;
  };

  const _renderSeparator = rowID => {
    return <View key={`spr_${rowID}`} style={separatorStyle} />;
  };

  const _onRowPress = ({item, index, isNew}, e) => {
    onSelect && onSelect({item: item, index, isNew}, e);
  };

  const _renderRow =
    hide =>
    ({item, index}) => {
      const {containerStyle, textStyle, secondaryTextStyle} =
        dropdownItemStyle || {};

      let row = void 0;
      if (renderSuggestion) {
        row = renderSuggestion({
          item,
          index,
          props,
        });
      } else {
        let rowText = displayText({item, suggestionField});
        let rowSecondaryText = displayText({
          item,
          suggestionField: secondarySuggestionField,
        });
        row = (
          <View style={containerStyle}>
            <Text style={[textStyle]}>{rowText}</Text>
            {secondarySuggestionField && (
              <Text style={[secondaryTextStyle]}>{rowSecondaryText}</Text>
            )}
          </View>
        );
      }

      let preservedProps;
      if (Platform.OS === 'web') {
        preservedProps = {
          accessible,
          onMouseDown: e => {
            e.preventDefault();
            _onRowPress({item, index}, e);
            hide();
            onChangeSearchValue('');
          },
        };
      } else {
        preservedProps = {
          accessible,
          onPress: e => {
            _onRowPress({item, index}, e);
            hide();
            onChangeSearchValue('');
          },
        };
      }
      if (TOUCHABLE_ELEMENTS.find(name => name == row.type.displayName)) {
        const props = {...row.props};
        props.key = preservedProps.key;
        props.onPress = preservedProps.onPress;
        const {children} = row.props;
        switch (row.type.displayName) {
          case 'TouchableHighlight': {
            return (
              <TouchableHighlight {...props}>{children}</TouchableHighlight>
            );
          }
          case 'TouchableOpacity': {
            return <TouchableOpacity {...props}>{children}</TouchableOpacity>;
          }
          case 'TouchableWithoutFeedback': {
            return (
              <TouchableWithoutFeedback {...props}>
                {children}
              </TouchableWithoutFeedback>
            );
          }
          case 'TouchableNativeFeedback': {
            return (
              <TouchableNativeFeedback {...props}>
                {children}
              </TouchableNativeFeedback>
            );
          }
          default:
            break;
        }
      }
      return (
        <TouchableOpacity
          onMouseMove={e => {
            e && e.preventDefault && e.preventDefault();
          }}
          className={`flatListRowFocus${index}`}
          {...preservedProps}>
          {row}
        </TouchableOpacity>
      );
    };

  return (
    <WithModal
      position="screenBottom"
      onClickOutside={() => {
        onChangeSearchValue('');
      }}
      renderModal={({hide}) => {
        const {
          containerStyle,
          inputContainerStyle,
          inputStyle,
          bodyContainerStyle,
          headerStyle,
        } = bottomModalStyle;
        return (
          <TouchableOpacity
            activeOpacity={1}
            style={containerStyle}
            onPress={e => {
              e?.stopPropagation();
            }}>
            <View style={headerStyle?.containerStyle}>
              <Text style={headerStyle?.textStyle}>Select</Text>
            </View>
            <View style={bodyContainerStyle}>
              <View style={inputContainerStyle}>
                <TextInput
                  style={inputStyle}
                  placeholder={placeholder}
                  value={searchValue}
                  onChangeValue={onChangeSearchValue}
                  showSoftInputOnFocus={false}
                />
              </View>
              <FlatList
                data={data}
                renderItem={_renderRow(hide)}
                ItemSeparatorComponent={renderSeparator || _renderSeparator}
                keyExtractor={keyExtractor}
                showsHorizontalScrollIndicator={showsVerticalScrollIndicator}
                keyboardShouldPersistTaps={keyboardShouldPersistTaps}
              />
            </View>
          </TouchableOpacity>
        );
      }}>
      {children}
    </WithModal>
  );
};
