export const ModalPage = ({theme: {colors, icons}}) => {
  return {
    backdropStyle: {
      backgroundColor: colors.MODAL_BACKGROUND,
    },
    sm: {
      containerStyle: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        backgroundColor: colors.BACKGROUND,
      },
      position: 'screenBottom',
      maxHeight: '70%',
      sizes: {
        small: {minHeight: '40%'},
        medium: {minHeight: '60%'},
        large: {minHeight: '80%'},
      },
    },
    md: {
      containerStyle: {
        borderRadius: 8,
        backgroundColor: colors.BACKGROUND,
      },
      position: 'screenCenter',
      minWidth: '60%',
      maxWidth: '90%',
      maxHeight: '90%',
      minHeight: '60%',
      sizes: {
        auto: {minWidth: '0%', minHeight: '0%'},
        extra_small: {minWidth: '50%', minHeight: '20%'},
        small: {width: '60%', height: '60%'},
        medium: {width: '75%', height: '75%'},
        large: {width: '90%', height: '90%'},
      },
    },
  };
};
