export const DateCell = ({theme: {colors, fonts}}) => {
  return {
    format: 'DD MMM YY',
    textStyle: {...fonts.HEADING1, color: colors.NEUTRAL_LOW},
  };
};

export const TextCell = ({theme: {colors, fonts}}) => {
  return {
    textStyle: {...fonts.BODY3, color: colors.NEUTRAL_HIGH},
  };
};

export const TitleCell = ({theme: {colors, fonts}}) => {
  return {
    titleStyle: {...fonts.BODY3, color: colors.NEUTRAL_HIGH},
    secondaryTitleStyle: {
      paddingTop: 4,
      ...fonts.CAPTION,
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};

export const TaggedTextCell = ({theme: {colors, fonts}}) => {
  return {
    titleStyle: {...fonts.BODY3, color: colors.NEUTRAL_HIGH},
    tagsStyle: {
      containerStyle: {
        marginTop: 12,
        flexDirection: 'row',
      },
    },
  };
};

export const ChipBadgeCell = ({theme: {icons, colors, fonts}}) => {
  const badgeAbsoluteStyle = {
    position: 'absolute',
    top: -5,
    right: -5,
    height: 14,
    width: 14,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  };
  return {
    TickWhite: icons.TickWhite,
    containerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    badgeContainerStyle: {
      ...badgeAbsoluteStyle,
      backgroundColor: colors.ACCENT2_HIGH,
    },
    badgeTextStyle: {
      color: colors.BACKGROUND,
      ...fonts.FOOTNOTE,
    },
    markContainerStyle: {
      ...badgeAbsoluteStyle,
      backgroundColor: colors.SUCCESS_HIGH,
    },
    markIconStyle: {
      height: 5,
    },
    chipStyle: {
      containerStyle: {
        borderRadius: 19,
        borderColor: colors.NEUTRAL_LOW,
        borderWidth: 1,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 9,
        paddingRight: 9,
      },
      labelStyle: {
        ...fonts.BODY2,
        color: colors.NEUTRAL_MEDIUM,
      },
    },
  };
};

export const LinkCell = ({theme: {colors, fonts, icons}}) => {
  return {
    containerStyle: {cursor: 'pointer', flexDirection: 'row', gap: 9},
    linkIcon: icons.FigmaIcon,
    textStyle: {...fonts.BODY3, color: colors.ACCENT1_HIGH},
    iconStyle: {height: 20, width: 20},
  };
};

export const DesignColumnCell = ({theme: {colors, fonts}}) => {
  return {
    containerStyle: {flexDirection: 'row', gap: 12, alignItems: 'center'},
    dotStyle: {
      height: 6,
      width: 6,
      borderRadius: 6,
      backgroundColor: colors.OUTLINE,
    },
    textStyle: {
      color: colors.PRIMARY_HIGH,
      ...fonts.ACTION,
    },
  };
};

export const PrimaryDetailCell = ({theme: {colors, fonts}}) => {
  return {
    gap: 8,
    containerStyle: {
      alignItems: 'center',
    },
    iconContainerStyle: {
      backgroundColor: colors.PRIMARY_LOW,
      height: 32,
      width: 32,
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
    },
    improvementIconContainerStyle: {
      backgroundColor: colors.ACCENT4_LOW,
      height: 32,
      width: 32,
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconStyle: {
      height: 20,
      width: 20,
    },
    primaryTextStyle: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    secondaryTextStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
    countStyle: {
      paddingLeft: 12,
      ...fonts.HEADING1,
      color: colors.PRIMARY_HIGH,
    },
  };
};

export const PrimaryCell = ({theme: {colors, fonts}}) => {
  return {
    gap: 8,
    containerStyle: {
      alignItems: 'center',
    },
    iconContainerStyle: {
      backgroundColor: colors.PRIMARY_LOW,
      height: 32,
      width: 32,
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconStyle: {
      height: 20,
      width: 20,
    },
    primaryTextStyle: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
    secondaryTextStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
    countStyle: {
      paddingLeft: 12,
      ...fonts.BODY3,
      color: colors.PRIMARY_HIGH,
    },
  };
};

export const StandardCell = ({theme: {colors, fonts}}) => {
  return {
    gap: 8,
    containerStyle: {
      alignItems: 'center',
    },
    iconContainerStyle: {
      backgroundColor: colors.PRIMARY_LOW,
      height: 32,
      width: 32,
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconStyle: {
      height: 20,
      width: 20,
    },
    primaryTextStyle: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
    secondaryTextStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
    countStyle: {
      color: colors.NEUTRAL_HIGH,
      paddingLeft: 4,
      ...fonts.BODY3,
    },
  };
};
export const AttachmentCountCell = ({theme: {colors, fonts}}) => {
  return {
    gap: 4,
    textStyle: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_MEDIUM,
    },
    containerStyle: {
      cursor: 'pointer',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
};
