import React, {useEffect, useState} from 'react';
import {useDataFetch} from '../../../../controllers/useDataFetch';
import {newAllAnalytics, retentionAnalytics} from '../../Apis';

import {
  ScrollView,
  TouchableOpacity,
  View,
  Text,
  Image,
  Row,
  Platform,
  TouchableWithoutFeedback,
} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';
import {getTime} from '../../../../components/table/Utility';
import CenterList from '../../../../theme-component/list/center-list/CenterList';
import useLoadMore from '../../../../controllers/useLoadMore';
import {AutoComplete} from '../../../../components/form-editors/AutoComplete';
import {useEventHandler} from '@unthinkable/react-event-handler';
import NewHeader from '../../../../components/newHeader/newHeader';
import TimeFrame from '../../../../components/timeFrameHeader/timeFrameHeader';
import RightSideView from '../../../../components/RightSideView/RightSideView';
import ReleaseVersionChip from '../../../../components/releaseVersionChip/ReleaseVersionChip';
import {
  NewIntallUserNavigate,
  groupByUtmAd,
  useRahulNavigate,
} from '../../Controller';
import {useParams} from '@unthinkable/react-router';

let arr;

const Item = ({
  doc,
  state,
  setState,
  setStateData,
  index: _index,
  setDocIndex,
  docIndex,
  showVersionCard,
  setShowVersionCard,
  versionCard,
  setVersionCard,
  dateStyle,
  setNotificationUser,
}) => {
  // let [expanded, setExpanded] = useState();
  // let navigateToUTMGroupBy = groupByUtmAd();

  const [selectedIndex, setIndex] = useState();
  const {notify} = useEventHandler();

  const theme = useComponentTheme('NewAnalytics');

  const newInstall = NewIntallUserNavigate();

  let {
    RightArrowIcon,
    iconStyle,
    headerTextStyle,
    titleTextStyle,
    backgroundColor,
    containerStyle,
    titleValueStyle,
    selectStyle,
  } = theme;

  let versionArray = doc.versionList || [];
  let versionSlice = [];
  let arrayToShow = versionArray;
  let lengthOfVersionArray = 0;
  if (versionArray.length > 4) {
    versionSlice = versionArray.slice(0, 4) || [];
    lengthOfVersionArray = versionArray.length - versionSlice.length || 0;
    arrayToShow = versionSlice;
  }

  let span = doc.span || 'day';
  let notificationCount = doc.notificationPerUser;
  if (span === 'month') {
    notificationCount = doc.notificationPerUser / 30;
    notificationCount = notificationCount.toFixed(1);
  }

  if (span === 'week') {
    notificationCount = doc.notificationPerUser / 7;
    notificationCount = notificationCount.toFixed(1);
  }

  let onboardPercentage =
    (doc.ReachHomeuniqueCount / doc.installPipelineuniqueCount) * 100 || 0;
  let arrData = (doc && doc.versionList) || [];

  let {
    installPipelineuniqueCount = 0,
    activeUser_end = 0,
    churnCountDZero = 0,
    churnCount = 0,
    percentage_visit_d_zero = 0,
    percentage_visit = 0,
    visir_per_users_d_zero = 0,
    visir_per_users = 0,
    eventsPerUsers = 0,
    eventsPerUsersDZero,
    percentage_visit_upto_now = 0,
    visir_per_users_upto_now = 0,
    eventsPerUsers_Upto_now = 0,
    notificationSentOnlyPerUser = 0,
    notificationDeliveredPerUser = 0,
    notificationClickedPerUser = 0,
    userLifeTimetotalCount = 0,
  } = doc || {};

  arr = [
    {
      title: 'New Install',
      count: installPipelineuniqueCount,
      onPress: index => {
        setState(true);
        newInstall();
        setStateData({
          ...doc,
          type: 'install',
          title: 'New Install',
          versionInfo: versionCard?.Item,
        });
        setIndex(index);
        setTimeout(() => {
          notify('reloadRightMenu', {
            ...doc,
            type: 'install',
            versionInfo: versionCard?.Item,
          });
        }, 100);
        setNotificationUser(false);
      },
    },
    {
      title: 'Onboarded (%)',
      count: onboardPercentage.toFixed(1),
      onPress: index => {
        setIndex(index);
        setState(true);
        setStateData({
          ...doc,
          type: 'onboarded',
          title: 'Onboarded',
          versionInfo: versionCard?.Item,
        });
        setTimeout(() => {
          notify('reloadRightMenu', {
            ...doc,
            type: 'onboarded',
            versionInfo: versionCard?.Item,
          });
        }, 100);
        setNotificationUser(false);
      },
    },
    {
      title: 'Act. Users',
      count: activeUser_end,
      onPress: index => {
        setIndex(index);
        setState(true);
        setStateData({
          ...doc,
          type: 'Act. Users',
          title: 'Act. Users',
          versionInfo: versionCard?.Item,
        });
        setTimeout(() => {
          notify('reloadRightMenu', {
            ...doc,
            type: 'Act. Users',
            versionInfo: versionCard?.Item,
          });
        }, 100);
        setNotificationUser(false);
      },
    },

    {
      title: 'User lifetime (days)',
      count: userLifeTimetotalCount,
      onPress: index => {
        setIndex(index);
        setState(true);
        setStateData({
          ...doc,
          type: 'User lifetime (days)',
          title: 'User lifetime (days)',
        });
        setTimeout(() => {
          notify('reloadRightMenu', {...doc, type: 'User lifetime (days)'});
        }, 100);
        setNotificationUser(false);
      },
    },

    // {
    //   title: 'Churn rate- D0-D2 (%)',
    //   count: churnCountDZero,
    //   onPress: index => {
    //     setIndex(index);
    //     setState(true);
    //     setStateData({
    //       ...doc,
    //       type: 'Churn rate- D0-D2 (%)',
    //       title: 'Churn rate- D0-D2',
    //     });
    //     setTimeout(() => {
    //       notify('reloadRightMenu', {...doc, type: 'Churn rate- D0-D2 (%)'});
    //     }, 100);
    //     setNotificationUser(false);
    //   },
    // },
    // {
    //   title: 'Churn rate- D2+ (%)',
    //   count: churnCount,
    //   onPress: index => {
    //     setIndex(index);
    //     setState(true);
    //     setStateData({
    //       ...doc,
    //       type: 'Churn rate- D2+ (%)',
    //       title: 'Churn rate- d2+ (%)',
    //     });
    //     setTimeout(() => {
    //       notify('reloadRightMenu', {...doc, type: 'Churn rate- D2+ (%)'});
    //     }, 100);
    //     setNotificationUser(false);
    //   },
    // },
    {
      title: 'Notification/User',
      count: notificationCount,
      onPress: index => {
        setIndex(index);
        setState(true);
        setStateData({
          ...doc,
          type: 'Notification/User',
          title: 'Notification/User',
        });
        setTimeout(() => {
          notify('reloadRightMenu', {...doc, type: 'Notification/User'});
        }, 100);
        setNotificationUser(true);
      },
    },

    {
      title: 'Unique opens (%)',
      count: percentage_visit_upto_now,
      onPress: index => {
        setIndex(index);
        setState(true);
        setStateData({
          ...doc,
          type: 'Unique opens',
          title: 'Unique opens',
        });
        setTimeout(() => {
          notify('reloadRightMenu', {...doc, type: 'Unique opens'});
        }, 100);
        setNotificationUser(false);
      },
    },

    // {
    //   title: 'Unique opens D0',
    //   count: percentage_visit_d_zero,
    //   onPress: index => {
    //     setIndex(index);
    //     setState(true);
    //     setStateData({
    //       ...doc,
    //       type: 'Unique opens D0',
    //       title: 'Unique opens D0',
    //     });
    //     setTimeout(() => {
    //       notify('reloadRightMenu', {...doc, type: 'Unique opens D0'});
    //     }, 100);
    //   },
    // },
    // {
    //   title: 'Unique opens D0+',
    //   count: percentage_visit,
    //   onPress: index => {
    //     setIndex(index);
    //     setState(true);
    //     setStateData({
    //       ...doc,
    //       type: 'Unique opens D0+',
    //       title: 'Unique opens D0+',
    //     });
    //     setTimeout(() => {
    //       notify('reloadRightMenu', {
    //         ...doc,
    //         type: 'Unique opens D0+',
    //       });
    //     }, 100);
    //   },
    // },

    {
      title: 'Visit/User',
      count: visir_per_users_upto_now,
      onPress: index => {
        setIndex(index);
        setState(true);
        setStateData({
          ...doc,
          type: 'Visit/User',
          title: 'Visit/User',
        });
        setTimeout(() => {
          notify('reloadRightMenu', {...doc, type: 'Visit/User'});
        }, 100);
        setNotificationUser(false);
      },
    },

    // {
    //   title: 'Visit/User- D0',
    //   count: visir_per_users_d_zero,
    //   onPress: index => {
    //     setIndex(index);
    //     setState(true);
    //     setStateData({
    //       ...doc,
    //       type: 'Visit/User- D0',
    //       title: 'Visit/User- D0',
    //     });
    //     setTimeout(() => {
    //       notify('reloadRightMenu', {...doc, type: 'Visit/User- D0'});
    //     }, 100);
    //   },
    // },
    // {
    //   title: 'Visit/User- D0+',
    //   count: visir_per_users,
    //   onPress: index => {
    //     setIndex(index);
    //     setState(true);
    //     setStateData({
    //       ...doc,
    //       type: 'Visit/User- D0+',
    //       title: 'Visit/User- D0+',
    //     });
    //     setTimeout(() => {
    //       notify('reloadRightMenu', {...doc, type: 'Visit/User- D0+'});
    //     }, 100);
    //   },
    // },

    {
      title: 'Events/Visit',
      count: eventsPerUsers_Upto_now,
      onPress: index => {
        setIndex(index);
        setState(true);
        setStateData({
          ...doc,
          type: 'Events/Visit',
          title: 'Events/Visit',
        });
        setTimeout(() => {
          notify('reloadRightMenu', {...doc, type: 'Events/Visit'});
        }, 100);
        setNotificationUser(false);
      },
    },

    // {
    //   title: 'Events/Visit- D0',
    //   count: eventsPerUsersDZero,
    //   onPress: index => {
    //     setIndex(index);
    //     setState(true);
    //     setStateData({
    //       ...doc,
    //       type: 'Events/Visit- D0',
    //       title: 'Events/Visit- D0',
    //     });
    //     setTimeout(() => {
    //       notify('reloadRightMenu', {...doc, type: 'Events/Visit- D0'});
    //     }, 100);
    //   },
    // },
    // {
    //   title: 'Events/Visit- D0+',
    //   count: eventsPerUsers,
    //   onPress: index => {
    //     setIndex(index);
    //     setState(true);
    //     setStateData({
    //       ...doc,
    //       type: 'Events/Visit- D0+',
    //       title: 'Events/Visit- D0+',
    //     });
    //     setTimeout(() => {
    //       notify('reloadRightMenu', {...doc, type: 'Events/Visit- D0+'});
    //     }, 100);
    //   },
    // },
  ];
  const Card = ({title, count, onPress, index}) => {
    let style =
      index === selectedIndex && docIndex === _index
        ? selectStyle
        : containerStyle;
    return (
      <TouchableWithoutFeedback
        onPress={() => {
          onPress(index);
          setDocIndex(_index);
        }}>
        <View style={style}>
          <Text
            style={{
              ...titleTextStyle,
              flex: 1,
            }}>
            {title}
          </Text>

          <Text style={{...titleValueStyle}}>{` ${count || 0}`}</Text>
          {index === selectedIndex && docIndex === _index ? (
            <View style={iconStyle} />
          ) : (
            <Image source={RightArrowIcon} style={iconStyle} />
          )}
        </View>
      </TouchableWithoutFeedback>
    );
  };

  return (
    <TouchableOpacity style={{marginLeft: 12, marginRight: 12}}>
      <View style={dateStyle}>
        {doc.span === 'day' && doc.startDate ? (
          <Text style={{...headerTextStyle}}>
            {getTime(new Date(doc.startDate))}
          </Text>
        ) : (
          <Text style={{...headerTextStyle}}>
            {`${getTime(new Date(doc.startDate))} - ${getTime(
              new Date(doc.endDate),
            )}`}
          </Text>
        )}
      </View>

      {showVersionCard ? (
        <ReleaseVersionChip
          item={arrData}
          appOpenCount={doc.appOpenEventsuniqueCount}
          setShowVersionCard={setShowVersionCard}
          showVersionCard={showVersionCard}
          versionCard={versionCard}
          setVersionCard={setVersionCard}
          data={doc}
        />
      ) : null}

      <View
        style={{
          flex: 1,
          flexDirection: 'column',
          marginBottom: 8,
        }}>
        {arr.map(({title, count, onPress}, index) => (
          <Card
            title={title}
            count={count}
            onPress={onPress}
            index={index}
            dateStyle={dateStyle}
          />
        ))}
      </View>
    </TouchableOpacity>
  );
};

const NewAnalytics = () => {
  const theme = useComponentTheme('NewAnalytics');
  const [state, setState] = useState(false);
  const [stateData, setStateData] = useState({});
  const [docIndex, setDocIndex] = useState(-1);
  const [versionCard, setVersionCard] = useState('');
  const [showVersionCard, setShowVersionCard] = useState(true);
  const [notificationUser, setNotificationUser] = useState(false);

  let {listStyle, mainConatiner, textStyleConatiner, dateStyle} = theme;

  let {
    data = [],
    loading,
    loaded,
    loadMore,
    loadingMore,
    params,
    setParams,
  } = useDataFetch({
    api: newAllAnalytics,
    reloadEvents: ['reloadNewAnalytics'],
    // ...useLoadMore(),
  });
  if (data && !data.length) {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <Text>loading...</Text>
      </View>
    );
  }

  let defaultValue = params && params.span ? params.span : 'day';
  let newOptions = [{name: 'Day'}, {name: 'Week'}, {name: 'Month'}];
  return (
    <View>
      <NewHeader />
      <View style={Platform.OS === 'web' ? {backgroundColor: '#F7F7F7'} : null}>
        <TimeFrame
          timeSpan={newOptions}
          setParams={setParams}
          setDocIndex={setDocIndex}
          docIndex={docIndex}
          setState={setState}
          state={state}
        />
        <View
          style={{
            ...mainConatiner,
          }}>
          <View style={Platform.OS === 'web' ? listStyle : null}>
            {Platform.OS === 'web' ? (
              <Text style={textStyleConatiner}>Home</Text>
            ) : null}
            {versionCard ? (
              <ReleaseVersionChip
                versionCard={versionCard}
                setVersionCard={setVersionCard}
                appOpenCount={data.appOpenEventsuniqueCount}
                setShowVersionCard={setShowVersionCard}
                showSelected={true}
                data={data}
              />
            ) : null}
            <CenterList
              key="retention"
              // data={[data[0], data[1]]}
              data={data}
              loading={loading}
              loaded={loaded}
              loadMore={loadMore}
              loadingMore={loadingMore}
              renderItem={({item, index}) => {
                return (
                  <Item
                    doc={item}
                    setState={setState}
                    state={state}
                    setStateData={setStateData}
                    stateData={stateData}
                    index={index}
                    setDocIndex={setDocIndex}
                    docIndex={docIndex}
                    showVersionCard={showVersionCard}
                    setShowVersionCard={setShowVersionCard}
                    versionCard={versionCard}
                    setVersionCard={setVersionCard}
                    dateStyle={dateStyle}
                    setNotificationUser={setNotificationUser}
                  />
                );
              }}
            />
          </View>
          {/* {Platform.OS == 'web' ? ( */}
          <RightSideView
            state={state}
            setState={setState}
            setStateData={setStateData}
            stateData={stateData}
            versionCard={versionCard}
            setVersionCard={setVersionCard}
            setShowVersionCard={setShowVersionCard}
            notificationUser={notificationUser}
          />

          {/* ) : null} */}
        </View>
      </View>
    </View>
  );
};

export default NewAnalytics;
