import React from 'react';
import {Image, View, Text, Platform} from '@unthinkable/react-core-components';

const resolveAvatarValue = value => {
  if (!value || typeof value !== 'string') {
    return '';
  }
  value = value.split(' ');
  let avatarValue = '';
  if (value.length) {
    for (let key of value) {
      avatarValue += key.substring(0, 1);
      if (avatarValue.length === 2) {
        break;
      }
    }
  }
  return avatarValue.toUpperCase();
};

const Avatar = props => {
  let {
    title,
    icon,
    resolveIcon,
    value,
    defaultIcon,
    defaultText,
    size,
    variant = 'circular',
    iconStyle,
    textStyle,
    children,
    isActive,
    indicatorStyle,
    backgroundColor,
    borderColor,
    textColor,
    borderWidth,
    resizeMode = 'cover',
  } = props;

  if (icon) {
    if (typeof icon === 'function') {
      icon = icon(props);
    }
    if (icon && resolveIcon) {
      icon = resolveIcon(icon);
    }
  }
  if (typeof value === 'function') {
    value = value(props);
  }
  if (icon === undefined && value === undefined) {
    icon = defaultIcon;
    value = defaultText;
  }
  if (icon === undefined && value === undefined) {
    return null;
  }
  if (typeof title === 'function') {
    title = title(props);
  } else if (title === undefined) {
    title = value;
  }
  if (typeof title !== 'string') {
    try {
      title = JSON.stringify(title);
    } catch (err) {
      title = void 0;
    }
  }
  let style =
    (size && typeof size === 'string' && props[`${size}Style`]) ||
    props['style'];
  style = {
    ...style,
    alignItems: 'center',
    justifyContent: 'center',
  };
  if (size && typeof size === 'number') {
    style.width = size;
    style.height = size;
    style.borderRadius = size / 2;
  }
  if (variant === 'square') {
    delete style.borderRadius;
  }
  if (backgroundColor) {
    style.backgroundColor = backgroundColor;
  }
  if (borderColor) {
    style.borderColor = borderColor;
  }
  if (borderWidth) {
    style.borderWidth = borderWidth;
  }

  if (textColor) {
    textStyle = {...textStyle, color: textColor};
  }
  const source = typeof icon === 'string' ? {uri: icon} : icon;
  if (Platform.OS !== 'web' && source?.uri) {
    iconStyle = {width: '100%', height: '100%', ...iconStyle};
    resizeMode = 'contain';
    style = {overflow: 'hidden', ...style};
  }

  let avatarComponent = (
    <View style={style}>
      {children ||
        (icon ? (
          <Image
            title={title}
            style={{
              maxHeight: style.height,
              maxWidth: style.width,
              borderRadius: style.borderRadius,
              overflow: 'hidden',
              ...iconStyle,
            }}
            alt={false}
            source={source}
            resizeMode={resizeMode}
          />
        ) : (
          <Text title={title} style={textStyle}>
            {resolveAvatarValue(value)}
          </Text>
        ))}
      {isActive && indicatorStyle && <View style={{...props.indicatorStyle}} />}
    </View>
  );
  return avatarComponent;
};

export default Avatar;
