import {Text, TextInput, View} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';
import React, {useState} from 'react';
import {useAuthenticate} from '../controllers';

export const LoginForm = () => {
  let {onSubmit} = useAuthenticate({
    uri: '/user/authenticate',
  });

  const theme = useComponentTheme('LoginForm');
  const {
    containerStyle,
    headerContainerStyle,
    headerTextStyle,
    textInputContainerStyle,
    loginButtonStyle,
    loginTextStyle,
  } = theme;

  const [data, updateData] = useState({
    email: '',
    password: '',
    rememberme: false,
  });

  return (
    <View style={containerStyle}>
      <View style={headerContainerStyle}>
        <Text style={headerTextStyle}>Log in to LOCOMO</Text>
      </View>
      <View style={textInputContainerStyle}>
        <TextInput
          placeholder="Sign in with Email"
          value={data['email']}
          onChange={e => {
            updateData({...data, email: e.target.value});
          }}
        />
      </View>
      <View style={textInputContainerStyle}>
        <TextInput
          type="password"
          placeholder="password"
          value={data['password']}
          onSubmitEditing={() => {
            onSubmit(data);
          }}
          onChange={e => {
            updateData({...data, password: e.target.value});
          }}
        />
      </View>
      <View
        style={loginButtonStyle}
        onPress={() => {
          onSubmit(data);
        }}>
        <Text style={loginTextStyle}>Login</Text>
      </View>
    </View>
  );
};
