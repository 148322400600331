import React from 'react';
import {Route, Router} from '@unthinkable/react-router';

import Home from './Home';
import Login from './Login';
import Page from '../layouts/page/Page';
import Stack from '../unthinkable-components/Stack';
import RightSide from '../components/RightSideView/RightSideView';

export default props => {
  return (
    <Router root layout={<Stack />}>
      {Home}
      {Login}
      {/* <Route
        path="/new-install"
        elements={{
          view: <RightSide />,
        }}
      /> */}
    </Router>
  );
};
