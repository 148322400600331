export const ReusableButtons = ({theme: {colors, fonts, icons}}) => {
  return {
    sm: {},
    md: {
      mainContainer: {
        flex: 1,
        flexDirection: 'row',
        marginTop: 20,
        marginBottom: 36,
        cursor: 'pointer',
      },
      saveContainerStyle: {
        borderWidth: 1,
        borderColor: colors.NEUTRAL_HIGH,
        borderRadius: 8,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 10,
        paddingBottom: 10,
        color: colors.NEUTRAL_HIGH,
        marginLeft: 16,
      },
      shareContainerStyle: {
        borderWidth: 1,
        borderColor: colors.NEUTRAL_HIGH,
        backgroundColor: colors.BACKGROUND,
        alignSelf: 'start',
        borderRadius: 8,
      },
      runReportStyle: {
        borderRadius: 8,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 10,
        paddingBottom: 10,
        color: colors.BRAND_LOW,
        backgroundColor: colors.BRAND_UPPER_MEDIUM,
        marginLeft: 16,
        flex: 1,
        alignItems: 'center',
      },
    },
    ShareIcon: icons.ShareIcon,
    iconStyle: {
      height: 16,
      width: 16,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 10,
      paddingBottom: 10,
    },
  };
};
