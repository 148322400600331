export const MenuBar = ({theme: {fonts, colors}}) => {
  return {
    containerStyle: {
      backgroundColor: colors.SURFACE2,
    },
    itemStyle: {
      containerStyle: {
        cursor: 'pointer',
        padding: 10,
      },
      activeContainerStyle: {},
      iconStyle: {
        width: 25,
        height: 25,
      },
      labelStyle: {
        ...fonts.BODY3,
        color: colors.NEUTRAL_HIGH,
      },
      activeLabelStyle: {
        ...fonts.BODY3,
        color: colors.ACCENT1_HIGH,
      },
    },
    sm: {
      position: 'bottom',
    },
    md: {
      position: 'left',
    },
  };
};

export const TopMenuBar = ({theme: {colors, fonts}}) => {
  return {
    containerStyle: {
      borderBottomWidth: 1,
      borderBottomColor: colors.OUTLINE,
    },
    itemStyle: {
      containerStyle: {
        cursor: 'pointer',
        marginLeft: 16,
        marginRight: 16,
        paddingTop: 16,
        paddingBottom: 16,
      },
      activeBorderStyle: {
        height: 4,
        backgroundColor: colors.PRIMARY_HIGH,
        borderRadius: 9,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
      },
      iconStyle: {
        width: 25,
        height: 25,
      },
      labelStyle: {
        color: colors.NEUTRAL_HIGH,
        ...fonts.BODY3,
      },
      activeLabelStyle: {
        ...fonts.BODY1,
      },
    },
    position: 'top',
  };
};

export const ModalTopMenuBar = ({theme: {colors, fonts}}) => {
  return {
    containerStyle: {
      backgroundColor: colors.BACKGROUND,
      borderBottomWidth: 1,
      borderBottomColor: colors.OUTLINE,
    },
    itemStyle: {
      containerStyle: {
        cursor: 'pointer',
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
        paddingBottom: 8,
      },
      activeBorderStyle: {
        height: 4,
        backgroundColor: colors.PRIMARY_HIGH,
        borderRadius: 9,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
      },
      iconStyle: {
        width: 25,
        height: 25,
      },
      labelStyle: {
        color: colors.NEUTRAL_HIGH,
        ...fonts.BODY3,
      },
      activeLabelStyle: {
        ...fonts.BODY1,
      },
    },
    position: 'top',
  };
};

export const LeftMenuBar = ({theme: {colors, fonts}}) => {
  return {
    containerStyle: {
      width: 240,
      backgroundColor: colors.SURFACE1,
      paddingTop: 32,
      paddingLeft: 18,
      paddingRight: 18,
    },
    itemStyle: {
      containerStyle: {
        cursor: 'pointer',
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
        paddingBottom: 8,
        borderWidth: 1,
        borderColor: 'transparent',
        borderRadius: 7,
      },
      activeContainerStyle: {
        borderWidth: 1,
        borderColor: '#F7C0C7',
      },
      iconContainerStyle: {
        width: 28,
        height: 28,
        backgroundColor: '#F7C0C71C',
        borderRadius: 4,
      },
      iconStyle: {},
      labelStyle: {
        paddingLeft: 11,
        color: colors.NEUTRAL_MEDIUM,
        ...fonts.BODY1,
      },
      activeLabelStyle: {
        ...fonts.BODY1,
        color: colors.NEUTRAL_HIGH,
      },
    },
    position: 'left',
  };
};

export const LeftSectionsMenuBar = ({theme: {colors, fonts, icons}}) => {
  return {
    containerStyle: {
      width: 216,
      backgroundColor: colors.SURFACE1,
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      borderRadius: 8,
      padding: 8,
    },
    separatorStyle: {
      height: 1,
      backgroundColor: colors.OUTLINE,
    },
    sectionStyle: {
      expandIcon: icons.ExpandSmall,
      collapseIcon: icons.CollapseSmall,
      labelContainerStyle: {
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 10,
        paddingBottom: 10,
        alignItems: 'center',
      },
      labelStyle: {
        ...fonts.CAPTION,
        color: colors.NEUTRAL_LOW,
      },
      itemsContainerStyle: {
        marginBottom: 8,
      },
      expandIconContainerStyle: {
        marginLeft: 12,
      },
      expandIconStyle: {
        height: 12,
        width: 12,
      },
      itemStyle: {
        showActiveIndicator: true,
        activeIndicatorContainerStyle: {
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
        },
        activeIndicatorStyle: {
          flex: 1,
          marginTop: 8,
          marginBottom: 8,
          width: 3,
          backgroundColor: colors.PRIMARY_HIGH,
          borderRadius: 9,
        },
        containerStyle: {
          cursor: 'pointer',
          paddingLeft: 24,
          paddingRight: 12,
          paddingTop: 10,
          paddingBottom: 10,
          borderRadius: 6,
        },
        activeContainerStyle: {
          backgroundColor: colors.PRIMARY_LOW,
        },
        labelStyle: {
          ...fonts.CAPTION_LARGE,
          color: colors.NEUTRAL_MEDIUM,
        },
        activeLabelStyle: {
          ...fonts.CAPTION_LARGE,
          color: colors.PRIMARY_HIGH,
        },
      },
    },
  };
};

export const LeftSideMenuBar = ({theme: {colors, fonts, icons}}) => {
  return {
    containerStyle: {
      flexDirection: 'row',
      borderRightWidth: 1,
      borderColor: colors.OUTLINE,
    },
    separatorStyle: {
      width: 1,
      backgroundColor: colors.OUTLINE,
    },
    leftSectionStyle: {
      containerStyle: {
        width: 200,
      },
      collapsedContainerStyle: {
        width: void 0,
      },
      separatorStyle: {
        height: 1,
        backgroundColor: colors.OUTLINE,
      },
      menusMaxHeight: '40%',
      menusStyle: {
        containerStyle: {
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16,
        },
        itemStyle: {
          gap: 8,
          showActiveIndicator: true,
          activeIndicatorContainerStyle: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
          },
          iconStyle: {
            height: 20,
            width: 20,
          },
          activeIndicatorStyle: {
            flex: 1,
            marginTop: 8,
            marginBottom: 8,
            width: 3,
            backgroundColor: colors.PRIMARY_HIGH,
            borderRadius: 9,
          },
          containerStyle: {
            cursor: 'pointer',
            paddingLeft: 12,
            paddingRight: 12,
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 6,
          },
          activeContainerStyle: {
            backgroundColor: colors.PRIMARY_LOW,
          },
          labelStyle: {
            numberOfLines: 2,
            ...fonts.SIDE_NAVIGATION,
            color: colors.NEUTRAL_MEDIUM,
          },
          activeLabelStyle: {
            color: colors.PRIMARY_HIGH,
          },
        },
      },
    },
    rightSectionStyle: {
      containerStyle: {
        width: 185,
      },
      menusStyle: {
        containerStyle: {
          padding: 8,
        },
        itemStyle: {
          gap: 8,
          showActiveIndicator: true,
          activeIndicatorContainerStyle: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
          },
          activeIndicatorStyle: {
            flex: 1,
            marginTop: 8,
            marginBottom: 8,
            width: 3,
            backgroundColor: colors.PRIMARY_HIGH,
            borderRadius: 9,
          },
          containerStyle: {
            cursor: 'pointer',
            paddingLeft: 12,
            paddingRight: 12,
            paddingTop: 8,
            paddingBottom: 8,
            borderRadius: 6,
          },
          activeContainerStyle: {
            backgroundColor: colors.PRIMARY_LOW,
          },
          labelStyle: {
            numberOfLines: 2,
            ...fonts.SIDE_NAVIGATION,
            color: colors.NEUTRAL_MEDIUM,
          },
          activeLabelStyle: {
            color: colors.PRIMARY_HIGH,
          },
        },
      },
    },
  };
};

export const BottomMenuBar = ({theme: {colors, fonts}}) => {
  return {
    containerStyle: {
      backgroundColor: colors.BACKGROUND,
      paddingLeft: 60,
      paddingRight: 60,
      paddingTop: 16,
      paddingBottom: 16,
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
    },
    itemStyle: {
      containerStyle: {
        cursor: 'pointer',
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 8,
        paddingBottom: 8,
        borderRadius: 12,
      },
      activeContainerStyle: {
        backgroundColor: colors.ACCENT1_LOW,
      },
      iconContainerStyle: {
        height: 24,
        width: 24,
      },
      labelStyle: {
        paddingLeft: 12,
        color: colors.NEUTRAL_MEDIUM,
        ...fonts.HEADING1,
      },
      activeLabelStyle: {
        color: colors.PRIMARY_HIGH,
      },
    },
    position: 'bottom',
  };
};

export const AbsoluteMenuBar = ({theme: {colors, fonts}}) => {
  return {
    absoluteStyle: {
      position: 'absolute',
      bottom: 0,
      left: '50%',
      right: '50%',
      alignItems: 'center',
      marginBottom: 20,
    },
    containerStyle: {
      backgroundColor: colors.BACKGROUND,
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
      borderRadius: 12,
      alignItems: 'center',
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      flexDirection: 'row',
    },
    itemStyle: {
      direction: 'column',
      gap: 4,
      wrapperStyle: {
        width: 80,
        paddingLeft: 10,
        paddingRight: 10,
        alignItems: 'center',
      },
      containerStyle: {
        cursor: 'pointer',
        paddingTop: 8,
        paddingBottom: 8,
        alignItems: 'center',
      },
      iconContainerStyle: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 24,
        width: 24,
      },
      labelStyle: {
        color: colors.NEUTRAL_MEDIUM,
        ...fonts.CAPTION_LARGE,
      },
      activeLabelStyle: {
        color: colors.PRIMARY_HIGH,
      },
      showActiveIndicator: true,
      activeIndicatorContainerStyle: {
        position: 'absolute',
        left: 0,
        bottom: 3,
        right: 0,
      },
      activeIndicatorStyle: {
        marginLeft: 5,
        marginRight: 5,
        height: 3,
        backgroundColor: colors.PRIMARY_HIGH,
        borderRadius: 9,
      },
    },
    position: 'absolute',
  };
};

export const LeftMenuHeader = ({theme: {icons}}) => {
  return {
    containerStyle: {
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 16,
      height: 54,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    logoContainerStyle: {flex: 1, overflow: 'hidden', alignItems: 'flex-start'},
    logo: icons.LOCOMO,
    expandIcon: icons.LeftMenuExpand,
    collpaseIcon: icons.LeftMenuCollapse,
  };
};
