import {isMobile} from '../components/table/Utility';

export const MoreAction = ({theme: {fonts, icons, colors}}) => {
  return {
    icon: icons.ThreeDotVertical,
    modalContainerStyle: isMobile
      ? {
          backgroundColor: colors.SURFACE2,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 24,
          paddingBottom: 8,
          maxHeight: 300,
          overflow: 'hidden',
        }
      : {
          backgroundColor: colors.BACKGROUND,
          borderWidth: 1,
          borderColor: colors.OUTLINE,
          borderRadius: 6,
          maxHeight: 300,
          overflow: 'hidden',
          width: 200,
          boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.06)',
        },
    actionContainerStyle: isMobile
      ? {
          padding: 12,
          marginBottom: 4,
          borderRadius: 6,
          backgroundColor: colors.BACKGROUND,
        }
      : {
          padding: 12,
          borderBottomWidth: 1,
          borderBottomColor: colors.OUTLINE,
        },

    imageContainerStyle: {
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'center',
    },
    titleStyle: {
      ...fonts.PAGE_SUBHEADING_SMALL,
      color: colors.NEUTRAL_HIGH,
    },
  };
};
