import {Dimensions} from '@unthinkable/react-core-components';

export const AutoComplete = ({theme: {colors, fonts, icons}}) => {
  return {
    showArrow: true,
    disabled: true,
    DownArrowIcon: icons.DownArrowIcon,
    chipGap: 8,
    chipStyle: {
      containerStyle: {
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: colors.SECONDARY_MEDIUM,
        borderRadius: 100,
      },
      textStyle: {
        numberOfLines: 1,
        ...fonts.CAPTION_SMALL,
        color: colors.NEUTRAL_HIGH,
      },
      crossIcon: icons.ChipCross,
      crossContainerStyle: {
        cursor: 'pointer',
        paddingLeft: 4,
      },
    },
    containerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      overflow: 'hidden',
      borderRadius: 6,
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      backgroundColor: colors.SURFACE,
    },
    inputContainerStyle: {
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 10,
      paddingBottom: 6,
    },
    crossIcon: icons.ChipCross,
    crossContainerStyle: {
      cursor: 'pointer',
      height: 16,
      width: 16,
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 12,
      paddingLeft: 6,
      marginLeft: -6,
    },
    inputStyle: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_MEDIUM,
    },
    selectorStyle: {
      containerStyle: {
        cursor: 'pointer',
        height: 16,
        width: 16,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 12,
        paddingLeft: 6,
        marginLeft: -6,
      },
      iconStyle: {height: 15, width: 15},
    },
    separatorStyle: {
      height: 1,
      backgroundColor: colors.OUTLINE,
    },
    dropdownStyle: {
      position: 'absolute',
      height: 33 * 5,
      alignSelf: 'center',
    },
    dropdownListStyle: {
      flex: 1,
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      borderRadius: 10,
      backgroundColor: colors.BACKGROUND,
    },
    dropdownHeaderStyle: {
      containerStyle: {
        backgroundColor: colors.BACKGROUND,
        borderBottomWidth: 1,
        borderBottomColor: colors.OUTLINE,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 4,
        paddingBottom: 4,
      },
      titleStyle: {
        ...fonts.CAPTION,
        color: colors.NEUTRAL_MEDIUM,
      },
    },
    dropdownItemStyle: {
      containerStyle: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 12,
        paddingRight: 12,
        backgroundColor: colors.BACKGROUND,
        borderBottomWidth: 1,
        borderBottomColor: colors.OUTLINE,
        cursor: 'pointer',
      },
      textStyle: {
        ...fonts.BODY3,
        color: colors.NEUTRAL_HIGH,
        textAlignVertical: 'center',
      },

      secondaryTextStyle: {
        ...fonts.CAPTION_SMALL,
        color: colors.NEUTRAL_MEDIUM,
        textAlignVertical: 'center',
      },
      highlightedTextStyle: {
        color: 'black',
      },
    },
    createNewStyle: {
      createNewIcon: icons.Plus_Green,
      containerStyle: {
        height: 24,
        width: 24,
        backgroundColor: colors.BRAND_LOW,
        borderRadius: 24,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 12,
      },
      iconStyle: {height: 12, width: 12},
    },
    createNewItemStyle: {
      containerStyle: {
        flexDirection: 'row',
        backgroundColor: colors.BACKGROUND,
        borderBottomWidth: 1,
        borderBottomColor: colors.OUTLINE,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 12,
        paddingBottom: 12,
      },
      labelStyle: {
        ...fonts.SECTION_HEADING2,
        color: colors.NEUTRAL_MEDIUM,
        paddingRight: 8,
      },
      textStyle: {
        ...fonts.CAPTION,
        color: colors.NEUTRAL_MEDIUM,
      },
    },
    bottomModalStyle: {
      containerStyle: {
        height: Dimensions.get('window').height * 0.4,
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
        backgroundColor: colors.SURFACE2,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      },
      headerStyle: {
        containerStyle: {
          paddingTop: 12,
          paddingRight: 16,
          paddingLeft: 16,
        },
        titleStyle: {
          ...fonts.TITLE,
          color: colors.NEUTRAL_HIGH,
        },
      },
      bodyContainerStyle: {
        padding: 16,
      },
      inputContainerStyle: {
        borderRadius: 6,
        backgroundColor: colors.BACKGROUND,
        borderWidth: 1,
        borderColor: colors.OUTLINE,
        marginBottom: 12,
      },
      inputStyle: {
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 14,
        paddingBottom: 14,
      },
    },
  };
};

export const LabelledAutoComplete = ({theme: {colors}}) => {
  return {
    containerStyle: {
      flexDirection: 'row',
      overflow: 'hidden',
      alignItems: 'center',
      borderRadius: 6,
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      backgroundColor: colors.BACKGROUND,
    },
    inputContainerStyle: {
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 22,
      paddingBottom: 6,
    },
  };
};

export const AutoCompleteCell = ({theme: {colors}}) => {
  return {
    containerStyle: {
      flexDirection: 'row',
      overflow: 'hidden',
      alignItems: 'center',
      borderRadius: 6,
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      backgroundColor: colors.SURFACE1,
    },
    inputContainerStyle: {
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 14,
      paddingBottom: 14,
    },
  };
};

export const LabelledAutoCompleteCell = ({theme: {colors}}) => {
  return {
    containerStyle: {
      flexDirection: 'row',
      overflow: 'hidden',
      alignItems: 'center',
      borderRadius: 6,
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      backgroundColor: colors.SURFACE1,
    },
    inputContainerStyle: {
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 22,
      paddingBottom: 6,
    },
  };
};
