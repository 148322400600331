export const TimeFrame = ({theme: {colors, fonts}}) => {
  return {
    sm: {
      wrapperStyle: {
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 16,
        marginRight: 16,
        flexDirection: 'row',
        backgroundColor: colors.OUTLINE,
        borderRadius: 8,
      },
      containerStyle: {
        borderWidth: 1,
        borderColor: colors.OUTLINE,
        borderRadius: 8,
        flexDirection: 'row',
        flex: 1,
      },
      timeFrame: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        color: colors.NEUTRAL_MEDIUM,
        cursor: 'pointer',
        paddingLeft: 10,
        paddingBottom: 6,
        paddingRight: 10,
        paddingTop: 6,
        borderRadius: 8,
      },
      selectedTimeFrame: {
        backgroundColor: colors.BACKGROUND,
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
      },
      textStyle: {
        ...fonts.PAGE_SUBHEADING_SMALL,
        textAlign: 'center',
        color: colors.NEUTRAL_MEDIUM,
        height: 24,
        width: 90,
      },
    },
    md: {
      wrapperStyle: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 8,
        backgroundColor: colors.BACKGROUND,
        marginLeft: 136,
        marginRight: 136,
        padding: 8,
        borderRadius: 4,
      },
      containerStyle: {
        borderWidth: 1,
        borderColor: colors.OUTLINE,
        backgroundColor: colors.OUTLINE,
        borderRadius: 8,
        flexDirection: 'row',
        flex: 1,
      },
      timeFrame: {
        flex: 1,
        alignItems: 'center',
        fontColor: colors.NEUTRAL_MEDIUM,
        cursor: 'pointer',
        paddingLeft: 10,
        paddingBottom: 4,
        paddingRight: 10,
        paddingTop: 8,
        borderRadius: 8,
        alignItems: 'center',
      },
      selectedTimeFrame: {
        backgroundColor: colors.BACKGROUND,
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
      },
      textStyle: {
        ...fonts.PAGE_SUBHEADING_SMALL,
        color: colors.NEUTRAL_MEDIUM,
        height: 24,
        width: 144,
        textAlign: 'center',
      },
    },
  };
};
