export const Button = ({theme: {fonts, colors}}) => {
  return {
    gap: 8,
    containerStyle: {
      cursor: 'pointer',
      paddingTop: 9,
      paddingBottom: 9,
      paddingLeft: 20,
      paddingRight: 20,
      borderRadius: 6,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.NEUTRAL_HIGH,
    },
    textStyle: {
      ...fonts.ACTION,
      color: colors.BACKGROUND,
    },
    disabledContainerStyle: {
      backgroundColor: colors.SURFACE2,
      cursor: 'not-allowed',
    },
    disabledTextStyle: {
      color: colors.NEUTRAL_LOW,
    },
  };
};
