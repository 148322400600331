import React, {useRef, useState} from 'react';
import {
  Modal,
  Platform,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';

export const MobilePopper = ({
  children,
  backdropStyle,
  renderModal,
  position,
  onClickOutside: propOnClickOutside,
}) => {
  const _ref = useRef(null);
  const [visible, setVisible] = useState(false);
  const onClickOutside = () => {
    setVisible(false);
    propOnClickOutside && propOnClickOutside();
  };
  let frameStyle = {};
  let backDropAddOnStyle = {};
  if (position.includes('screen') || position.includes('Screen')) {
    if (position === 'fullScreen') {
      frameStyle.width = '100%';
      frameStyle.height = '100%';
    } else if (position === 'screenTop') {
      backDropAddOnStyle = {
        justifyContent: 'flex-start',
      };
      frameStyle.width = '100%';
    } else if (position === 'screenBottom') {
      backDropAddOnStyle = {
        justifyContent: 'flex-end',
      };
      frameStyle.width = '100%';
    } else if (position === 'screenLeft') {
      backDropAddOnStyle = {
        alignItems: 'flex-start',
      };
      frameStyle.height = '100%';
    } else if (position === 'screenRight') {
      backDropAddOnStyle = {
        alignItems: 'flex-end',
      };
      frameStyle.height = '100%';
    } else if (position === 'screenCenter') {
      backDropAddOnStyle = {
        justifyContent: 'center',
        alignItems: 'center',
      };
    }
  }
  return (
    <>
      <TouchableOpacity ref={_ref} onPress={() => setVisible(m => !m)}>
        {children}
      </TouchableOpacity>

      <Modal avoidKeyboard isVisible={visible} style={{margin: 0}}>
        <TouchableOpacity
          activeOpacity={1}
          style={{
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            position: Platform.OS === 'web' ? 'fixed' : 'absolute',
            zIndex: 1,
            backgroundColor: 'rgba(44, 46, 54, 0.73)',
            ...backdropStyle,
            ...backDropAddOnStyle,
          }}
          onPress={onClickOutside}>
          <View style={frameStyle}>{renderModal({hide: onClickOutside})}</View>
        </TouchableOpacity>
      </Modal>
    </>
  );
};
