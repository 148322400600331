import React from 'react';
import {Platform} from '@unthinkable/react-core-components';
import moment from 'moment';

const monthName = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export const getRenderComponent = (Component, props) => {
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, props);
  } else if (typeof Component === 'function') {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    } else {
      return Component(props);
    }
  }
  return Component;
};

const getIndianNumberFormat = (value, format, skipRoundOff = true) => {
  let zeroCount = 0;
  var space = format.indexOf(' i') !== -1 ? ' ' : ' ';
  let fragments = format.split('.');
  value = Number(value);

  if (fragments.length === 2) {
    zeroCount = (fragments[1].match(/0/g) || []).length;
  } else if (fragments.length > 2) {
    return 'Invalid Format';
  }

  const crore = 10000000;
  const lakh = 100000;
  const thousand = 1000;

  let abbr;
  let abs = Math.abs(value);

  if (abs >= crore) {
    value = value / crore;
    abbr = 'CR';
  } else if (abs < crore && abs >= lakh) {
    value = value / lakh;
    abbr = 'L';
  } else if (abs < lakh && abs >= thousand) {
    value = value / thousand;
    abbr = 'K';
  } else if (abs < thousand) {
    abbr = '';
  }
  value = value.toFixed(zeroCount);
  if (value % 1 === 0 && skipRoundOff) {
    value = parseInt(value);
  }
  return value + (abbr ? space + abbr : abbr);
};

export const numberFormatter = (value, format = '0,0.00', skipRoundOff) => {
  if (value === undefined || value === null || value === '' || !format) {
    return value;
  }
  if (format.toUpperCase() === 'IN' || format.toUpperCase() === 'US') {
    return value.toLocaleString(`en-${format}`);
  } else {
    return getIndianNumberFormat(value, format, skipRoundOff);
  }
};

export const resolveExp = (values, key) => {
  if (!values || !key) {
    return values;
  }
  if (typeof key === 'function') {
    return key(values);
  }
  if (Array.isArray(values)) {
    var result = [];
    for (var i = 0; i < values.length; i++) {
      var row = values[i];
      var resolvedValue = resolveExp(row, key);
      if (resolvedValue !== undefined) {
        if (Array.isArray(resolvedValue)) {
          for (var j = 0; j < resolvedValue.length; j++) {
            /* for save the duplicate values for field other then _id @sourabh -- 4-2-17*/
            if (key !== '_id' || result.indexOf(resolvedValue[j]) === -1) {
              result.push(resolvedValue[j]);
            }
          }
        } else {
          if (key !== '_id' || result.indexOf(resolvedValue) === -1) {
            result.push(resolvedValue);
          }
        }
      }
    }
    return result;
  } else {
    var value = values[key];
    if (value !== undefined) {
      return value;
    }
    var index = key.indexOf('.');
    if (index === -1) {
      return;
    }
    var firstPart = key.substring(0, index);
    var nextPart = key.substring(index + 1);
    return resolveExp(values[firstPart], nextPart);
  }
};

export const detectMob = () => {
  if (
    Platform.OS !== 'web' ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};

export const getTime = (time, dateFormat = 'DD MMM YY') => {
  let currentTime = new moment().startOf('day');
  let givenTime = moment(time).startOf('day');
  let getYear = new Date(moment(time).format('YYYY')).getFullYear();
  let currentYear = new Date().getFullYear();
  if (currentTime.diff(givenTime, 'days') === 0) {
    return 'Today';
  } else if (currentTime.diff(givenTime, 'days') === 1) {
    return 'Yesterday';
  } else {
    if (getYear === currentYear) {
      dateFormat = 'DD MMM';
    }
    return moment(time).format(dateFormat);
  }
};

export const getDateAndTime = timestamp => {
  let date = new Date(timestamp);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let nameOfMonth = monthName[month];
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? ' pm' : ' am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  let time = day + ' ' + nameOfMonth + ' | ' + hours + ':' + minutes + ampm;
  return time;
};

export const isMobile = detectMob();
