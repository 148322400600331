const typewiseStyle = {
  number: {
    width: 100,
  },
  date: {
    width: 100,
    format: 'DD MMM YY',
  },
  moreActions: {
    width: 40,
  },
};

const typewiseProps = {
  number: {
    align: 'right',
  },
  date: {
    format: 'DD MMM',
  },
};

export const Table = ({theme: {fonts, colors, icons}}) => {
  return {
    dragIcon: icons.DotsSixVertical,
    typewiseStyle,
    typewiseProps,
    emptyStyle: {
      noDataIcon: icons.NoData,
      containerStyle: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      },
      iconStyle: {},
    },
    headerStyle: {
      containerStyle: {
        backgroundColor: colors.SURFACE2,
        borderRadius: 4,
      },
      cellStyle: {
        containerStyle: {
          padding: 12,
        },
        labelStyle: {
          ...fonts.CAPTION_SMALL,
          color: colors.NEUTRAL_MEDIUM,
        },
      },
    },
    rowStyle: {
      containerStyle: {
        borderBottomWidth: 1,
        borderBottomColor: colors.OUTLINE,
      },
      cellStyle: {
        containerStyle: {
          justifyContent: 'center',
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 12,
          paddingBottom: 12,
        },
        hoverContainerStyle: {
          backgroundColor: colors.SURFACE1,
        },
        textStyle: {
          ...fonts.BODY3,
          color: colors.NEUTRAL_HIGH,
        },
        secondaryTextStyle: {
          ...fonts.BODY3,
          color: colors.NEUTRAL_LOW,
        },
      },
      hoverActionsStyle: {
        containerStyle: {
          position: 'absolute',
          top: 0,
          bottom: 1,
          right: 0,
          backgroundColor: colors.SURFACE1,
        },
      },
    },
  };
};

export const ColoredTable = ({theme: {fonts, colors, icons}}) => {
  return {
    typewiseStyle,
    typewiseProps,
    headerStyle: {
      containerStyle: {
        backgroundColor: colors.SURFACE2,
        borderRadius: 4,
      },
      cellStyle: {
        containerStyle: {
          padding: 12,
        },
        labelStyle: {
          ...fonts.CAPTION_SMALL,
          color: colors.NEUTRAL_MEDIUM,
        },
      },
    },
    rowStyle: {
      containerStyle: {
        backgroundColor: colors.SURFACE1,
        marginTop: 2,
        borderRadius: 6,
      },
      cellStyle: {
        containerStyle: {
          justifyContent: 'center',
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 8,
          paddingBottom: 8,
        },
        textStyle: {
          ...fonts.BODY3,
          color: colors.NEUTRAL_HIGH,
        },
        secondaryTextStyle: {
          ...fonts.BODY3,
          color: colors.NEUTRAL_LOW,
        },
      },
      hoverActionsStyle: {
        containerStyle: {
          position: 'absolute',
          top: 0,
          bottom: 1,
          right: 0,
          backgroundColor: colors.SURFACE1,
        },
      },
    },
  };
};

export const BorderedTable = ({theme: {fonts, colors}}) => {
  return {
    typewiseStyle,
    typewiseProps,
    bordered: true,
    borderStyle: {
      borderWidth: 1,
      borderColor: colors.OUTLINE,
    },
    headerStyle: {
      cellStyle: {
        containerStyle: {
          padding: 12,
        },
        labelStyle: {
          ...fonts.HEADING1,
          color: colors.NEUTRAL_HIGH,
        },
      },
    },
    rowStyle: {
      cellStyle: {
        containerStyle: {
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 12,
          paddingBottom: 12,
        },
        textStyle: {
          ...fonts.BODY3,
          color: colors.NEUTRAL_HIGH,
        },
        secondaryTextStyle: {
          ...fonts.BODY3,
          color: colors.NEUTRAL_LOW,
        },
      },
    },
  };
};

export const GroupRow = ({theme: {colors, fonts, icons, spacing}}) => ({
  containerStyle: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 12,
    paddingRight: 12,
    borderBottomWidth: 1,
    borderColor: colors.OUTLINE,
  },
  expandIcon: icons.CaretRight,
  collapseIcon: icons.CaretDown,
  iconStyle: {
    height: 20,
    width: 20,
    marginRight: 20,
  },
  titleStyle: {
    ...fonts.HEADING1,
    color: colors.NEUTRAL_HIGH,
  },
  secondaryStyle: {
    containerStyle: {
      marginTop: 10,
    },
    textStyle: {
      ...fonts.CAPTION,
      color: colors.NEUTRAL_MEDIUM,
    },
  },
  actionStyle: {
    paddingLeft: spacing.md,
  },
  aggregateStyle: {
    paddingLeft: spacing.md,
    ...fonts.HEADING1,
    color: colors.NEUTRAL_HIGH,
  },
  moreActionStyle: {
    icon: icons.ThreeDot,
  },
});

export const RecursiveTable = ({theme: {fonts, colors, icons}}) => ({
  leftGap: 10,
  indentSize: 20,
  typewiseStyle,
  typewiseProps,
  lineStyle: {
    color: colors.OUTLINE,
    borderWidth: 1,
    topGap: 10,
  },
  headerStyle: {
    containerStyle: {
      backgroundColor: colors.SURFACE2,
      borderRadius: 4,
      paddingLeft: 20,
    },
    cellStyle: {
      containerStyle: {
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 8,
        paddingBottom: 8,
      },
      labelStyle: {
        ...fonts.CAPTION_SMALL,
        color: colors.NEUTRAL_MEDIUM,
      },
    },
  },
  rowStyle: {
    containerStyle: {
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      borderRadius: 4,
      marginTop: 6,
      paddingTop: 8,
      paddingRight: 12,
      paddingBottom: 8,
      paddingLeft: 12,
    },
    cellStyle: {
      expandIcon: icons.CaretRight,
      collapseIcon: icons.CaretDown,
      iconContainerStyle: {
        width: 16,
        marginRight: 8,
        alignItems: 'center',
      },
      noChildStyle: {
        height: 6,
        width: 6,
        borderRadius: 6,
        backgroundColor: colors.NEUTRAL_MEDIUM,
      },
      iconStyle: {
        height: 16,
        width: 16,
      },
      containerStyle: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 4,
        paddingRight: 4,
        backgroundColor: colors.SURFACE1,
      },
      textStyle: {
        ...fonts.BODY3,
        color: colors.NEUTRAL_HIGH,
      },
      secondaryTextStyle: {
        ...fonts.BODY3,
        color: colors.NEUTRAL_LOW,
      },
      dotSeparatorStyle: {
        marginLeft: 8,
        marginRight: 8,
        width: 4,
        height: 4,
        backgroundColor: colors.NEUTRAL_LOW,
        borderRadius: 4,
      },
      tagStyle: {
        textStyle: {
          ...fonts.BODY3,
          color: colors.NEUTRAL_MEDIUM,
        },
      },
    },
  },
});
