import {View, Text} from '@unthinkable/react-core-components';
import React from 'react';
const Stack = ({Modal, elements = {}} = {}) => {
  let {view, modal} = elements;

  if (view && !Array.isArray(view)) {
    view = [view];
  }

  if (modal && !Array.isArray(modal)) {
    modal = [modal];
  }

  return (
    <View style={{flex: 1, overflow: 'hidden'}}>
      {view
        ? view.map(component => {
            return (
              <View
                style={{
                  // flex: 1,

                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  overflow: 'hidden',
                }}>
                {component}
              </View>
            );
          })
        : null}
      {modal
        ? modal.map(page => {
            return React.cloneElement(Modal, {
              children: (
                <View style={{flex: 1, overflow: 'hidden'}}>{page}</View>
              ),
            });
          })
        : null}
    </View>
  );
};

export default Stack;
