const {useMemo} = require('react');

export const useTypeColumns = ({
  columns,
  typewiseStyle,
  typewiseProps,
  CellRenders,
}) => {
  return useMemo(() => {
    if (!columns || !columns.length) {
      return columns;
    }

    return columns.map(column => {
      column = {
        ...column,
      };

      let {type} = column;

      if (typewiseStyle && type && typewiseStyle[type]) {
        for (let key in typewiseStyle[type]) {
          if (!column.hasOwnProperty(key)) {
            column[key] = typewiseStyle[type][key];
          }
        }
      }
      if (typewiseProps && type && typewiseProps[type]) {
        for (let key in typewiseProps[type]) {
          if (!column.hasOwnProperty(key)) {
            column[key] = typewiseProps[type][key];
          }
        }
      }
      const headerCellRender =
        CellRenders?.header?.[type] || CellRenders?.header?.['default'];
      const rowCellRender =
        CellRenders?.row?.[type] || CellRenders?.row?.['default'];

      if (headerCellRender && !column.renderHeader) {
        column.renderHeader = headerCellRender;
      }

      if (rowCellRender) {
        if (!column.render && rowCellRender.render) {
          column.render = rowCellRender.render;
        }
        if (!column.editor && rowCellRender.editor) {
          column.editor = rowCellRender.editor;
        }
      }
      return column;
    });
  }, [columns, typewiseStyle, typewiseProps, CellRenders]);
};
