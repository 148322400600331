export const DateInput = ({theme: {colors, fonts, icons}}) => {
  return {
    containerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      overflow: 'hidden',
      borderRadius: 8,
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      backgroundColor: colors.SURFACE,
    },
    inputStyle: {
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 14,
      paddingBottom: 14,
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
      padding: 12,
    },
    inputSelectorContainerStyle: {
      justifyContent: 'center',
      paddingLeft: 10,
      paddingRight: 10,
    },
    calendarStyle: {
      calendarIconStyle: {
        image: icons.Calendar,
        crossImage: icons.Close,
        imageStyle: {width: 21, height: 21},
      },
      timeCalendarIconStyle: {
        image: void 0,
        imageStyle: {width: 15, height: 15},
        viewStyle: {paddingLeft: 6, paddingRight: 6},
      },
      dropdownStyle: {
        marginTop: 8,
        marginBottom: 8,
        padding: 6,
        width: 300,
        height: 270,
        padding: 10,
        borderRadius: 4,
        backgroundColor: 'white',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
      },
      calendarStyle: {
        dataView: {
          header: {
            containerStyle: {
              flexDirection: 'row',
              alignItems: 'center',
            },
            prev: {
              prevIcon: void 0,
              imageStyle: {},
              viewStyle: {
                width: 20,
                height: 20,
                backgroundColor: colors.ACCENT1_LOW,
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 4,
              },
              textStyle: {
                color: colors.NEUTRAL_HIGH,
              },
            },
            next: {
              nextIcon: void 0,
              imageStyle: {},
              viewStyle: {
                width: 20,
                height: 20,
                backgroundColor: colors.ACCENT1_LOW,
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 4,
              },
              textStyle: {
                color: colors.NEUTRAL_HIGH,
              },
            },
            year: {
              viewStyle: {
                cursor: 'pointer',
                flex: 1,
                padding: 5,
                justifyContent: 'center',
                alignItems: 'center',
              },
              textStyle: {
                ...fonts.BODY1,
                color: colors.NEUTRAL_HIGH,
              },
            },
            month: {
              viewStyle: {
                flex: 1,
                cursor: 'pointer',
                padding: 5,
                justifyContent: 'center',
                alignItems: 'center',
              },
              textStyle: {
                ...fonts.BODY1,
                color: colors.NEUTRAL_HIGH,
              },
            },
          },
          body: {
            weekContainerStyle: {
              flexDirection: 'row',
              paddingTop: 5,
              alignItems: 'center',
            },
          },
        },
        monthView: {
          containerStyle: {
            paddingTop: 10,
            paddingBottom: 10,
            ...fonts.BODY1,
          },
          header: {
            containerStyle: {
              flexDirection: 'row',
              alignItems: 'center',
              // justifyContent: 'space-around',
            },
            prev: {
              prevIcon: void 0,
              imageStyle: {},
              viewStyle: {
                width: 20,
                height: 20,
                backgroundColor: colors.ACCENT1_LOW,
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 4,
              },
              textStyle: {
                color: colors.NEUTRAL_HIGH,
              },
            },
            next: {
              nextIcon: void 0,
              imageStyle: {},
              viewStyle: {
                width: 20,
                height: 20,
                backgroundColor: colors.ACCENT1_LOW,
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 4,
              },
              textStyle: {
                color: colors.NEUTRAL_HIGH,
              },
            },
            year: {
              viewStyle: {
                cursor: 'pointer',
                flex: 1,
                padding: 5,
                justifyContent: 'center',
                alignItems: 'center',
              },
              textStyle: {
                ...fonts.BODY1,
                color: colors.NEUTRAL_HIGH,
              },
            },
          },
          bodyStyle: {
            monthRowStyle: {
              flexDirection: 'row',
              justifyContent: 'space-around',
              cursor: 'pointer',
            },
            monthTextStyle: {
              padding: 7,
              ...fonts.BODY3,
              color: colors.NEUTRAL_HIGH,
            },
            monthViewStyle: {
              cursor: 'pointer',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            },
          },
        },
        yearView: {
          containerStyle: {
            paddingTop: 10,
            paddingBottom: 10,
            ...fonts.BODY1,
          },
          header: {
            containerStyle: {
              flexDirection: 'row',
              alignItems: 'center',
            },
            prev: {
              prevIcon: void 0,
              imageStyle: {},
              viewStyle: {
                width: 20,
                height: 20,
                backgroundColor: colors.ACCENT1_LOW,
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 4,
              },
              textStyle: {
                color: colors.NEUTRAL_HIGH,
              },
            },
            next: {
              nextIcon: void 0,
              imageStyle: {},
              viewStyle: {
                width: 20,
                height: 20,
                backgroundColor: colors.ACCENT1_LOW,
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 4,
              },
              textStyle: {
                color: colors.NEUTRAL_HIGH,
              },
            },
            year: {
              viewStyle: {
                cursor: 'pointer',
                flex: 1,
                padding: 5,
                justifyContent: 'center',
                alignItems: 'center',
              },
              textStyle: {
                ...fonts.BODY1,
                color: colors.NEUTRAL_HIGH,
              },
            },
          },
          bodyStyle: {
            // container: {justifyContent: 'space-around', alignItems: 'center'},
            yearRowStyle: {
              flexDirection: 'row',
              cursor: 'pointer',
              justifyContent: 'space-between',
            },
            yearTextStyle: {
              padding: 7,
              ...fonts.BODY3,
              color: colors.NEUTRAL_HIGH,
            },
            yearViewStyle: {
              cursor: 'pointer',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            },
          },
        },
        dateStyle: {
          viewStyle: {
            flex: 1,
            paddingTop: 5,
            paddingBottom: 5,
            // minWidth: 15,
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          },
          deActiveViewStyle: {},
          selectedViewStyle: {},
          textStyle: {
            paddingLeft: 5,
            paddingRight: 5,
            whiteSpace: 'pre-wrap',
            ...fonts.BODY3,
            color: colors.NEUTRAL_HIGH,
          },
          deActiveTextStyle: {
            color: colors.NEUTRAL_LOW,
          },
          selectedTextStyle: {
            color: colors.ACCENT1_HIGH,
            borderBottomStyle: 'solid',
            borderBottomColor: colors.ACCENT1_HIGH,
            borderBottomWidth: 2,
          },
        },
        weekComponentStyle: {
          viewStyle: {
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            padding: 5,
            cursor: 'pointer',
          },
          textStyle: {
            textAlign: 'center',
            color: colors.NEUTRAL_MEDIUM,
            ...fonts.BODY1,
          },
          primaryViewStyle: {},
          primaryTextStyle: {
            color: colors.ERROR_HIGH,
          },
        },
        timeViewStye: {
          seperatorStye: {
            width: '100%',
            height: 1,
            backgroundColor: '#97979766',
            marginBottom: 5,
          },
          timeContainerStyle: {flexDirection: 'row', alignItems: 'center'},
          itemStyle: {paddingLeft: 5, alignItems: 'center'},
          mainStyle: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 6,
          },
        },
      },
    },
  };
};

export const LabelledDateInput = ({theme: {colors, fonts}}) => {
  return {
    inputStyle: {
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 22,
      paddingBottom: 6,
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
  };
};
