import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {View, Text} from '@unthinkable/react-core-components';

const ToastContext = React.createContext();

const useToastState = () => {
  let [toast, setState] = useState({});
  let timerRef = useRef();

  const hideToast = useCallback(() => {
    let newState = {
      ...toast,
      message: void 0,
      type: void 0,
      time: void 0,
      visible: false,
    };
    setState(newState);
  }, [toast]);

  const show = useCallback(
    ({message, type = 'Success', time = 2000}) => {
      let newState = {...toast, message, type, time, visible: true};
      setState(newState);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(hideToast, time);
    },
    [toast, timerRef],
  );

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);
  return {toast, show};
};
export const ToastProvider = ({children}) => {
  let {toast, show} = useToastState();
  let value = useMemo(() => {
    return {toast, show};
  }, [toast, show]);
  return (
    <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
  );
};

const SuccessToast = ({theme, toast}) => {
  const {message} = toast;
  const {containerStyle, textContainerStyle, textStyle} = theme;

  return (
    <View style={containerStyle}>
      <View style={textContainerStyle}>
        <Text style={textStyle}>{message}</Text>
      </View>
    </View>
  );
};

export const useToast = () => {
  let {show} = useContext(ToastContext);
  return show;
};
export const ToastComponent = ({theme}) => {
  // toast = {
  //   visible: true,
  //   type: 'Error',
  //   message: 'posting...',
  // };

  let {toast} = useContext(ToastContext);
  const {visible, message, type = 'Success'} = toast;
  if (!visible || !message) {
    return null;
  }

  if (type === 'Success') {
    return <SuccessToast theme={theme.success} toast={toast} />;
  } else if (type === 'Error') {
    return <SuccessToast theme={theme.error} toast={toast} />;
  } else {
    return null;
  }
};
