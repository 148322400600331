import {useState, useCallback, useMemo} from 'react';
const {resolveExp} = require('../Utility');

export const useExpanded = ({
  rowKey = '_id',
  expandKey = 'expanded',
  toggleExpanded,
  defaultExpanded,
} = {}) => {
  const [expandedState, setExpandedState] = useState({});

  const defaultExpandedState = useMemo(() => {
    return {
      [expandKey]: true,
    };
  }, [expandKey]);

  const getKeyValue = useCallback(
    ({row}) => {
      return rowKey !== undefined ? resolveExp(row, rowKey) : void 0;
    },
    [rowKey],
  );

  const getRowExpandedState = useCallback(
    ({row}) => {
      const keyValue = getKeyValue({row});
      return (
        expandedState[keyValue] ||
        (defaultExpanded ? defaultExpandedState : void 0)
      );
    },
    [expandedState, defaultExpandedState, getKeyValue],
  );

  const setRowExpandedState = useCallback(
    ({row, rowExpandedState}) => {
      const keyValue = getKeyValue({row});
      let newState = {[keyValue]: rowExpandedState};
      if (!toggleExpanded) {
        newState = {
          ...expandedState,
          ...newState,
        };
      }
      setExpandedState(newState);
    },
    [expandedState, setExpandedState, getKeyValue],
  );

  const toggleRowExpanded = useCallback(
    ({row}) => {
      const rowPrevExpandedState = getRowExpandedState({row});
      let rowExpandedState = void 0;
      if (rowPrevExpandedState) {
        rowExpandedState = {
          ...rowPrevExpandedState,
          [expandKey]: !rowPrevExpandedState[expandKey],
        };
      } else {
        rowExpandedState = defaultExpandedState;
      }
      setRowExpandedState({row, rowExpandedState});
    },
    [defaultExpandedState, expandKey, getRowExpandedState, setRowExpandedState],
  );

  return {
    expandedState,
    getRowExpandedState,
    toggleRowExpanded,
  };
};
