export const Form = () => {
  return {
    fieldsLayoutStyle: {
      gap: 12,
      fieldContainerStyle: {
        marginTop: 6,
        marginBottom: 6,
      },
    },
    footerStyle: {
      containerStyle: {
        paddingTop: 20,
      },
    },
  };
};

export const FormGroup = ({theme: {fonts, colors}}) => {
  return {
    containerStyle: {
      marginTop: 8,
      marginBottom: 8,
      padding: 16,
      borderRadius: 8,
      borderWidth: 1,
      borderColor: colors.OUTLINE,
    },
    headerStyle: {
      iconContainerStyle: {
        height: 20,
        width: 20,
      },
      iconStyle: {},
      titleStyle: {
        paddingLeft: 12,
        ...fonts.HEADING2,
        color: colors.NEUTRAL_HIGH,
        flex: 1,
      },
      actionStyle: {
        padding: 4,
        cursor: 'pointer',
      },
      actionTextStyle: {
        color: colors.ACCENT1_HIGH,
        ...fonts.ACTION,
      },
    },
    fieldsLayoutStyle: {
      gap: 24,
      fieldContainerStyle: {
        marginTop: 12,
        marginBottom: 12,
        marginLeft: 32,
      },
    },
  };
};
