import {Text, TextInput, View} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';
import React, {useState} from 'react';

function Radio(props) {
  const {label, labelPosition = 'right', value} = props;
  const [selectedOption, setSelectedOption] = useState();

  const handleOptionChange = event => {
    setSelectedOption(event.target.value);
  };
  let theme = useComponentTheme('Radio');
  const {rightLabelStyle, leftLabelStyle, containerStyle} = theme;

  return (
    <View style={containerStyle}>
      {label && labelPosition === 'left' ? (
        <Text style={leftLabelStyle}>{label}</Text>
      ) : (
        void 0
      )}
      <TextInput
        type="radio"
        value={value}
        checked={selectedOption == value}
        onChange={handleOptionChange}
        {...props}
      />
      {label && labelPosition === 'right' ? (
        <Text style={rightLabelStyle}>{label}</Text>
      ) : (
        void 0
      )}
    </View>
  );
}

export default Radio;
