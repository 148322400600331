import React, {useCallback, useContext, useMemo, useState} from 'react';
import {matchPath} from 'react-router';

const RouterContext = React.createContext();
const RouteContext = React.createContext();
const LocationContext = React.createContext();

const popoulateRoute = ({
  match,
  route,
  elements,
  location,
  parent,

  params,
  parentLocation,
}) => {
  // console.log('@@@@ match::::', match);
  let {path, index, elements: childElements} = route.props;

  const {pathname, params: routeParams} = match;

  let newParams = {...params, ...location.params, ...routeParams};
  if (childElements) {
    for (let key in childElements) {
      let element = childElements[key];
      element = (
        <RouteContextProvider
          parent={parent}
          pathname={pathname}
          params={newParams}
          location={location}
          parentLocation={parentLocation}>
          {element}
        </RouteContextProvider>
      );

      if (elements[key]) {
        let value = elements[key];
        if (!Array.isArray(value)) {
          element = [value, element];
        } else {
          element = [...value, element];
        }
      }
      elements[key] = element;
    }
  }
  return newParams;
};
const iterateRoutes = ({
  elements,
  location,
  parent,
  children,
  params,
  parentLocation,
}) => {
  let indexRoute;
  let matchFound;
  let newParams;
  React.Children.map(children, child => {
    let {path, index} = child.props;
    if (index) {
      indexRoute = child;
    }
    let match;
    if (path) {
      let routePath = path;
      if (parent) {
        routePath = parent + '/' + path;
      }
      // console.log('@@@@ matching::::', routePath, location.pathname);

      match = matchPath({path: routePath, end: false}, location.pathname);
      if (match) {
        matchFound = true;
      }
    } else {
      match = {pathname: parent, params};
    }

    if (!match) {
      // console.log('@@@@ match not found');
      return;
    }

    newParams = popoulateRoute({
      route: child,
      match,
      elements,
      location,
      parent,
      children,
      params,
      parentLocation,
    });
  });

  if (!matchFound && indexRoute) {
    newParams = popoulateRoute({
      route: indexRoute,
      match: {pathname: parent, params},
      elements,
      location,
      parent,
      children,
      params,
      parentLocation,
    });
  }
  return newParams;
};

export const RouteContextProvider = ({
  pathname,
  parent,
  params,
  children,
  location,
  parentLocation,
}) => {
  let value = useMemo(() => {
    return {pathname, parent, params, location, parentLocation};
  }, [pathname, parent, params, location, parentLocation]);
  return (
    <RouteContext.Provider value={value}>{children}</RouteContext.Provider>
  );
};

const useLocationState = ({location: defaultLocation}) => {
  const [location, setState] = useState({location: defaultLocation});
  const setLocation = useCallback(
    ({location: newLocation}) => {
      let newState = {...location, location: newLocation};

      setState(newState);
    },
    [location, setState],
  );
  return {location, setLocation};
};

export const useLocationContext = () => {
  let value = useContext(LocationContext);
  return value;
};
export const LocationContextProvider = ({location: baseLocation, children}) => {
  if (typeof baseLocation === 'string') {
    baseLocation = [{pathname: baseLocation}];
  }
  let {location, setLocation} = useLocationState({location: baseLocation});

  let value = useMemo(() => {
    return {location, setLocation};
  }, [location, setLocation]);
  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  );
};

export const useRouteContext = () => {
  let value = useContext(RouteContext);
  return value;
};
export const useRouterContext = () => {
  let value = useContext(RouterContext);
  return value;
};
export const useParams = () => {
  let {params} = useContext(RouteContext);
  return params;
};

export const useReplace = () => {
  let {parent = '', location, parentLocation = []} = useRouteContext();
  let {setLocation} = useLocationContext();
  let replace = useCallback(
    (path, props) => {
      if (path.indexOf('/') !== 0) {
        path = '/' + path;
      }
      let newPath = parent + path;
      let currentLocation = {...location, pathname: newPath, ...props};
      let newLocation = [...parentLocation, currentLocation];

      setLocation({location: newLocation});
    },
    [parent],
  );
  return replace;
};
export const usePush = () => {
  let {parent, pathname, location, parentLocation = []} = useRouteContext();
  let {setLocation} = useLocationContext();
  let push = useCallback(
    (path, props) => {
      if (path.indexOf('/') !== 0) {
        path = '/' + path;
      }
      let newPath = pathname + path;

      let currentLocation = {...location, pathname: newPath, ...props};
      let newLocation = [...parentLocation, currentLocation];

      setLocation({location: newLocation});
    },
    [pathname, parent],
  );
  return push;
};
export const usePop = () => {
  let params = useParams();

  let {
    parent = '',
    pathname,
    location,
    parentLocation = [],
  } = useRouteContext();
  let {setLocation} = useLocationContext();
  let push = useCallback(
    props => {
      const {goBack} = params;

      let currentLocation;
      if (goBack) {
        currentLocation = {...location, ...goBack};
      } else {
        currentLocation = {...location, ...props};
      }

      let newLocation = [...parentLocation, currentLocation];
      setLocation({location: newLocation});
    },
    [parent, pathname],
  );
  return push;
};
export const useNavigate = () => {
  let {parentLocation, location} = useRouteContext();
  let {setLocation} = useLocationContext();

  let navigate = useCallback(
    (path, props) => {
      if (path === -1) {
        setLocation({location: [...parentLocation]});
      } else {
        if (path.indexOf('/') !== 0) {
          path = `/${path}`;
        }
        setLocation({
          location: [...parentLocation, location, {pathname: path, ...props}],
        });
      }
    },
    [parentLocation, location, setLocation],
  );
  return navigate;
};

const Router = ({children, root, layout, index}) => {
  //
  let {
    pathname,
    params,
    location: routeLocation,
    parentLocation: routeParentLocation,
  } = useRouteContext();
  let {
    location: {location: rootLocation},
  } = useLocationContext();

  let location;
  let parentLocation = [];
  if (root) {
    location = rootLocation;
  } else {
    location = routeLocation;
    parentLocation = routeParentLocation;
  }

  if (location && !Array.isArray(location)) {
    location = [location];
  }

  let elements = {};

  let newParams = {...params};

  location.forEach(path => {
    let {pathname: currentPathName} = path;
    if (currentPathName === pathname && index) {
      let indexToAdd = index;
      if (!index.startsWith('/')) {
        indexToAdd = `/${indexToAdd}`;
      }
      currentPathName = `${currentPathName}${indexToAdd}`;
      path = {...path, pathname: currentPathName};
    }
    let match = iterateRoutes({
      children,
      location: path,
      parentLocation,
      elements,
      parent: pathname,
      params: newParams,
    });
    if (match) {
      newParams = {...newParams, ...match};
    }
    parentLocation = [...parentLocation, path];
  });

  return React.cloneElement(layout, {elements});
};
export {Router};

export const Route = ({children}) => {
  return children;
};
