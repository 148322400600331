export const UserSegment = ({theme: {colors, fonts, icons}}) => {
  return {
    sm: {},
    md: {
      mainContainer: {
        backgroundColor: colors.SURFACE1,
        padding: 20,
        borderRadius: 8,
      },
      headerContainer: {
        flex: 1,
        flexDirection: 'row',
      },
      headerText: {
        ...fonts.PAGE_SUBHEADING_LARGE,
        color: colors.NEUTRAL_HIGH,
        flex: 1,
      },
      tittle: {
        marginTop: 24,
        ...fonts.PAGE_SUBHEADING_SMALL,
        color: colors.NEUTRAL_HIGH,
      },
      inputContainer: {
        borderWidth: 1,
        borderColor: colors.NEUTRAL_LOW,
        backgroundColor: colors.BACKGROUND,
        marginTop: 8,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 8,
        paddingBottom: 8,
        borderRadius: 8,
      },
      segmentMainContainer: {
        marginTop: 8,
        backgroundColor: colors.SURFACE1,
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
        borderRadius: 4,
        cursor: 'pointer',
      },
      segmentTypeText: {
        ...fonts.PAGE_SUBHEADING_SMALL,
        color: colors.NEUTRAL_HIGH,
        marginTop: 16,
      },
      segmentTypeWrapperStyle: {
        marginTop: 8,
        flex: 1,
        flexDirection: 'row',
        padding: 8,
        alignItems: 'center',
      },
      subTittle: {
        color: colors.NEUTRAL_LOW,
        fontSize: 10,
      },
      saveButtonStyle: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 10,
        paddingBottom: 10,
        marginTop: 32,
        borderRadius: 8,
        backgroundColor: colors.BRAND_UPPER_MEDIUM,
      },
      saveText: {
        textAlign: 'center',
        color: colors.BRAND_LOW,
      },
    },

    CrossIcon: icons.Cross,
    ActiveRadioIcon: icons.ActiveRadioIcon,
    InactiveIcon: icons.InactiveIcon,
    iconStyle: {
      height: 28,
      width: 28,
      cursor: 'pointer',
    },
    radioActiveiconStyle: {
      height: 24,
      width: 24,
      marginRight: 12,
    },
  };
};
