import React from 'react';
import {
  Row,
  View,
  TouchableOpacity,
  Image,
} from '@unthinkable/react-core-components';
const {useComponentTheme} = require('@unthinkable/react-theme');

export const LeftMenuHeader = ({toggleExpanded, expanded}) => {
  const {containerStyle, logo, expandIcon, collpaseIcon, logoContainerStyle} =
    useComponentTheme('LeftMenuHeader');
  return (
    <Row style={containerStyle}>
      {expanded === false ? (
        void 0
      ) : (
        <View style={logoContainerStyle}>
          <Image source={logo} />
        </View>
      )}
      {toggleExpanded ? (
        <TouchableOpacity
          onPress={toggleExpanded}
          style={{cursor: 'pointer', justifyContent: 'center'}}>
          <Image source={expanded ? collpaseIcon : expandIcon} />
        </TouchableOpacity>
      ) : (
        void 0
      )}
    </Row>
  );
};
