export const allEventsApi = () => {
  return {
    uri: '/events',
  };
};

export const appOpenEventsApi = () => {
  return {
    uri: '/appOpenEvents',
  };
};

export const appOpenVersionwiseEventsApi = (params, state, {stateData}) => {
  return {
    uri: '/appOpenVersionwiseEvents',
    props: {...stateData},
  };
};

export const versionList = ({searchValue}) => {
  return {
    uri: `/versionList`,
    props: {searchValue},
  };
};

export const appUserEventWise = (params, state) => {
  let {mobile, version, platform} = state && state.params;
  return {
    uri: '/userEventWise',
    props: {mobile, version, platform},
  };
};

export const recursiveEventsApi = ({
  _createdOn,
  parents,
  container,
  parent,
}) => {
  return () => {
    return {
      uri: '/recursiveEvents',
      props: {
        _createdOn,
        parents,
        container,
        parent,
      },
    };
  };
};
export const desiredEventsApi = (_, state) => {
  let {params = {}} = state;
  let {department, span} = params || {};
  return {
    uri: '/desiredEventsAnalytics',
    props: {
      ...department,
      span,
      limit: 30,
    },
  };
};

export const addDepartmentsEventsApi = (_, state) => {
  let {params = {}} = state;
  let {department} = params || {};
  return {
    uri: '/desiredEventsAnalytics',
    props: {
      ...department,
      limit: 30,
    },
  };
};

export const acquistionAnalytics = () => {
  return {
    uri: '/acquistionAnalytics',
  };
};

export const retentionAnalytics = (params, state) => {
  let {params: newParams} = state || {};

  return {
    uri: '/retentionAnalytics',
    props: {limit: 30, ...newParams},
  };
};

export const engagmentAnalytics = () => {
  return {
    uri: '/engagmentAnalytics',
  };
};

export const getAppOpenEventWise = () => {
  return {
    uri: '/appOpenEventWise',
  };
};
export const getDesiredDepartment = () => {
  return {
    uri: '/desiredDepartment',
  };
};

export const getUserDetails = params => {
  return {
    uri: '/userDetails',
    props: {...params},
  };
};

export const newAllAnalytics = (params, state, {version, platform, span}) => {
  let {params: newParams} = state || {};
  let extraParams = {};
  if (span) {
    extraParams = {span};
  }

  extraParams = {...extraParams, version};

  extraParams = {...extraParams, platform};

  return {
    // uri: '/allEventsAnalytics',
    uri: '/allNewEventsAnalytics',

    // uri: '/createAggregateEvents',

    props: {limit: 15, ...newParams, ...extraParams},
  };
};

export const getUtmGroupBy = (
  params,
  state,
  {startDate, endDate, type, platform, version, versionInfo, span} = {},
) => {
  let {
    startDate: iStartDate,
    endDate: iEndDate,
    type: iType,
    version: iVersion,
    platform: iPlatform,
    versionInfo: iVersionInfo,
    span: iSpan,
  } = state || {};
  if (!startDate) {
    startDate = iStartDate;
    endDate = iEndDate;
    type = iType;
    version = iVersion;
    platform = iPlatform;
    versionInfo = iVersionInfo;
    span = iSpan;
  }
  return {
    uri: '/groupByUTMDrillDown',
    props: {startDate, endDate, type, versionInfo, span},
  };
};
