export const RightSide = ({theme: {colors, fonts, icons}}) => {
  return {
    sm: {
      containerStyle: {
        marginTop: 8,
        marginLeft: 8,
        flex: 1,
      },
      textStyle: {
        color: 'red',
      },
    },
    md: {
      containerStyle: {
        // flex: 1,
        marginTop: 8,
        marginLeft: 8,
        backgroundColor: colors.BACKGROUND,
        // backgroundColor: 'red',
        height: '80vh',
        overflow: 'scroll',
      },
      WrapperStyle: {
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 200,
      },
      textStyle: {
        ...fonts,
        textAlign: 'center',
        marginTop: 23,
        width: 232,
        fontSize: 16,
        color: colors.NEUTRAL_MEDIUM,
      },
    },
    iconsStyle: {width: 232, height: 168},
    EmptyImage: icons.EmptyImage,
  };
};
